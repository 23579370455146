import ReportFuncShare from "./ReportFuncShare.js";
// import ReportsAdminShareCom from "./ReportsAdminShareCom.js";

const listdata = [
  { value: "total_ex_by_function", title: " Total Exp. by Function" },
  { value: "ex_by_function_new", title: "Personnel Exp. By Function" },
  { value: "ex_by_function_new", title: "Other NDR Exp.by Function" },
  { value: "ex_by_function", title: "Debt Service by Function" },
  { value: "ex_by_function", title: "Capital Exp. By Function" },
];
function ReportByFunc() {
  return (
    <ReportFuncShare
      type="total_ex_by_function"
      title="Total Exp. by Function"
      route="/reports"
      options={listdata}
    />
  );
}

export default ReportByFunc;
