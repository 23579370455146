import React, { useCallback, useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";

import { BsPrinter, BsXSquare } from "react-icons/bs";
import { getSector } from "helper";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import { Card, Grid } from "@mui/material";
import SearchBar from "components/SearchBar";
import CustomTable from "components/CustomTable";
import { _postApi } from "redux/action/api";
import Footer from "examples/Footer";
import MDTypography from "components/MDTypography";
import { _fetchApi } from "redux/action/api";
import Scrollbar from "components/Tree/Scrollbar";
import { Button, Input, Label } from "reactstrap";
import { formatNumber } from "redux/action/api";
import { useSelector } from "react-redux";
import moment from "moment";
import { expiredSetting } from "examples/admin/helper";
import { useToasts } from "react-toast-notifications";
import { SiMicrosoftexcel } from "react-icons/si";
import { CSVLink } from "react-csv";
import { PDFViewer } from "@react-pdf/renderer";
import MyPDF from "./ProposedPdf";
import { useYear } from "useYearList";
import CustomTypeahead from "components/CustomTypeahead";
import { downloadExcel, DownloadTableExcel } from "react-export-table-to-excel";
// import * as XLSX from 'xlsx';

import { getNextYear } from "./getBudgetYear";
import ProposalTable from "./ProposalTable";
import { ensureNumber } from "redux/action/api";

function BudgetBreakdown() {
  const navigate = useNavigate();
  const this_year = moment().format("YYYY");
  const [editingIndex, setEditingIndex] = useState(-1);
  const years = useYear();
  const [form, setForm] = useState({
    year: "",
    status: "",
  });
  const handleDoubleClick = (index) => {
    setEditingIndex(index);
  };
  const [searchQuery, setSearchQuery] = useState("");

  const [results, setResults] = useState();
  const [loading, setLoading] = useState();
  const [result2, setResult2] = useState([]);
  const [budget, setBudget] = useState([]);

  useEffect(() => {
    getNextYear((data) => setForm((p) => ({ ...p, year: data })));
  }, []);

  const getBudgetType = () => {
    setLoading(true);
    _fetchApi(
      `/get-budget-new/${form.admin_description}?query_type=type&budget_type=${form.type}&budget_year=${form.year}`,
      (res) => {
        setBudget(res.results), setLoading(false);
      },
      (err) => console.log(err)
    );
  };
  useEffect(() => {
    getBudgetType();
  }, [form.type, form.admin_description]);

  useEffect(() => { 
    getSector(
      { type: "mdas_approved", proposal_type: form.proposal_type_type },
      "mdas_approved",
      (d) => {
        setResult2(d);
      }
    );
  }, [form.proposal_type]);

  let aheadData = [
    {
      name: "Revenue",
    },
    {
      name: "Expenditure",
    },
  ];

  // let data = results&&results.map(item => item.name)
  let query_type =
    form.status === "" || form.status === "All" ? "SELECT" : "btype";

  const user = useSelector((e) => e.auth.user);

  const getDamnData = useCallback(() => {
    _fetchApi(
      `/get-budget-new/${form.admin_description}?query_type=breakdown&budget_year=${form.year}&status=${form.status}`,
      (res) => setResults(res.results),
      (err) => console.log(err)
    );
  }, [form.year, form.admin_description]);

  useEffect(() => {
    getDamnData();
  }, [getDamnData, form.status]);

  const fields = [
    {
      title: "Adminstrative",
      custom: true,
      component: (ite) => ite.admin_description,
      rowSpan: true,
    },
    {
      title: "Admin Code",
      custom: true,
      component: (item) => (<>
        {item.admin_code.startsWith('0') ? (
   <p>{`'${item.admin_code}`}</p>
) : (
    <p>{`${item.admin_code}`}</p>
)}
</>

     
      ),
      rowSpan: true,
    },

    {
      title: "Economic Description",
      custom: true,
      component: (ite) => ite.economic_description,
      rowSpan: true,
    },
    {
      title: "Economic Code",
      custom: true,
      component: (item) => (
        <>
        {item.economic_code.startsWith('0') ? (
   <p>{`'${item.economic_code}`}</p>
) : (
    <p>{`${item.economic_code}`}</p>
)}
</>),
      rowSpan: true,
    },

    {
      title: "Program Description",
      custom: true,
      component: (ite) => ite.program_description,
      rowSpan: true,
    },
    {
      title: "Program Code",
      custom: true,
      component: (ite) => (
        <>
        {ite.program_code.startsWith('0') ? (
   <p>{`'${ite.program_code}`}</p>
) : (
    <p>{`${ite.program_code}`}</p>
)}
</>),
      rowSpan: true,
    },
    {
      title: "Functional Description",
      custom: true,
      component: (ite) => ite.function_description,
      rowSpan: true,
    },
    {
      title: "Functional code",
      custom: true,
      component: (ite) => ite.function_code,
      rowSpan: true,
    },
    {
      title: "Geo Description",
      custom: true,
      component: (ite) => ite.geo_description,
      rowSpan: true,
    },
    {
      title: "Geo code",
      custom: true,
      component: (ite) => ite.geo_code,
      rowSpan: true,
    },
    {
      title: "Budget type",
      custom: true,
      component: (ite) => ite.type_of_budget,
      rowSpan: true,
    },
    {
      title: "Fund Description",
      custom: true,
      component: (item) => item.fund_description,
    },
    {
      title: "Fund Code",
      custom: true,
      component: (item) => (
        <>
        {item.fund_code.startsWith('0') ? (
   <p>{`'${item.fund_code}`}</p>
) : (
    <p>{`${item.fund_code}`}</p>
)}
</>),
    },
    {
      title: "Fund Amount",
      custom: true,
      component: (item) => (
        <div style={{ textAlign: "right" }}>{formatNumber(item.fund_amt1)}</div>
      ),
    },
    {
      title: `Aproved(₦)`,
      custom: true,
      component: (ite) => (
        <div className="float-end">{formatNumber(ite.approve_amount)} </div>
      ),
      rowSpan: true,
    },
  ];

  const [results5, setResults5] = useState([]);
  // let data = results&&results.map(item => item.name)
  useEffect(() => {
    getSector({ type: "mdas" }, "mdas", (d) => {
      setResults5(d);
    });
  }, []);

  const [results4, setResult4] = useState([]);
  const [expired, setExpired] = useState([]);

  useEffect(() => {
    // getSector({ type: "mdas" }, "mdas", (d) => {
    //   setResult4(d);
    // });
    // expiredSetting(
    //   { description: "Proposed Budget End Date" },
    //   "select",
    //   (d) => {
    //     setExpired(d.results);
    //   },
    //   (e) => {
    //     console.log(e);
    //   }
    // );
  }, []);
  const filteredResults1 = results?.filter((item) => {
    if (!form.status || form.status === "All") {
      return true; // If no status is selected, or "All" is selected, return all items
    }
    return item.type_of_budget === form.status;
  });

  const totalAmount = filteredResults1?.reduce(
    (total, item) => ensureNumber(item.budget_amount) + total,
    0
  );

  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  let _expired = expired[0]?.end_date;
  const submissionDeadline = moment(_expired);
  const daysLeft = submissionDeadline.diff(moment(), "days");
  const filteredResults = results?.filter((item) => item.status === "draft");
  const [print, setPrint] = useState(false);

  let data = [];

  results?.forEach((item) => {
    if (
      (item.fund_description &&
        item.function_description
          .toLowerCase()
          .indexOf(searchQuery.toLowerCase()) === -1) ||
      (item.fund_code &&
        item.fund_code.toLowerCase().indexOf(searchQuery.toLowerCase()) ===
          -1) ||
      (item.mda_name &&
        item.mda_name.toLowerCase().indexOf(searchQuery.toLowerCase()) === -1)
    )
      return;
    else data.push(item);
  });

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  let newBudget = { type_of_budget: "All" };
  let isObjectExists = budget?.some(
    (item) => item.type_of_budget === newBudget.type_of_budget
  );

  // If the object doesn't exist, push it into the array
  if (!isObjectExists) {
    budget?.push(newBudget);
  }
  const tableRef = useRef(null);
  let aheadData1 =
    results5 &&
    results5?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));

const handleDownloadExcel = () => {
  const formattedData = results.map((item) => ({
    "Adminstrative": item.admin_description,
    "Admin Code": item.admin_code ? `'${item.admin_code.toString()}` : "", // Preserve leading zeros
    "Economic Description": item.economic_description,
    "Economic Code": item.economic_code ? `'${item.economic_code.toString()}` : "",
    "Program Description": item.program_description,
    "Program Code": item.program_code ? `'${item.program_code.toString()}` : "",
    "Functional Description": item.function_description,
    "Functional code": item.function_code ? `'${item.function_code.toString()}` : "",
    "Geo Description": item.geo_description,
    "Geo code": item.geo_code ? `'${item.geo_code.toString()}` : "",
    "Budget type": item.type_of_budget,
    "Fund Description": item.fund_description,
    "Fund Code": item.fund_code,
    "Fund Amount": formatNumber(item.fund_amt1), // Format with two decimal places
    "Approved(₦)": formatNumber(item.approve_amount), // Format with two decimal places
  }));

  // Convert JSON data to a worksheet
  // const ws = XLSX.utils.json_to_sheet(formattedData);

  // // Apply alignment to the worksheet cells
  // Object.keys(ws).forEach((cell) => {
  //   const col = cell[0];
  //   if (col >= 'A' && col <= 'M') {
  //     ws[cell].s = { alignment: { horizontal: "left" } }; // Left-align text fields
  //   } else if (col === 'N' || col === 'O') {
  //     ws[cell].s = { alignment: { horizontal: "right" }, numFmt: '0.00' }; // Right-align amounts with two decimals
  //   }
  // });

  // // Create a new workbook and append the worksheet
  // const wb = XLSX.utils.book_new();
  // XLSX.utils.book_append_sheet(wb, ws, 'Data Export');

  // // Export the workbook as an Excel file
  // XLSX.writeFile(wb, `${form.year}-budget.xlsx`);
}




  return (
    <DashboardLayout>
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                my={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h5" gutterBottom color="white">
                  <center>
                    <h2 style={{ fontSize: "25px" }}>BUDGET BREAKDOWN</h2>
                  </center>
                </MDTypography>
              </MDBox>
              {/* {JSON.stringify(results)} */}
              {print ? (
                <div>
                  <Button color="danger" onClick={() => setPrint(!print)}>
                    cancel
                  </Button>
                  <br />
                  <PDFViewer width="1000" height="600">
                    {" "}
                    {/* Adjust the width and height as needed */}
                    <MyPDF data={data} />
                  </PDFViewer>
                </div>
              ) : (
                <MDBox>
                  <center></center>
                  {/* {JSON.stringify(budget)} */}
                  <MDBox pt={3}>
                    <div className=" row mb-4 p-2">
                      <div className=" justify-content-end">
                        <div className="col-md-6">
                          {/* <button
                            className="btn btn-dark px-3 m-2"
                            onClick={() => navigate("/budget-form")}
                          >
                            Add New Item
                          </button> */}
                          {/* <Button
                            color="primary"
                            className="px-5 m-2"
                            // style={{ float: "right" }}
                            onClick={handleSubmit}
                            disabled={
                              filteredResults?.length === 0 ? true : false
                            }
                          >
                            Submit
                          </Button> */}
                          {/* <Button color="success" className="m-2">
                            <CSVLink
                              data={results && results ? results : []}
                              className="text-white"
                              filename={`${user?.mda} ${form.year} budget proposal  `}
                            >
                              <SiMicrosoftexcel /> Download to Excel
                            </CSVLink>
                          </Button> */}
                        </div>
                      </div>
                      {/* <div className="col-md-6 mb-3"> */}
                      {/* <CustomTypeahead
                          labelKey="name"
                          label="Filter by Budget type"
                          options={aheadData}
                          onChange={(selected) => {
                            setForm((p) => ({ ...p, type: selected[0]?.name }));
                          }}
                        /> */}
                      {/* </div> */}

                      <div className="col-md-4">
                        <CustomTypeahead
                          labelKey="head"
                          label="Select MDA"
                          options={aheadData1}
                          onChange={(s) => {
                            if (s.length) {
                              console.log(s);
                              // navigate(`/functional-maping?code=${s[0].code}`);
                              setForm((p) => ({
                                ...p,
                                admin_description: s[0].description,
                                mda_name: s[0].description,
                              }));
                            }
                          }}
                        />
                      </div>
                      <div className="col-md-4 mb-4 ">
                        <CustomTypeahead
                          labelKey="type_of_budget"
                          label="Filter by Budget type"
                          options={budget || []}
                          onChange={(selected) => {
                            setForm((p) => ({
                              ...p,
                              status: selected[0]?.type_of_budget,
                            }));
                          }}
                        />
                      </div>
                      <div className="col-md-2">
                        <label>Year</label>
                        <Input
                          type="select"
                          name="year"
                          value={form.year}
                          onChange={handleChange}
                        >
                          <option>--select--</option>
                          {years?.map((item) => (
                            <option key={item}>{item}</option>
                          ))}
                        </Input>
                      </div>

                      <div className="col-md-2 mt-3">
                       <Button
  color="success"
  className="m-2"
  // onClick={handleDownloadExcel} // Use the modified function
>
                        <DownloadTableExcel
                          filename={`budget-${form.year}`}
                          sheet={`budget-${form.year}`}
                          currentTableRef={tableRef.current}
                        >
                          <span> Export excel </span>
                        </DownloadTableExcel>
</Button>

                      </div>

                      {/* <Label>Select year</Label> */}
                      {/* <div className="col-md-4 ">
                        <Input
                          type="select"
                          name="year"
                          value={form.year}
                          onChange={handleChange}
                        >
                          <option>--select--</option>
                          {years?.map((item) => (
                            <option key={item}>{item}</option>
                          ))}
                        </Input>
                      </div> */}
                      {/*  <div className="col-md-4">
                        <SearchBar
                          placeholder="Search By fund code or description"
                          filterText={searchQuery}
                          onFilterTextChange={handleSearchChange}
                        />
                      </div> */}
                      {/* {JSON.stringify(parseInt(this_year) + 1)} */}
                      {/* <div className="col-md-4">
                        <button
                          className="btn btn-dark px-3"
                          onClick={() => navigate("/budget-form")}
                        >
                          Add New Item
                        </button>
                        <Button
                          color="primary"
                          className="px-5"
                          style={{ float: "right" }}
                          onClick={handleSubmit}
                          disabled={
                            filteredResults?.length === 0 ? true : false
                          }
                        >
                          Submit
                        </Button>
                      </div> */}

                      <div>
                        <span style={{ fontWeight: "bold" }}>
                          Total Budget Size : ₦{totalAmount?.toLocaleString()}
                        </span>
                        {/* <Button color="success" className="m-2">
                          <CSVLink
                            data={results && results ? results : []}
                            className="text-white"
                            filename={`${user?.mda} ${form.year} budget proposal  `}
                          >
                            <SiMicrosoftexcel /> Download to Excel
                          </CSVLink>
                        </Button> */}
                        {/* <Button color="success" onClick={printItemList}>
                          <BsPrinter /> Print
                        </Button> */}
                      </div>
                      <Scrollbar>
                        {/* {JSON.stringify(results)} */}

                        <ProposalTable
                          fields={fields}
                          data={filteredResults1}
                          type="proposals"
                          tableRef={tableRef}
                          stage="breakdown"
                        />
                      </Scrollbar>
                      {/* <center>
                        <Button
                          color="success"
                          className="pr-5 pl-5 btn btn-success"
                          onClick={handleSubmit}
                          disabled={
                            filteredResults?.length === 0 ? true : false
                          }
                        >
                          Submit
                        </Button>
                      </center> */}
                    </div>
                  </MDBox>
                </MDBox>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default BudgetBreakdown;
