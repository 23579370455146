import ReportsAdminShareCom from "./ReportsAdminShareCom.js";
const listdata = [
  { value: "total_ex_by_location", title: "Total Exp. By Location" },
  {
    value: "ex_by_location_new",
    title: " Personnel Exp. by Location",
    code: "Personnel",
  },
  {
    value: "ex_by_location_new",
    title: "Other NDR Exp. By Location",
    code: "OverHead",
  },
  { value: "ex_by_location", title: "Debt Service By Location", code: "2206%" },
  {
    value: "ex_by_location",
    title: "Capital Exp. by Location",
    code: "23%",
  },
];
function ReportByLoc() {
  return (
    <ReportsAdminShareCom
      type="total_ex_by_location"
      title="Total Exp. by Location"
      route="/reports"
      options={listdata}
    />
  );
}

export default ReportByLoc;
