import { getAllData } from "layouts/DashboardNew";
import React, { useEffect, useState } from "react";
import { Button, Spinner, Table } from "reactstrap";
import { formatNumber } from "redux/action/api";
import { Col, Row } from "reactstrap";
import SearchInput from "./SearchInput";
import PropTypes from 'prop-types';

const AgricIndex = ({ toggle }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const handleSearch = async (searchTerm) => {
    setSearchTerm(searchTerm);
    
    if (!searchTerm) {
      setFilteredData(data);
      return;
    }
    setFilteredData(data.filter(item =>
      item.program_description.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.description.toLowerCase().includes(searchTerm.toLowerCase())
    ));
  };

  useEffect(() => {
    setLoading(true);
    getAllData("mda", "", "", (d) => {
      setData(d);
      setFilteredData(d); 
      setLoading(false);
    });
  }, []);

  return (
    <div>
      <Row>
        <Col md={6}>
          <SearchInput onSearch={handleSearch} />
        </Col>
      </Row>
      {loading && <Spinner />}
      <Table bordered striped size="sm">
        <thead>
          <tr style={{ fontSize: "10px" }}>
            <th>Program Code</th>
            <th>Program Description</th>
            <th>Function Code</th>
            <th>Function Description</th>
            <th>Fund Code</th>
            <th>Fund Description</th>
            <th>Project Description</th>
            <th>Approve Amt</th>
            <th>Actual</th>
            <th>Percentage</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((item, id) => (
            <tr key={id} style={{ fontSize: "10px" }}>
              <td>{item.program_code}</td>
              <td>{item.program_description}</td>
              <td>{item.function_code}</td>
              <td>{item.function_description}</td>
              <td>{item.fund_code}</td>
              <td>{item.fund_description}</td>
              <td>{item.description}</td>
              <td style={{ textAlign: "right" }}>
                {formatNumber(item.approve_amount)}
              </td>
              <td className="text-right">{item.actual_amount || 0}</td>
              <td>
                {parseInt(item.actual_amount / item.approve_amount) * 100}%
              </td>
              <td>Ongoing</td>
              <td>
                <Button size="sm" onClick={() => toggle(item)}>
                  Process
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

AgricIndex.propTypes = {
  toggle: PropTypes.func.isRequired,
};

export default AgricIndex;
