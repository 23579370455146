/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from "react";
import PropTypes from "prop-types"; // Import prop-types library

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

function Attachment({
  acceptedFiles = [],
  getRootProps = (f) => f,
  getInputProps = (f) => f,
  isDragActive = (f) => f,
  isDragAccept = (f) => f,
  isDragReject = (f) => f,
}) {
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes <span>&apos;X&apos;</span>
    </li>
  ));

  return (
    <div className="mv-2">
      <h3
        style={{
          color: "#59B15D",
        }}
      >
        Upload Attachment
      </h3>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>Drag n drop some files here, or click to select files</p>
      </div>

      {files.length ? (
        <aside>
          <h4>Files</h4>
          <ul>{files}</ul>
        </aside>
      ) : null}
    </div>
  );
}

// Add prop type validation
Attachment.propTypes = {
  acceptedFiles: PropTypes.array,
  getRootProps: PropTypes.func,
  getInputProps: PropTypes.func,
  isDragActive: PropTypes.bool,
  isDragAccept: PropTypes.bool,
  isDragReject: PropTypes.bool,
};

export default Attachment;
