/* eslint-disable react/prop-types */
import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";
import DM_SANS_NORMAL from "../../assets/DM_Sans/DM_Sans/static/DMSans_24pt-SemiBold.ttf";
import DM_SANS_BOLD from "../../assets/DM_Sans/DM_Sans/static/DMSans_24pt-Bold.ttf";
import DM_SANS_ITALIC from "../../assets/DM_Sans/DM_Sans/static/DMSans-Italic.ttf";

Font.register({
  family: "DM_SANS",
  fonts: [
    { src: DM_SANS_NORMAL, fontWeight: 700 },
    {
      src: DM_SANS_BOLD,
      fontStyle: "bold",
    },
    {
      src: DM_SANS_ITALIC,
      fontStyle: "italic",
    },
  ],
});

const styles = StyleSheet.create({
  body: {
    width: "100%",
    pageBreakInside: "avoid",
    paddingTop: 80,
    paddingLeft: 20,
    paddingRight: 20,
    fontSize: 10,
    flexDirection: "row",
    lineHeight: 1.5,
  },
  leftBody: {
    width: "75%",
    height: "85vh",
    paddingTop: 10,
    borderRight: "2px solid #000",
  },
  rightBody: {
    width: "30%",
    paddingLeft: 20,
    paddingTop: 10,
  },
  table: {
    borderTop: 1,
    borderBottom: 1,
    width: "100%",
    flexDirection: "row",
  },
  table1: {
    borderBottom: 1,
    width: "100%",
    flexDirection: "row",
  },
});

const FormSection = ({ label, value }) => (
  <View style={{ width: "100%", marginTop: 30 }}>
    <Text style={{ width: "100%", textAlign: "center" }}>{label}</Text>
    <View style={{ width: "100%", border: 1, flexDirection: "row" }}>
      {String(value)
        .split("")
        .map((digit, index) => (
          <Text
            key={index}
            style={{
              width: `${100 / String(value).length}%`,
              textAlign: "center",
              borderRight: index < String(value).length - 1 ? 1 : 0,
              paddingTop: 3,
            }}
          >
            {digit}
          </Text>
        ))}
    </View>
  </View>
);

export const PaymentVoucherPdf = ({ form }) => {
  return (
    <Document>
      <Page size="A4">
        <View style={styles.body}>
          <View style={styles.leftBody}>
            <Text
              style={{
                fontFamily: "DM_SANS",
                fontStyle: "bold",
                fontSize: 18,
                width: "100%",
                textAlign: "right",
              }}
            >
              PAYMENT VOUCHER
            </Text>
            <Text
              style={{
                fontFamily: "DM_SANS",
                fontStyle: "bold",
              }}
            >
              KTT.44
            </Text>
            <Text>
              Organisation:
              ...............................................................................................................
            </Text>
            <Text>
              Sub Organisation Name:
              .............................................................................................
            </Text>

            <Text
              style={{
                marginBottom: 2,
              }}
            >
              Payment to:
              .................................................................................................................
            </Text>
            <View style={styles.table}>
              <Text
                style={{
                  fontFamily: "DM_SANS",
                  fontStyle: "bold",
                  width: "10%",
                  borderRight: 1,
                  textAlign: "right",
                }}
              >
                Date
              </Text>
              <Text
                style={{
                  fontFamily: "DM_SANS",
                  fontStyle: "bold",
                  width: "50%",
                  borderRight: 1,
                  textAlign: "center",
                }}
              >
                Detailed description of service
              </Text>
              <Text
                style={{
                  fontFamily: "DM_SANS",
                  fontStyle: "bold",
                  width: "10%",
                  borderRight: 1,
                  textAlign: "center",
                }}
              >
                Rate
              </Text>
              <View
                style={{
                  width: "30%",
                  flexDirection: "column",
                }}
              >
                <Text
                  style={{
                    fontFamily: "DM_SANS",
                    fontStyle: "bold",
                    width: "100%",
                    borderRight: 1,
                    textAlign: "center",
                  }}
                >
                  Amount
                </Text>
                <View
                  style={{
                    width: "100%",
                    flexDirection: "row",
                    borderTop: 1,
                  }}
                >
                  <Text
                    style={{
                      width: "80%",
                      borderRight: 1,
                      paddingLeft: 10,
                    }}
                  >
                    N
                  </Text>
                  <Text
                    style={{
                      width: "20%",
                      borderRight: 1,
                      paddingLeft: 4,
                    }}
                  >
                    K
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.table1}>
              <View
                style={{
                  fontFamily: "DM_SANS",
                  fontStyle: "bold",
                  width: "10%",
                  borderRight: 1,
                  textAlign: "right",
                  height: 180,
                }}
              ></View>
              <View
                style={{
                  fontFamily: "DM_SANS",
                  fontStyle: "bold",
                  width: "50%",
                  borderRight: 1,
                  textAlign: "center",
                }}
              ></View>
              <View
                style={{
                  fontFamily: "DM_SANS",
                  fontStyle: "bold",
                  width: "10%",
                  borderRight: 1,
                  textAlign: "center",
                }}
              ></View>
              <View
                style={{
                  fontFamily: "DM_SANS",
                  fontStyle: "bold",
                  width: "30%",
                  borderRight: 1,
                  textAlign: "center",
                  flexDirection: "row",
                }}
              >
                <View
                  style={{
                    width: "83%",
                    borderRight: 1,
                    paddingLeft: 10,
                  }}
                ></View>
                <View
                  style={{
                    width: "20%",
                    paddingLeft: 10,
                  }}
                ></View>
              </View>
            </View>
            <View
              style={{
                width: "100%",
                flexDirection: "row",
                borderBottom: 1,
              }}
            >
              <Text
                style={{
                  width: "60%",
                  borderRight: 1,
                  textAlign: "center",
                  height: 50,
                }}
              >
                Total Amount in words: One Naira Only
              </Text>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "40%",
                }}
              >
                <Text
                  style={{
                    fontFamily: "DM_SANS",
                    fontStyle: "bold",
                  }}
                >
                  TOTAL
                </Text>
                <Text
                  style={{
                    fontFamily: "DM_SANS",
                    fontStyle: "bold",
                    marginLeft: 90,
                    marginTop: 10,
                  }}
                >
                  1.00
                </Text>
              </View>
            </View>
            <View
              style={{
                width: "100%",
                borderBottom: 1,
                paddingLeft: 2,
                flexDirection: "column",
              }}
            >
              <Text
                style={{
                  width: "100%",
                }}
              >
                We certify that the details above are in accordance with the
                relevant contract,{" "}
              </Text>
              <Text
                style={{
                  width: "100%",
                }}
              >
                regulations or other authority under which the Services/Goods
                were provided/purchased
              </Text>
              <Text
                style={{
                  width: "100%",
                }}
              >
                Officer who prepared voucher, Signature:
                ..................................................................
              </Text>
              <Text
                style={{
                  marginLeft: 53,
                }}
              >
                Name (in block letters):
                ...........................................................................
              </Text>
              <Text
                style={{
                  width: "100%",
                }}
              >
                Officer who checked voucher, Signature
                ...................................................................
              </Text>
              <Text
                style={{
                  marginLeft: 53,
                }}
              >
                Name (in block letters):
                ...........................................................................
              </Text>
            </View>
            <View
              style={{
                width: "100%",
                borderBottom: 1,
                paddingLeft: 2,
                flexDirection: "column",
              }}
            >
              <Text
                style={{
                  width: "100%",
                }}
              >
                We certify that the Service/Goods have been duly
                performed/received, that financial
              </Text>
              <Text
                style={{
                  width: "100%",
                }}
              >
                authority GM/.............. is held to incur this expenditure
                and that the relevant D V.E
              </Text>
              <Text
                style={{
                  width: "100%",
                }}
              >
                account entries have been made. We hereby authorized payment.
              </Text>
              <Text
                style={{
                  width: "100%",
                }}
              >
                Signature: ...................................................
                For
                .............................................................
              </Text>
              <Text
                style={{
                  width: "100%",
                  marginLeft: "55%",
                }}
              >
                (Accounting Officer)
              </Text>
              <Text
                style={{
                  width: "100%",
                }}
              >
                Name (in block letters):
                .................................................Date......................20................
              </Text>
              <Text
                style={{
                  width: "100%",
                }}
              >
                Signature: ...................................................
                For
                .............................................................
              </Text>
              <Text
                style={{
                  width: "100%",
                  marginLeft: "55%",
                }}
              >
                (Accounting Officer)
              </Text>
              <View
                style={{
                  width: "100%",
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    width: "50%",
                  }}
                >
                  Name (in block letters): {form.contractor_name}
                </Text>
                <Text
                  style={{
                    width: "50%",
                  }}
                >
                  Date: {form.date}
                </Text>
              </View>
            </View>
            <View
              style={{
                width: "100%",
                borderBottom: 1,
                paddingLeft: 2,
                flexDirection: "row",
              }}
            >
              <Text
                style={{
                  width: "50%",
                }}
              >
                Cheque No:
              </Text>
              <Text
                style={{
                  width: "50%",
                }}
              >
                Account No: {form.account_number}
              </Text>
            </View>
            <View
              style={{
                width: "100%",
                borderBottom: 1,
                paddingLeft: 2,
                flexDirection: "row",
              }}
            >
              <Text
                style={{
                  width: "50%",
                }}
              >
                Account Name: {form.account_name}
              </Text>
              <Text
                style={{
                  width: "50%",
                }}
              >
                {" "}
                Name of Bank: {form.bank_name}
              </Text>
            </View>
            <View
              style={{
                width: "100%",
                paddingLeft: 2,
                flexDirection: "column",
              }}
            >
              <Text
                style={{
                  width: "100%",
                }}
              >
                Received the sum of ..................................... Naira
                and......................................Kobo in
              </Text>
              <Text
                style={{
                  width: "100%",
                }}
              >
                acquisition of the above Account
                this.............................Day
                of..........................20..........
              </Text>
              <Text
                style={{
                  width: "100%",
                }}
              >
                Amount in word.....................................Name of
                Payee...................................................
              </Text>
              <Text
                style={{
                  width: "100%",
                }}
              >
                Witness in official
                Name..........................................Rank.................................................
              </Text>
              <View
                style={{
                  flexDirection: "row",
                  width: "60%",
                  marginLeft: 40,
                }}
              >
                <View
                  style={{
                    flexDirection: "column",
                  }}
                >
                  <Text>Witness</Text>
                  <Text>Official</Text>
                </View>
                <View
                  style={{
                    flexDirection: "column",
                    marginLeft: 40,
                  }}
                >
                  <Text>Name.......................................</Text>
                  <Text>Rank........................................</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.rightBody}>
            <View
              style={{
                width: "100%",
              }}
            >
              <Text
                style={{
                  width: "100%",
                  textAlign: "center",
                }}
              >
                Voucher Number
              </Text>
              <View
                style={{
                  width: "100%",
                  border: 1,
                  flexDirection: "row",
                }}
              >
                {String(form.pv_code)
                  .split("")
                  .map((digit, index) => (
                    <Text
                      key={index}
                      style={{
                        width: "33.3%",
                        textAlign: "center",
                        borderRight:
                          index < String(form.pv_code).length - 1 ? 1 : 0,
                        paddingTop: 3,
                      }}
                    >
                      {digit}
                    </Text>
                  ))}
              </View>
            </View>
            <View
              style={{
                width: "100%",
                marginTop: 30,
              }}
            >
              <Text
                style={{
                  width: "100%",
                  textAlign: "center",
                }}
              >
                Date/Month/Year
              </Text>
              <View
                style={{
                  width: "100%",
                  border: 1,
                  flexDirection: "row",
                }}
              >
                {String(form.date)
                  .split("")
                  .map((digit, index) => (
                    <Text
                      key={index}
                      style={{
                        width: "12.5%",
                        textAlign: "center",
                        borderRight:
                          index < String(form.date).length - 1 ? 1 : 0,
                        paddingTop: 3,
                      }}
                    >
                      {digit}
                    </Text>
                  ))}
              </View>
            </View>
            <View
              style={{
                width: "100%",
                marginTop: 30,
              }}
            >
              <Text
                style={{
                  width: "100%",
                  textAlign: "center",
                }}
              >
                Administrative Code
              </Text>
              <View
                style={{
                  width: "100%",
                  border: 1,
                  flexDirection: "row",
                }}
              >
                {String(form.sourceMdaCode)
                  .split("")
                  .map((digit, index) => (
                    <Text
                      key={index}
                      style={{
                        width: "8.3%",
                        textAlign: "center",
                        borderRight:
                          index < String(form.sourceMdaCode).length - 1 ? 1 : 0,
                        paddingTop: 3,
                      }}
                    >
                      {digit}
                    </Text>
                  ))}
              </View>
            </View>
            <View
              style={{
                width: "100%",
                marginTop: 30,
              }}
            >
              <Text
                style={{
                  width: "100%",
                  textAlign: "center",
                }}
              >
                Economic Code
              </Text>
              <View
                style={{
                  width: "100%",
                  border: 1,
                  flexDirection: "row",
                }}
              >
                {String(form.sourceEconomicCode)
                  .split("")
                  .map((digit, index) => (
                    <Text
                      key={index}
                      style={{
                        width: "12.5%",
                        textAlign: "center",
                        borderRight:
                          index < String(form.sourceEconomicCode).length - 1
                            ? 1
                            : 0,
                        paddingTop: 3,
                      }}
                    >
                      {digit}
                    </Text>
                  ))}
              </View>
            </View>
            <View
              style={{
                width: "100%",
                marginTop: 30,
              }}
            >
              <Text
                style={{
                  width: "100%",
                  textAlign: "center",
                }}
              >
                Fund Code
              </Text>
              <View
                style={{
                  width: "100%",
                  border: 1,
                  flexDirection: "row",
                }}
              >
                {String(form.fund_code.fund_code)
                  .split("")
                  .map((digit, index) => (
                    <Text
                      key={index}
                      style={{
                        width: "12.5%",
                        textAlign: "center",
                        borderRight:
                          index < String(form.fund_code.fund_code).length - 1
                            ? 1
                            : 0,
                        paddingTop: 3,
                      }}
                    >
                      {digit}
                    </Text>
                  ))}
              </View>
            </View>
            <View
              style={{
                width: "100%",
                marginTop: 30,
              }}
            >
              <Text
                style={{
                  width: "100%",
                  textAlign: "center",
                }}
              >
                Functional Code
              </Text>
              <View
                style={{
                  width: "100%",
                  border: 1,
                  flexDirection: "row",
                }}
              >
                {String(form.functional_code.functional_code)
                  .split("")
                  .map((digit, index) => (
                    <Text
                      key={index}
                      style={{
                        width: "40%",
                        textAlign: "center",
                        borderRight:
                          index <
                          String(form.functional_code.functional_code).length -
                            1
                            ? 1
                            : 0,
                        paddingTop: 3,
                      }}
                    >
                      {digit}
                    </Text>
                  ))}
              </View>
            </View>
            <View
              style={{
                width: "100%",
                marginTop: 30,
              }}
            >
              <Text
                style={{
                  width: "100%",
                  textAlign: "center",
                }}
              >
                Program Code
              </Text>
              <View
                style={{
                  width: "100%",
                  border: 1,
                  flexDirection: "row",
                }}
              >
                {String(form.program_code.program_code)
                  .split("")
                  .map((digit, index) => (
                    <Text
                      key={index}
                      style={{
                        width: "10%",
                        textAlign: "center",
                        borderRight:
                          index <
                          String(form.program_code.program_code).length - 1
                            ? 1
                            : 0,
                        paddingTop: 3,
                      }}
                    >
                      {digit}
                    </Text>
                  ))}
              </View>
            </View>
            <View
              style={{
                width: "100%",
                marginTop: 30,
              }}
            >
              <Text
                style={{
                  width: "100%",
                  textAlign: "center",
                }}
              >
                Goe Code
              </Text>
              <View
                style={{
                  width: "100%",
                  border: 1,
                  flexDirection: "row",
                }}
              >
                {String(form.geo_code.geo_code)
                  .split("")
                  .map((digit, index) => (
                    <Text
                      key={index}
                      style={{
                        width: "12.5%",
                        textAlign: "center",
                        borderRight:
                          index < String(form.geo_code.geo_code).length - 1
                            ? 1
                            : 0,
                        paddingTop: 3,
                      }}
                    >
                      {digit}
                    </Text>
                  ))}
              </View>
            </View>
            <View
              style={{
                width: "100%",
                marginTop: 50,
              }}
            >
              <Text>Date received in Treasury </Text>
              <Text>............................................</Text>
              <Text
                style={{
                  marginBottom: 15,
                }}
              >
                Check and passed at{" "}
              </Text>
              <Text>............................................</Text>
              <Text
                style={{
                  marginBottom: 15,
                }}
              >
                For{" "}
              </Text>
              <Text
                style={{
                  marginBottom: 15,
                }}
              >
                Payable only at{" "}
              </Text>
              <Text>Checked by internal Auditor</Text>
              <Text>(Name and Signature)</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
