const allModule = {
  account: {
    name: "Account Chart",
    type: [
      "Dashboard",
      "Account Chart",
      "Administrative Segment",
      "Economic Segment",
      "Functional Segment",
      "Programme Segment",
      "Fund Segment",
      "Geo Segment",
      "User Management",
    ],
  },

  budget: {
    name: "Budget Ceiling",
    type: ["Sector Ceilling", "MDA Ceilling"],
  },

  mda_mapping: {
    name: "MDA Mapping",
    type: [
      "MDA Mapping",
      "Economic Segment Mapping",
      "Administrative Segment Mapping",
      "Functional Segment Mapping",
      "Programme Segment Mapping",
      "Fund Segment Mapping",
      "Geo Segment Mapping",
    ],
  },

  approval_collection: {
    name: "Release Processing",
    type: [
      "Release Processing",
      "Internal Control",
      "Approval Review",
      "Release Cancellation",
      "Unclear Release",
    ],
  },
  virment: {
    name: "Virement",
    type: ["Planning Reserve"],
  },

  ccopies: {
    name: "CC Copies",
    type: ["CC_Copies"],
  },
  budget_type: {
    name: "Budget Procceses",
    type: [
      "Proposed Budget",
      "MOPB Recomendation",
      "Exco Recomedation",
      "House Recommendation",
      "Approve Recommendation",
      "Supplementary",
      "Revised Estimate",
    ],
  },
  reports: {
    name: "Reports",
    type: [
      "Approved Budget",
      "Budget Report Tracker",
      "Budget Overview",
      "Expenditure by Department",
      "Expenditure by MDA",
      "Release Report",
      "Revenue Reports by Admin",
      "Expenditure Reports by Admin",
      "Total Revenue by Admin",
      "Exp by Fund",
      "Exp by Location",
      "Exp by Function",
      "Exp by Programme",
      "Oil Rec Revenue by Admin",
      "Oil Capital Rec Revenue by Admin",
      "Total Revenue by Economic",
      // "Capital Receipts by item",
      "Total Revenue by Fund",
      "Receipt Revenue by Fund",
      "Capital Receipts by Fund",
      "Total Expenditure by Fund",
      "Personnel Expenditure by Fund",
      "Overhead Expenditure by Fund",
    ],
  },
  m_and_e: {
    name: "M And E",
    type: [
      "Agriculture Sector",
      "Education Sector",
      "Commerce Sector",
      "Nature Resource Sector",
      "Health Sector",
      "Housing and Transport Sector",
      "Goods Transfer Form",
      "Kano State M&E",
      "Sample Monitoring",
    ],
  },
  setup: {
    name: "Admin",
    type: ["Set up"],
  },
};

export default allModule;
