/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Input, Label } from "reactstrap";
import PropTypes from "prop-types";

export default function InputForm(props) {
  // eslint-disable-next-line react/prop-types
  const { label = "", required = false } = props;
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Label className="Label mt-2" style={{ fontWeight: "lighter" }}>
        {label} {required && <span className="text-danger">*</span>}
      </Label>

      <input {...props} className="form-control" />
    </div>
  );
}

InputForm.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
};
