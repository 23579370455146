/* eslint-disable react/prop-types */
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";

// Data
import React, { useEffect, useRef, useState } from "react";
import Scrollbar from "components/Tree/Scrollbar";

import moment from "moment";
import { _fetchApi } from "redux/action/api";
import { useYear } from "useYearList";
import { Input } from "reactstrap";
import { capitalizeWords } from "redux/action/api";
import { Spinner } from "reactstrap";
import { ButtonGroup, Button, Modal } from "reactstrap";
import MDAReportModal from "./MDAReportsModal";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { formatNumber } from "redux/action/api";
import { DownloadTableExcel } from "react-export-table-to-excel";
import htmlDocx from "html-docx-js/dist/html-docx";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { getNextYear } from "../getBudgetYear";
export default function CapitalExpShare({
  title = "Capital Expenses by Project",
  route = "/get-report",
  options = [],
  type = "",
}) {
  const [treeData, setTreeData] = useState([]);
  const [form, setForm] = useState({
    budget_year: "",
  });
  const years = useYear();
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    value: type,
    title,
  });

  useEffect(() => {
    getNextYear((data) => setForm((p) => ({ ...p, budget_year: data })));
  }, []);

  const [selectMDA, setSelectMDA] = useState({});
  const [modal, setModal] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setModal(!modal);
  const toggle1 = () => setDropdownOpen(!dropdownOpen);

  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  const handleButtonClick = (value, title) => {
    setSelectedOption({ value, title });
  };

  useEffect(() => {
    setLoading(true);
    _fetchApi(
      `${route}?query_type=${selectedOption.value}&budget_year=${form.budget_year}`,
      (data) => {
        setLoading(false);
        setTreeData(data?.results);
      },
      (err) => console.log(err)
    );
  }, [form.budget_year, selectedOption.value]);

  const getTotal = (value) => {
    return treeData?.reduce(
      (total, item) => total + parseFloat(item[value] || 0),
      0
    );
  };
  // const headerColors = ["#000", "#666", "#999", "#ccc", "#eee"];
  let styles = {
    textAlign: "right",
    backgroundColor: "#eee",
    fontWeight: "bold",
  };
  const tableRef = useRef(null);
  const generateTable2 = () => {
    return (
      <table className="table table-bordered" ref={tableRef}>
        <thead>
          <tr
            style={{
              fontSize: "12px",
              borderWidth: 1,
              borderColor: "black",
              textAlign: "center",
              backgroundColor: "orange",
            }}
          >
            <th style={{ backgroundColor: "orange" }}>Project Name</th>
            <th style={{ backgroundColor: "orange" }}>Programme Code & Desc</th>
            <th style={{ backgroundColor: "orange" }}>
              Administrative Code & Desc
            </th>
            <th style={{ backgroundColor: "orange" }}>Economic Code & Desc</th>
            <th style={{ backgroundColor: "orange" }}>Function Code & Desc</th>
            <th style={{ backgroundColor: "orange" }}>Location Code & Desc</th>
            <th style={{ backgroundColor: "orange" }}>{`${
              parseInt(form.budget_year) - 2
            }  Full Year Actual(₦)`}</th>
            <th style={{ backgroundColor: "orange" }}>{`${
              parseInt(form.budget_year) - 1
            } Aproved Budget(₦)`}</th>
            <th style={{ backgroundColor: "orange" }}>{`${
              parseInt(form.budget_year) - 1
            } performance January To August(₦)`}</th>
            <th style={{ backgroundColor: "orange" }}>{`${parseInt(
              form.budget_year
            )} MDA Proposal`}</th>

            <th style={{ backgroundColor: "orange" }}>{`${parseInt(
              form.budget_year
            )} Approved Budget`}</th>
          </tr>
        </thead>
        <tbody>
          <tr style={{ fontSize: "12px", backgroundColor: "#eee" }}>
            <td style={{ ...styles, textAlign: "left" }}>
              Total Capital Expenditure
            </td>
            <td style={styles}></td>
            <td style={styles}></td>
            <td style={styles}></td>
            <td style={styles}> </td>
            <td style={styles}> </td>
            <td style={styles}>
              {formatNumber(getTotal("prev_year2_actual_amount"))}
            </td>
            <td style={styles}>
              {formatNumber(getTotal("prev_year1_approve_amount"))}
            </td>
            <td style={styles}>
              {formatNumber(getTotal("prev_year1_actual_amount"))}
            </td>
            <td style={styles}>{formatNumber(getTotal("budget_amount"))}</td>

            <td style={styles}>{formatNumber(getTotal("approve_amount"))}</td>
          </tr>
          {treeData?.map((item, index) => (
            <tr
              key={`row_${index}_${item.economic_code}`}
              style={{ fontSize: "12px" }}
            >
              <td>{item.description}</td>
              <td>
                {item.program_code}-{item.program_description}
              </td>
              <td>
                {item.admin_code}-{item.admin_description}
              </td>
              <td>
                {item.economic_code}-{item.economic_description}
              </td>
              <td>
                {item.function_code}-{item.function_description}
              </td>
              <td>
                {item.geo_code}-{item.geo_description}
              </td>
              <td style={{ textAlign: "right" }}>
                {isNaN(item.prev_year2_actual_amount) || 0
                  ? "---"
                  : formatNumber(item.prev_year2_actual_amount)}
              </td>
              <td style={{ textAlign: "right" }}>
                {isNaN(item.prev_year1_approve_amount) || 0
                  ? "---"
                  : formatNumber(item.prev_year1_approve_amount)}
              </td>
              <td style={{ textAlign: "right" }}>
                {isNaN(item.prev_year1_actual_amount) || 0
                  ? "---"
                  : formatNumber(item.prev_year1_actual_amount)}
              </td>
              <td style={{ textAlign: "right" }}>
                {isNaN(item.budget_amount) || 0
                  ? "---"
                  : formatNumber(item.budget_amount)}
              </td>

              <td style={{ textAlign: "right" }}>
                {isNaN(item.approve_amount) || 0
                  ? "---"
                  : formatNumber(item.approve_amount)}
              </td>
            </tr>
          ))}
        </tbody>
        {/* {JSON.stringify(treeData.length)} */}
      </table>
    );
  };

  const [pdfLoading, setPdfLoading] = useState(false);

  const handleExportToWord = () => {
    const tableHtml = tableRef.current.outerHTML;
    const converted = htmlDocx.asBlob(tableHtml);
    saveAs(converted, `${title}-report.docx`);
  };

  const handleExportToPDF = () => {
    setPdfLoading(true);
    const input = document.getElementById("table2");
    console.log(input);
    if (input) {
      html2canvas(input)
        .then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const pdf = new jsPDF("p", "mm", "a4");
          const imgProps = pdf.getImageProperties(imgData);
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
          pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
          pdf.save(`${title.replace(/ /g, "_")}.pdf`);
          setPdfLoading(false);
        })
        .catch((error) => {
          console.error("Error generating PDF:", error);
          setPdfLoading(false);
        });
    } else {
      console.error("Element not found");
      setPdfLoading(false);
    }
  };
  return (
    <DashboardLayout>
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <div>
              <Modal
                isOpen={modal}
                toggle={toggle}
                size="lg"
                style={{
                  marginLeft: "30%",
                }}
              >
                <MDAReportModal
                  toggle={toggle}
                  mda_name={selectMDA.description}
                />
              </Modal>
            </div>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h5" color="white">
                  {capitalizeWords(selectedOption.title)}
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {pdfLoading && (
                  <div className="loading-container text-center">
                    <Spinner color="primary" style={{ marginTop: "20px" }} />
                    Generating PDF, please wait...
                  </div>
                )}
                {/* {JSON.stringify({ treeData })} */}
                <div
                  className="d-flex justify-content-between"
                  style={{ marginRight: 10, marginLeft: 10 }}
                >
                  <div className="col-md-2">
                    <Input
                      type="select"
                      name="budget_year"
                      value={form.budget_year}
                      onChange={handleChange}
                    >
                      <option>--select--</option>
                      {years?.map((item) => (
                        <option key={item}>{item}</option>
                      ))}
                    </Input>
                  </div>

                  <div className="col-md-8">
                    <div className="text-center"></div>
                    <center>
                      <ButtonGroup>
                        {options.map((item) => (
                          <Button
                            size="sm"
                            key={item.title}
                            outline={
                              selectedOption.title !== item.title ? true : false
                            }
                            color={
                              selectedOption.title === item.title
                                ? "primary"
                                : "secondary"
                            }
                            onClick={() =>
                              handleButtonClick(item.value, item.title)
                            }
                          >
                            <small>{item.title}</small>
                          </Button>
                        ))}
                      </ButtonGroup>
                    </center>
                  </div>

                  {/* <Button
                    style={{ color: "white" }}
                    className="text-white mb-3 ml-3"
                    size="sm"
                    color="primary"
                  >
                    <CSVLink
                      data={treeData && treeData ? treeData : []}
                      className="text-white"
                      filename={selectedOption.title}
                    >
                      <FaFileDownload color="white" size="1.2rem" /> Download
                      Report
                    </CSVLink>
                  </Button> */}

                  <Dropdown isOpen={dropdownOpen} toggle={toggle1}>
                    <DropdownToggle
                      className="btn btn-primary text-white ml-3"
                      caret
                    >
                      {/* <FaFileDownload
                          color="white"
                          size="1.2rem"
                          className="mr-5"
                        /> */}
                      Download
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={() => console.log("Option 1 clicked")}
                      >
                        <DownloadTableExcel
                          filename={selectedOption.title}
                          sheet={selectedOption.title}
                          currentTableRef={tableRef.current}
                        >
                          Export to Excel
                        </DownloadTableExcel>
                      </DropdownItem>
                      {/* <DropdownItem onClick={() => setPdf(!pdf)}>
                          PDF
                        </DropdownItem> */}
                      <DropdownItem />
                      <DropdownItem onClick={handleExportToWord}>
                        Export Word
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>

                <div className="p-3">
                  {loading ? (
                    <div className="loading-container text-center">
                      <Spinner color="primary" style={{ marginTop: "20px" }} />{" "}
                      Loading...
                    </div>
                  ) : (
                    <Scrollbar>{generateTable2()}</Scrollbar>
                  )}
                </div>
              </MDBox>
              {/* {JSON.stringify(treeData)} */}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
