import React from "react";
import PropTypes from "prop-types";
import { Typeahead } from "react-bootstrap-typeahead";

const CustomTypeahead = React.forwardRef((props, ref) => {
  const {
    options,
    onInputChange,
    onChange,
    labelKey,
    label,
    placeholder,
    col,
    multiple = false,
    defaultSelected = [],
  } = props;

  return (
    <>
      <small style={{ fontSize: "12px" }}>{label}</small>
      <Typeahead
        id="basic-typeahead-single"
        labelKey={labelKey}
        multiple={multiple}
        onChange={onChange}
        onInputChange={onInputChange}
        options={options}
        placeholder={placeholder || ""}
        ref={ref}
        className="border-radius"
        defaultSelected={defaultSelected}
        {...props}
      />
    </>
  );
});

CustomTypeahead.propTypes = {
  options: PropTypes.array.isRequired,
  onInputChange: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  labelKey: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  col: PropTypes.number,
  multiple: PropTypes.bool,
  defaultSelected: PropTypes.array,
};

// Make sure this line is present:
export default CustomTypeahead;
