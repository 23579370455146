import ReportsAdminShareCom from "./ReportsAdminShareCom.js";

const listdata = [
  { value: "total_ex_by_program", title: " Total Exp. by Programme", id: 1 },
  {
    value: "ex_by_program_new",
    title: "Personnel Exp. By Programme",
    id: 2,
    code: "Personnel",
  },
  {
    value: "ex_by_program_new",
    title: "Other NDR Exp. By Programme",
    id: 3,
    code: "OverHead",
  },
  {
    value: "ex_by_program",
    title: "Debt Service By Programme",
    id: 5,
    code: "13%",
  },
  {
    value: "ex_by_program_new",
    title: "Capital Exp. by Programme",
    id: 4,
    code: "Capital",
  },
  {
    value: "ex_by_program",
    title: "Health Capital Exp. by Programme",
    id: 7,
    code: "04%",
  },
];

function ProgQuartely() {
  return (
    <ReportsAdminShareCom
      type="total_ex_by_program"
      title="Total Exp. by Programme"
      route="/reports"
      options={listdata}
    />
  );
}

export default ProgQuartely;
