/* eslint-disable react/prop-types */
import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import { postApi } from "redux/action/api_tbeams";

export default function ReleaseUnclear() {
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [budgetUtilization, setBudgetUtilization] = useState([]);
  const [editDropdownOpen, setEditDropdownOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null); // To store the index of the row where the dropdown is open
  const [reason, setReason] = useState("");
  const [form, setForm] = useState({
    date_from: "",
    date_to: "",
  });
  const [searchQuery, setSearchQuery] = useState("");
  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };

  const handleChanges = ({ target: { name, value } }) =>
    setForm((p) => ({ ...p, [name]: value }));

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const toggleEditDropdown = (idx) => {
    setEditDropdownOpen((prevState) => !prevState);
    setSelectedRow(idx);
  };

  const [modal1, setModal1] = useState(false);

  const toggle2 = () => setModal1(!modal1);

  const location = useLocation();
  const isPending = location.pathname.includes("payapp");

  const getApprovalCollection = useCallback(() => {
    setIsLoading(true);
    postApi(`post_approval_collection`, {
      form: {
        query_type: "by_filter",
        filter: "Unclear Released",
        // type: "release",
      },
    })
      .then((data) => {
        console.log("data", data);
        if (data.result) {
          const arr = data.result.map((item) => ({
            ...item,
            isOpen: false,
          }));
          setBudgetUtilization(arr);
        }
      })
      .catch((err) => {
        // Handle errors
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [isPending]);

  useEffect(() => {
    getApprovalCollection();
  }, [getApprovalCollection]);

  const filteredData = budgetUtilization.filter((item) =>
    item.mda_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const getRowStyle = (row) => {
    switch (row.approval_status) {
      case "Approval Released":
        return { backgroundColor: "orange" };
      case "Approval Processed":
        return { backgroundColor: "lightblue" };
      case "Approval Approved":
        return { backgroundColor: "lightgreen" };
      case "Approval Queried":
        return { backgroundColor: "yellow" };
      case "Payment Schedule":
        return { backgroundColor: "green" };
      default:
        return { backgroundColor: "red" };
    }
  };

  return (
    <DashboardLayout>
      <MDBox pt={2} pb={3} pl={2}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h5" color="white">
                  Unclear Release
                </MDTypography>
              </MDBox>
              <Row
                style={{
                  margin: 10,
                }}
              >
                <Col md={6}>
                  <Col md={11}>
                    <Label>Search From Table</Label>
                    <Input
                      type="text"
                      name="search"
                      placeholder="Search Table.................."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </Col>
                </Col>
                <Col md={3}>
                  <Label>Date From</Label>
                  <Input
                    type="date"
                    value={form.date_from}
                    name="date_from"
                    onChange={handleChanges}
                  />
                </Col>
                <Col md={3}>
                  <Label>Date To</Label>
                  <Input
                    type="date"
                    value={form.date_to}
                    name="date_to"
                    onChange={handleChanges}
                  />
                </Col>
              </Row>
              <div
                className="mt-4"
                style={{
                  width: "100%",
                  height: 462,
                  overflowX: "scroll",
                }}
              >
                <Table style={{ width: "150%" }} bordered>
                  <thead>
                    <tr>
                      <th
                        style={{
                          fontSize: 15,
                        }}
                      >
                        Action
                      </th>
                      <th
                        style={{
                          fontSize: 15,
                        }}
                      >
                        Economic Code
                      </th>
                      <th
                        style={{
                          fontSize: 15,
                        }}
                      >
                        MDA Name
                      </th>
                      <th
                        style={{
                          fontSize: 15,
                        }}
                      >
                        Collection Date
                      </th>
                      <th
                        style={{
                          fontSize: 15,
                        }}
                      >
                        Approval Date
                      </th>
                      <th
                        style={{
                          fontSize: 15,
                        }}
                      >
                        Approval No.
                      </th>
                      <th
                        style={{
                          fontSize: 15,
                        }}
                      >
                        Approval Type
                      </th>
                      <th
                        style={{
                          fontSize: 15,
                        }}
                      >
                        Status
                      </th>
                      <th
                        style={{
                          fontSize: 15,
                        }}
                      >
                        Amount
                      </th>
                    </tr>
                  </thead>
                  {isLoading ? (
                    <Spinner color="primary" />
                  ) : (
                    <tbody>
                      {filteredData.map((item, idx) => (
                        <tr key={idx}>
                          <td
                            style={{
                              fontSize: 15,
                              ...getRowStyle(item),
                            }}
                          >
                            <button
                              className="btn btn-primary"
                              style={{
                                marginRight: "10px",
                                marginLeft: "10px",
                              }}
                              onClick={toggle2}
                            >
                              Cancel
                            </button>
                          </td>
                          <td
                            style={{
                              fontSize: 15,
                              ...getRowStyle(item),
                            }}
                          >
                            {item.economic_code}
                          </td>
                          <td
                            style={{
                              fontSize: 15,
                              ...getRowStyle(item),
                            }}
                          >
                            {item.mda_name}
                          </td>
                          <td
                            style={{
                              fontSize: 15,
                              ...getRowStyle(item),
                            }}
                          >
                            {item.collection_date}
                          </td>
                          <td
                            style={{
                              fontSize: 15,
                              ...getRowStyle(item),
                            }}
                          >
                            {item.approval_date}
                          </td>
                          <td
                            style={{
                              fontSize: 15,
                              ...getRowStyle(item),
                            }}
                          >
                            {item.approval_no}
                          </td>
                          <td
                            style={{
                              fontSize: 15,
                              ...getRowStyle(item),
                            }}
                          >
                            {item.approved_by}
                          </td>
                          <td
                            style={{
                              fontSize: 15,
                              ...getRowStyle(item),
                            }}
                          >
                            {item.approval_status}
                          </td>
                          <td
                            style={{
                              fontSize: 15,
                              ...getRowStyle(item),
                            }}
                          >
                            {item.amount}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </Table>
              </div>
            </Card>
            <Modal isOpen={modal1} toggle={toggle2}>
              <ModalHeader toggle={toggle2}>
                Reason For Cancellation
              </ModalHeader>
              <ModalBody>
                <Col md={12}>
                  <Label>Reason For Cancellation</Label>
                  <Input
                    type="textarea"
                    value={reason}
                    name="reason"
                    onChange={handleReasonChange}
                  />
                </Col>
              </ModalBody>
              <ModalFooter>
                <center>
                  <Button color="primary" onClick={toggle2}>
                    Submit
                  </Button>{" "}
                  <Button color="secondary" onClick={toggle2}>
                    Cancel
                  </Button>
                </center>
              </ModalFooter>
            </Modal>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
