// import ReportsFundShareCom from "./ReportsFundShareCom.js";
import ReportsRevByFund from "./ReportsRevByFund.js";
const listdata = [
  {
    value: "total_ex_by_fund",
    title: " Total Expenditure by Fund",
  },
  {
    value: "ex_by_fund_new",
    title: "Personnel Expenditure By Fund",
    code: "Personnel",
  },
  {
    value: "ex_by_fund_new_one",
    title: "Other NDR Exp. Exp by Fund",
    code: "OverHead",
  },
  { value: "ex_by_fund", title: "Debt Service by Fund", code: "2206%" },
  {
    value: "ex_by_fund_new",
    title: "Capital Expenditure By Fund",
    code: "Capital",
  },
];
function ReportByFund() {
  return (
    // <ReportsFundShareCom
    <ReportsRevByFund
      type="total_ex_by_fund"
      title="Total Expenditure by Fund"
      route="/reports"
      options={listdata}
    />
  );
}

export default ReportByFund;
