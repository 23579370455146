import { combineReducers } from "redux";

import auth from "./auth";
import budget_newReducer from "./budget_new";

const rootReducer = combineReducers({
  auth,
  budget_new: budget_newReducer,
});

export default rootReducer;
