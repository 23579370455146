import { _fetchApi } from "redux/action/api";
import ReportsExpShareCom from "./ReportsExpShareCom";

export default function AppropriationLaw({}) {
  const data = [{ title: "Appropriation Law", value: "expenditure_by_mda" }];
  return (
    <ReportsExpShareCom
      type="total_ex_by_eco"
      title="Total Expenditure by Economic"
      route="/reports"
      options={data}
      newPdf={true}
    />
  );
}
