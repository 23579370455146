import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import "bootstrap/dist/css/bootstrap.min.css";
import { MaterialUIControllerProvider } from "context";
// import "react-toastify/dist/ReactToastify.css";

const container = document.getElementById("app");
const root = createRoot(container);
import store from "./redux/store";
import { Provider } from "react-redux";
import { ToastProvider } from "react-toast-notifications";
import WhatsAppFloatingIcon from "WhatsAppFloatingIcon";
root.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <Provider store={store}>
        <ToastProvider>
          <App />
          <WhatsAppFloatingIcon />
        </ToastProvider>
      </Provider>
    </MaterialUIControllerProvider>
  </BrowserRouter>
);
