/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export default function CheckBoxNormal({
  label,
  name,
  handleCheck,
  value = [],
  className,
}) {
  const [checked, setChecked] = useState(
    value.indexOf(label) !== -1 ? true : false
  );
  useEffect(
    () => setChecked(value.indexOf(label) !== -1 ? true : false),
    [value]
  );
  return (
    <>
      <FormControlLabel
        // style={{ fontWeight: "bold", fontSize: "10px" }}
        control={
          <Checkbox
            checked={checked}
            onChange={({ target }) => {
              handleCheck(name, target.checked, label);
              setChecked(target.checked);
            }}
          />
        }
        label={label}
        className={className}
      />
    </>
  );
}
