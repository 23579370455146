import {
  AUTH,
  LOADING_LOGIN,
  LOADING_SIGNUP,
  LOGOUT,
  AUTH_ERROR,
  ERRORS,
  APPROVAL_DISPHATCH,
} from "../action/type";

const initialState = {
  authenticated: false,
  user: {},
  loadingLogin: false,
  loadingSignup: false,
  requestIsValid: true,
  error: "",
  loadingApp: false,
  item_code: '',
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case AUTH:
      return {
        ...state,
        user: action.payload,
        authenticated: true,
      };
    case APPROVAL_DISPHATCH:
      return {
        ...state,
        item_code: action.payload,
      };
    case LOADING_LOGIN:
      return {
        ...state,
        loadingLogin: !state.loadingLogin,
      };
    case ERRORS:
      return {
        ...state,
        error: action.payload,
      };
    case LOADING_SIGNUP:
      return {
        ...state,
        loadingSignup: !state.loadingSignup,
      };
    case AUTH_ERROR:
      return {
        ...state,
        error: "",
      };
    case LOGOUT:
      return {
        ...state,
        authenticated: false,
        user: {},
      };
    default:
      return state;
  }
}
