import React, { useEffect, useState } from "react";

import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import { AiFillEdit, AiFillEye } from "react-icons/ai";
import { FiDelete, FiEdit } from "react-icons/fi";
import { getSector } from "helper";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import { Card, Grid } from "@mui/material";
import SearchBar from "components/SearchBar";
import CustomTable from "components/CustomTable";
import { _postApi } from "redux/action/api";
import Footer from "examples/Footer";
import MDTypography from "components/MDTypography";
import { _fetchApi } from "redux/action/api";
import Scrollbar from "components/Tree/Scrollbar";
import { Button, Input, Label } from "reactstrap";
import { formatNumber } from "redux/action/api";
import CustomTypeahead from "components/CustomTypeahead";
import { year } from "helper";
import { useToasts } from "react-toast-notifications";
import { useYear } from "useYearList";

function Suplementary() {
  const years = useYear();

  const [form, setForm] = useState({
    budget_year: year,
  });

  const fields = [
    {
      title: "Economic Description",
      custom: true,
      component: (ite) => ite.economic_description,
    },
    {
      title: "Economic Code",
      custom: true,
      component: (item) => item.economic_code,
    },
    {
      title: "Fund Description",
      custom: true,
      component: (ite) => ite.fund_description,
    },
    { title: "Fund Code", custom: true, component: (ite) => ite.fund_code },
    {
      title: "Program Description",
      custom: true,
      component: (ite) => ite.program_description,
    },
    {
      title: "Program Code",
      custom: true,
      component: (ite) => ite.program_code,
    },
    {
      title: "Functional Description",
      custom: true,
      component: (ite) => ite.function_description,
    },
    {
      title: "Functional code",
      custom: true,
      component: (ite) => ite.function_code,
    },
    {
      title: "Geo Description",
      custom: true,
      component: (ite) => ite.geo_description,
    },
    { title: "Geo code", custom: true, component: (ite) => ite.geo_code },
    {
      title: "Proposed Amount",
      custom: true,
      component: (ite) => (
        <div style={{ float: "right" }}> {formatNumber(ite.budget_amount)}</div>
      ),
    },
    {
      title: "MOPB Recom",
      custom: true,
      component: (ite) => formatNumber(ite.mopb_amount),
    },
    {
      title: "Exco Recom",
      custom: true,
      component: (ite) => formatNumber(ite.exco_amount),
    },
    {
      title: "House Recom",
      custom: true,
      component: (ite) => formatNumber(ite.house_amount),
    },
    {
      title: "Approved Recom",
      custom: true,
      component: (ite) => formatNumber(ite.approve_amount),
    },
    {
      title: "supplementary Amount",
      custom: true,
      component: (ite, idx) => {
        editingIndex === idx ? (
          <Input
            name="supplementary_amount"
            value={ite.supplementary_amount}
            onChange={({ target: { value } }) => handleChanges(value, idx)}
            onBlur={handleInputBlur}
            style={{ width: "120px", alignItems: "right" }}
          />
        ) : (
          formatNumber(ite.supplementary_amount)
        );
      },
    },
  ];
  const [results, setResults] = useState();
  // let data = results&&results.map(item => item.name)

  useEffect(() => {
    _fetchApi(
      `/get-budget-new/${form.mda_name}?query_type=select&budget_year=${form.budget_year}`,
      (res) => {
        const modifiedResults = res.results.map((item) => {
          if (!item.mopb_amount) {
            return { ...item, mopb_amount: item.budget_amount };
          }
          return item;
        });
        setResults(modifiedResults);
      },
      (err) => {
        console.log(err);
      }
    );
  }, [form.mda_name, form.budget_year]);

  const [results5, setResults5] = useState([]);
  // let data = results&&results.map(item => item.name)
  useEffect(() => {
    getSector({ type: "ADMINISTRATIVE SEGMENT" }, "select", setResults5);
  }, []);
  const [editingIndex, setEditingIndex] = useState(-1);

  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  const handleInputBlur = () => {
    setEditingIndex(-1);
  };
  const handleChanges = (value, index) => {
    const arr = [...results];
    arr[index].supplementary_amount = value;
    setResults(arr);
  };
  const { addToast } = useToasts();

  const handleSubmit = (arr) => {
    // alert(JSON.stringify([arr]))
    _postApi(
      `/post-budget?query_type=UPDATE`,
      results,
      (res) => {
        if (res.success) {
          addToast("Submit successfully", {
            appearance: "info",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
          setData([]);
        }
      },
      (err) => {
        console.log(err);
        // alert('an  error occured!!!!!!')
      }
    );
  };
  const totalAmount = results?.reduce(
    (total, item) =>
      parseFloat(
        item.approve_amount ||
          item.budget_amount ||
          item.house_amount ||
          item.exco_amount ||
          item.supplementary_amount
      ) + total,
    0
  );

  const [results4, setResult4] = useState([]);
  useEffect(() => {
    getSector({ type: "mdas" }, "mdas", (d) => {
      setResult4(d);
    });
  }, []);
  let aheadData = results4?.map((i) => ({
    head: `${i.head}-${i.description}`,
    description: i.description,
    code: i.head,
  }));
  return (
    <DashboardLayout>
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography
                  variant="h5"
                  color="white"
                  className="text-center"
                >
                  Supplementary Budget Page
                </MDTypography>
              </MDBox>
              <MDBox>
                <MDBox pt={3}>
                  <div className=" row mb-4 p-3">
                    <div className="col-md-4 mb-3">
                      <CustomTypeahead
                        labelKey="head"
                        label="Select MDA"
                        options={aheadData}
                        onChange={(s) => {
                          if (s.length) {
                            console.log(s);
                            setForm((p) => ({
                              ...p,
                              mda_code: s[0].code,
                              mda_name: s[0].description,
                            }));
                          }
                        }}
                      />
                    </div>
                    <div className="col-md-4 " />
                    <div className="col-md-4 ">
                      <Label>Select budget year</Label>
                      <Input
                        type="select"
                        name="budget_year"
                        value={form.budget_year}
                        onChange={handleChange}
                      >
                        <option>--select--</option>
                        {years?.map((item) => (
                          <option key={item}>{item}</option>
                        ))}
                      </Input>
                    </div>
                    {/* <button  style={{float:"left",}} className="mb-4 btn btn-primary col-md-2" onClick={()=>navigate("form")} ><FaPlus /> Add Sub-Org </button> */}
                    <SearchBar />
                    <div className="">
                      {" "}
                      <Button
                        color="primary"
                        className="pr-5 pl-5 "
                        style={{ float: "right" }}
                        onClick={handleSubmit}
                      >
                        Submit
                      </Button>
                    </div>
                    <span style={{ fontWeight: "bold", float: "right" }}>
                      TOTAL : ₦{totalAmount?.toLocaleString()}
                    </span>
                    {/* <h1>i am sub org</h1>  */}
                    <Scrollbar>
                      <CustomTable fields={fields} data={results} />
                    </Scrollbar>
                    <center>
                      <Button
                        color="primary"
                        className="pr-5 pl-5"
                        onClick={handleSubmit}
                      >
                        Submit
                      </Button>
                    </center>
                  </div>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Suplementary;
