import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Input,
  Label,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import { useDropzone } from "react-dropzone";
import Attachment from "./Attachment";
import { useQuery } from "redux/action/api";
import { fetchApi, postApi } from "redux/action/api_tbeams";
import { apiURL } from "redux/action/api_tbeams";
import CapitalProject from "./CapitalProject";
import WarningModal from "./WarningModal";

export default function EditNewReleaseCollection({}) {
  const query = useQuery();
  const mdaname = query.get("mda_name");
  const mdacode = query.get("mda_code");
  const imageId1 = query.get("imageId");
  const mdaRef = useRef();
  const navigate = useNavigate();
  const [state, setState] = useState({});
  const [treasuryAccount, setTreasuryAccount] = useState([]);
  const [mdaAccount, setMdaAccount] = useState([]);
  const [paymentScheduleTable, setPaymentScheduleTable] = useState([]);
  const [budgetSummary, setBudgetSummary] = useState([]);
  const [loading, setLoading] = useState(false);

  const [economicCode, setEconomicCode] = useState([]);
  const [attachmentModalIsOpen, setAttachmentModalIsOpen] = useState(false);
  const [childCode, setChildCode] = useState([]);
  const [batch_no, setBatch_no] = useState("");
  const tresuryTypeahead = useRef();
  const economicTypeahead = useRef();
  const [approvalImageInfo, setApprovalImageInfo] = useState([]);
  const mdaCodeTypeahead = useRef();
  const mdaTypeahead = useRef();
  const [modal, setModal] = useState(false);
  const [batchCode, setBatchCode] = useState("");
  const today = moment().format("MM/DD/YYYY");
  const _id = query.get("id");
  const imageId = moment().format("YYYYMMDDhhmmss");
  const [mdaBudgetBalance, setMdaBudgetBalance] = useState(true);
  // moment(testDate).format('MM/DD/YYYY');
  const id = useParams()?.id;
  const { idx } = useParams();
  const string = moment("2020/05/12").toISOString(true);
  const [selectData, setSelectData] = useState("");
  const [form, setForm] = useState({
    collection_date: moment().format("YYYY-MM-DD"),
    approval_date: moment().format("YYYY-MM-DD"),
    mda_name: "",
    mda_code: "",
    mda_description: "",
    mda_budget_balance: "",
    mda_economic_code: "",
    approved_by: "",
    approved_amount: "",
    approval: "Approved",
    query_type: "insert",
    imageId,
    narration: "Being payment for ",
    approval_no: "",
    approval_status: "Approval Released",
    add_remark: "",
    type: "release",
  });

  const selecOffer = (n) => {
    let num = Math.round(parseInt(n), 0);
    if (num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    } else {
      return "0";
    }
  };

  const handleSubmit = () => {
    if (
      parseFloat(form.approved_amount) > parseFloat(form.mda_budget_balance)
    ) {
      setShowModal(true);
      return;
    }

    setLoading(true);

    postApi("your-s", { formData: form })
      .then((response) => {
        console.log("Form submitted successfully:", response);
      })
      .catch((error) => {
        console.error("Form submission failed:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: "image/*, .pdf, text/plain" });

  const toggle = () => setModal(!modal);
  const [steps, setSteps] = useState("1");
  const [showModal, setShowModal] = useState(true);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle1 = () => setDropdownOpen((prevState) => !prevState);

  const handleSubmitReset = () => {
    setForm((p) => ({
      ...p,
      collection_date: "",
      approval_date: "",
      mda_name: "",
      mda_description: "",
      mda_budget_balance: "",
      mda_economic_code: "",
      approved_by: "",
    }));

    clearEconomicTypeahead();
    // clearMdaTypeahead()
    clearMdaCodeTypeahead();
    // clearTreasuryTypeahead()
  };

  // useEffect(() => {

  //   getList1()

  // }, [])

  const getList = () => {
    fetchApi(`get-budget-summary?query_type=list_new`)
      .then((d) => {
        console.log("d", d);
        if (d.result) {
          setBudgetSummary(d.result);
        }
      })
      .catch((err) => console.log(err));
  };
  //

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    postApi(`select_mda_bank_details`, {
      query_type: "select",
    }).then((data) => {
      if (data.result) {
        console.log(data.result);

        const treasuryAccount = data.result.filter(
          (item) => item.account_type === "treasury_source_account"
        );
        setTreasuryAccount(treasuryAccount);

        const mdaAccount = data.result.filter(
          (item) => item.account_type === "mda_source_account"
        );
        setMdaAccount(mdaAccount);
      }
    });
  }, []);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (e.target.type === "checkbox") {
      setForm((p) => ({ ...p, [name]: value }));
    } else {
      setForm((p) => ({ ...p, [name]: value }));
    }
  };

  const getList3 = (economic_code) => {
    fetchApi(
      `get-budget-summary?query_type=fetch_budget&mda_code=${form.mda_code}&economic_code=${economic_code}`
    )
      .then((d) => {
        console.log(d);
        if (d.result) {
          console.log("Budget", d);
          const selectedItem = d.result[0];
          setForm((p) => ({
            ...p,
            description: selectedItem.budget_description,
            mda_budget_balance:
              selectedItem.budget_amount == 0
                ? "Budget Exausted"
                : selectedItem.budget_amount,
            budget:
              selectedItem.budget_amount == 0
                ? "Budget Exausted"
                : selectedItem.budget_amount,
          }));
        }
      })
      .catch((err) => console.log(err));
  };

  const handleEconomicCode = (selected) => {
    if (selected.length) {
      console.log("selected budget", selected);
      const selectedItem = selected[0];
      // setOptions(selected)
      setForm((p) => ({
        ...p,
        mda_economic_code: selectedItem.economic_code,
        // budget : selectedItem.economic_code
      }));
      getList3(selectedItem.economic_code);
    } else {
      console.log("no item selected");
    }

    console.log(selected);
  };

  const getList2 = useCallback((mda_code) => {
    fetchApi(
      `get-budget-summary?query_type=select_economic_code&&mda_code=${mda_code}`
    )
      .then((d) => {
        console.log(d);
        if (d.result) {
          console.log(d);
          setEconomicCode(d.result);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const getList5 = useCallback(() => {
    if (mdacode) {
      fetchApi(
        `get-budget-summary?query_type=select_economic_code&&mda_code=${mdacode}`
      )
        .then((d) => {
          console.log(d);
          if (d.result) {
            console.log(d);
            setEconomicCode(d.result);
            economicTypeahead.current.focus();
          }
        })
        .catch((err) => console.log(err));
    }
    mdaRef.current.focus();
  }, [mdacode]);
  //

  useEffect(() => {
    getList5();
  }, [getList5]);

  const handleMdaCode = (selected) => {
    if (selected.length) {
      console.log(selected);
      const selectedItem = selected[0];
      // setOptions(selected)
      setForm((p) => ({
        ...p,
        mda_code: selectedItem.mda_code,
        mda_name: selectedItem.mda_name,
        // mda_economic_code : selectedItem.economic_code
      }));

      getList2(selectedItem.mda_code);
    } else {
      console.log("no item selected");
    }
  };

  const handleApi = () => {
    postApi(`post_approval_collection`, {
      form,
    })
      .then((data) => {
        if (data.success) {
          console.log(data);
          handleApi1(data.approval_no);
          // setBatchCode(data.batch_code1)
          // updateBudgetCode('batch_code', 'update')
          handleSubmitReset();
          addToast("Saved Successfully", {
            appearance: "info",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
          history("/release-collection");
          // toggle()
          // setPaymentScheduleTable([])
        }
      })

      .catch((err) => {
        alert(err);
        addToast(err, {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
        // setLoadSpinner(false);
      });
  };

  const handleUpdate = () => {
    postApi(`post_payment_schedule`, {
      mda_account_name: form.mda_account_name,
      mda_account_no: form.mda_account_no,
      mda_bank_name: form.mda_bank_name,
      mda_acct_sort_code: form.mda_acct_sort_code,
      mda_code: form.mda_code,
      mda_name: form.mda_name,
      mda_description: form.mda_description,
      mda_budget_balance: form.mda_budget_balance,
      mda_economic_code: form.mda_economic_code,
      amount: form.amount,
      description: form.description,
      attachment: form.attachment,
      payment_type: form.payment_type,
      budget: form.budget,
      approval: form.approval,
      status: form.status,
      narration: form.narration,
      budget: form.mda_budget_balance,
      approval: form.approval,
      query_type: "UPDATE_BY_ID",
      id,
    })
      .then((data) => {
        if (data.success) {
          console.log(data);
          handleSubmitReset();
          addToast("Update Successfull", {
            appearance: "info",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });

          history.push(Routes.batchProcessing.index);
        }
      })

      .catch((err) => {
        alert(err);
        addToast(err, {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });

        // setLoadSpinner(false);
      });
  };

  const fetchMdaBankDetails = useCallback(() => {
    const query_type = "select";
    fetchApi(`select_mda_bank_details`, {
      query_type,
    })
      .then((data) => {
        console.log(data);
        setChildCode(data.result);
      })

      .catch((err) => {
        console.log(err);
      });

    console.log(childCode);
  });

  useEffect(() => {
    fetchMdaBankDetails();
  }, [fetchMdaBankDetails]);

  const clearEconomicTypeahead = () => {
    economicTypeahead.current.clear();
  };

  const clearMdaCodeTypeahead = () => {
    // mdaCodeTypeahead.current.clear();
  };

  useEffect(() => {
    postApi(`post_payment_schedule`, {
      query_type: "select_by_id",
      id,
    }).then((data) => {
      // console.log("datay", data.result[0]);
      if (data?.result?.length) {
        setForm(data.result[0]);
        setMdaBudgetBalance(data.result[0].mda_budget_balance);
        mdaTypeahead.current.setState({
          text: data.result[0].mda_account_name,
        });

        economicTypeahead.current.setState({
          text: data.result[0].mda_economic_code,
        });
        tresuryTypeahead.current.setState({
          text: data.result[0].treasury_account_name,
        });
        mdaCodeTypeahead.current.setState({ text: data.result[0].mda_name });
      }
    });
  }, [id]);

  const handleApi1 = (approval_no) => {
    // console.log(form)

    if (form.approved_amount && form.approved_amount !== "") {
      const formData = new FormData();
      form.approval_no = approval_no;
      for (let i = 0; i < acceptedFiles.length; i++) {
        formData.append("files", acceptedFiles[i]);
      }

      if (!acceptedFiles.length) {
        setForm((p) => ({ ...p, approval: "not approved" }));
        formData.append("form", JSON.stringify(form));
        // // formData.append('event_name', options[0].event_name)

        fetch(`${apiURL}/post_images`, {
          method: "POST",

          body: formData,
        })
          .then((response) => response.json())
          .then((result) => {
            // handleApi()
            // setLoadSpinner(false)
            console.log(result);
            // removeAll()
            // toggle()
            // let acceptedFiles = []

            // localStorage.setItem("key", JSON.stringify(result.token));
            // setName(result.user)
            // history.push("/homepage/payment_table");

            // console.log(result);
          })

          .catch((err) => {
            console.log(err);
            // alert(err);
            // setLoadSpinner(false)
          });
      } else {
        formData.append("form", JSON.stringify(form));
        // // formData.append('event_name', options[0].event_name)

        fetch(`${apiURL}/post_images`, {
          method: "POST",

          body: formData,
        })
          .then((response) => response.json())
          .then((result) => {
            // handleApi()
            // setLoadSpinner(false)
            console.log(result);
            // removeAll()
            // toggle()
            // let acceptedFiles = []

            // localStorage.setItem("key", JSON.stringify(result.token));
            // setName(result.user)
            // history.push("/homepage/payment_table");

            // console.log(result);
          })

          .catch((err) => {
            console.log(err);
            // alert(err);
            // setLoadSpinner(false)
          });
      }
      // Object.keys(form).forEach(item => {
      //   formData.append(item, form[item])
      // })

      // formData.append('user', name.email)
      //  formData.append('group_id', groupName)
    } else {
      // alert("Please enter a valid amount");
      addToast("Please enter a valid amount", {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 5000,
      });
    }
  };

  const getApprovalById = useCallback(() => {
    if (_id) {
      fetchApi(
        `get-approve-col?id=${_id}`,
        (res) => {
          if (res.success) {
            setForm({
              ...res.result[0],
              approved_amount: res.result[0].amount,
              query_type: "update_app",
            });
          }
        },
        (err) => {
          // alert(`error occured ${err}`);
          addToast(`error occured${err}`, {
            appearance: "error",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
        }
      );
    }
  }, [_id]);

  useEffect(() => {
    getApprovalById();
  }, [getApprovalById]);

  useEffect(() => {
    if (idx) {
      fetch(`post_approval_collection/${idx}`)
        .then((response) => response.json())
        .then((data) => {
          // Populate form fields with fetched data
          setForm({
            ...data,
            collection_date: moment(data.collection_date).format("YYYY-MM-DD"),
            approval_date: moment(data.approval_date).format("YYYY-MM-DD"),
          });
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [idx]);

  return (
    <DashboardLayout>
      {/* {JSON.stringify(form)} */}
      <MDBox pt={2} pb={3} pl={2}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h5" color="white">
                  New Release Collection
                </MDTypography>
              </MDBox>
              <Col
                md={5}
                style={{
                  marginLeft: 9,
                }}
                className="mt-4"
              >
                <button
                  className="btn btn-primary"
                  style={{ marginRight: "10px", marginLeft: "10px" }}
                  onClick={() => navigate("/release-collection")}
                >
                  Click here to go back
                </button>
              </Col>

              <Row className="mt-1 px-4 py-4">
                <Col md={4}>
                  <Label>Collection Date</Label>
                  <Input
                    type="date"
                    value={form.collection_date}
                    name="collection_date"
                    onChange={handleChange}
                  />
                </Col>
                <Col md={4}>
                  <Label>Approval Date</Label>
                  <Input
                    type="date"
                    value={form.approval_date}
                    name="approval_date"
                    onChange={handleChange}
                  />
                </Col>
                <Col md={4}>
                  <Label>Select MDA</Label>
                  <Typeahead
                    size="sm"
                    // disabled={id ? true : false}
                    id="20"
                    onChange={(selected) => handleMdaCode(selected)}
                    onInputChange={(text) => console.log(text)}
                    options={budgetSummary}
                    labelKey={(option) => `${option.mda_name}`}
                    placeholder="Select---"
                    //   ref={mdaCodeTypeahead}
                    defaultInputValue={mdaname ? mdaname : ""}
                    ref={mdaRef}
                  />
                </Col>
                <Col md={4}>
                  <Label>Administrative Code</Label>
                  <Input
                    type="number"
                    value={form.mda_code}
                    name="mda_code"
                    onChange={handleChange}
                    disabled
                  />
                </Col>
                <Col md={4}>
                  <Label>Select Economic Code</Label>
                  <Typeahead
                    size="sm"
                    // disabled={id ? true : false}
                    id="20"
                    onChange={(selected) => handleEconomicCode(selected)}
                    onInputChange={(text) => console.log(text)}
                    options={economicCode}
                    labelKey={(option) =>
                      `${option.economic_code} - ${option.description}`
                    }
                    placeholder="Select---"
                    ref={economicTypeahead}
                    // defaultInputValue={form.mda_name}
                  />
                </Col>
                <Col md={4}>
                  <Label>Description</Label>
                  <Input
                    type="text"
                    value={form.description}
                    name="description"
                    onChange={handleChange}
                    disabled
                  />
                </Col>
                <Col md={4}>
                  <Label>
                    Budget Balance({selecOffer(form.mda_budget_balance)})
                  </Label>
                  <Input
                    type="number"
                    value={form.mda_budget_balance}
                    name="mda_budget_balance"
                    onChange={handleChange}
                    disabled
                  />
                </Col>
                <Col md={4}>
                  <Label>Approved By</Label>
                  <Input
                    id="exampleSelect"
                    name="approved_by"
                    value={form.approved_by}
                    type="select"
                    onChange={handleChange}
                  >
                    <option>--Select--</option>
                    <option value="Exco">Exco</option>
                    <option value="Others">Others</option>
                    <option value="HE">HE</option>
                  </Input>
                </Col>
                <Col md={4}>
                  <Label>Approved Amount({selecOffer(form.amount)})</Label>
                  <Input
                    type="number"
                    value={form.approved_amount}
                    name="approved_amount"
                    onChange={handleChange}
                  />
                </Col>
                <Col md={4}>
                  <Label>Narration</Label>
                  <Input
                    type="text"
                    value={form.narration}
                    name="narration"
                    onChange={handleChange}
                  />
                </Col>
                <Col md={4}>
                  <Label>Add Remark</Label>
                  <Input
                    type="text"
                    value={form.add_remark}
                    name="add_remark"
                    onChange={handleChange}
                  />
                </Col>
              </Row>

              <Col md={12} className="py-4 px-4">
                <Attachment
                  acceptedFiles={acceptedFiles} // Pass the acceptedFiles state
                  getRootProps={getRootProps}
                  getInputProps={getInputProps}
                  isDragActive={isDragActive}
                />
              </Col>

              <center className="mb-3">
                {!id ? (
                  <Button
                    disabled={loading}
                    onClick={handleApi}
                    // size="sm"
                    className="btn-success bg-success"
                  >
                    {loading && <Spinner />}
                    {_id ? "Update" : "Submit"}
                  </Button>
                ) : (
                  <Button
                    disabled={loading}
                    onClick={handleUpdate}
                    //   size="sm"
                    // className="btn-success"
                  >
                    {loading && <Spinner />}
                    Update
                  </Button>
                )}
              </center>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
