/* eslint-disable react/prop-types */
import { formControlClasses } from '@mui/material';
import CustomTypeahead from 'components/CustomTypeahead';
import { getSector } from 'helper';
import data from 'layouts/tables/data/authorsTableData';
import React, { useEffect, useRef, useState } from 'react'
import { Typeahead } from 'react-bootstrap-typeahead';
import { Button, Input } from 'reactstrap'
import { Label } from 'recharts';
import { _postApi } from 'redux/action/api';

export default function MappingForm({toggle}) {
    const [form,setForm]=useState({});
    
    const repRef = useRef();
    const repRef1 = useRef();
    const repRef2 = useRef();
    const repRef3 = useRef();
    const repRef4 = useRef();
    const repRef5 = useRef();
    const [results, setResults] = useState([]);
    const [results1, setResults1] = useState([]);
    const [results2, setResults2] = useState([]);
    const [results3, setResults3] = useState([]);
    const [results4, setResults4] = useState([]);
    const [results5, setResults5] = useState([]);
    // let data = results&&results.map(item => item.name)
    useEffect(() => {
      getSector({ type: "ADMINISTRATIVE SEGMENT" }, "select", setResults);
    }, []);
  
    useEffect(() => {
      getSector({ type: "ECONOMIC SEGMENT" }, "select", setResults1);
    }, []);
  
    useEffect(() => {
      getSector({ type: "Function Segment" }, "select", setResults2);
    }, []);
  
    useEffect(() => {
      getSector({ type: "PROGRAMME SEGMENT" }, "select", setResults3);
    }, []);
  
    useEffect(() => {
      getSector({ type: "FUND SEGMENT" }, "select", setResults4);
    }, []);
  
    useEffect(() => {
      getSector({ type: "GEO SEGMENT" }, "select", setResults5);
    }, []);
    

    let aheadData =
    results &&
    results?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));
  let aheadData1 =
    results1 &&
    results1?.map((i) => ({
      head: `${i.head}-${i.description}`,
      mapped_description: i.description,
      mapped_code: i.head,
      type: `${form.from}_${form.type}`,
      description: form.description,
      economic_code: form.economic_code
    }));
  let aheadData2 =
    results2 &&
    results2?.map((i) => ({
      head: `${i.head}-${i.description}`,
      mapped_description: i.description,
      mapped_code: i.head,
      type: `${form.from}_${form.type}`,
      description: form.description,
      economic_code: form.economic_code
    }));
  let aheadData3 =
    results3 &&
    results3?.map((i) => ({
      head: `${i.head}-${i.description}`,
      mapped_description: i.description,
      mapped_code: i.head,
      type: `${form.from}_${form.type}`,
      description: form.description,
      economic_code: form.economic_code
    }));
  let aheadData4 =
    results4 &&
    results4?.map((i) => ({
      head: `${i.head}-${i.description}`,
      mapped_description: i.description,
      mapped_code: i.head,
      type: `${form.from}_${form.type}`,
      description: form.description,
      economic_code: form.economic_code
    }));
  let aheadData5 =
    results5 &&
    results5?.map((i) => ({
      head: `${i.head}-${i.description}`,
      mapped_description: i.description,
      mapped_code: i.head,
      type: `${form.from}_${form.type}`,
      description: form.description,
      economic_code: form.economic_code
    }));
    const [multiSelections, setMultiSelections] = useState([]);
    const handleChange = ({target:{name,value}})=>{
      setForm((p)=>({...p,[name]:value}))
    }
    const handleSubmit = ()=>{
   _postApi("/post-budget-mapping?query_type=INSERT",multiSelections,
    (res)=>{
      if(res.success){
        alert("Success");
        toggle()
      }
    },(err)=>{
      console.log(err);
    }

   )
    }
  return (
    <>
      {/* {JSON.stringify(multiSelections)} */}
    <div className='row'>
   <div className='col-md-6'>
    <label>From</label>
      <Input
      type="select"
      name="from"
      value={form.from}
      onChange={handleChange}

    >
       <option>
      select
      </option>
      <option value={"eco"}>
       Economic Code
      </option>
      <option value={"geo"}>
       Geo Code
      </option>
      <option value={"function"}>
     Function Code
      </option>
      <option value={"fund"}>
      Fund Code
      </option>
      <option value={"program"}>
      Program Code
      </option>
    </Input>
        </div>

        <div className='col-md-6'>
        <label>By</label>
      <Input
      type="select"
      name="type"
      value={form.type}
      onChange={handleChange}
    >
      <option>
      select
      </option>
      <option value={`by_economic`}>
       Economic Code
      </option>
      <option value={`by_geo`}>
       Geo Code
      </option>
      <option value={`by_function`}>
     Function Code
      </option>
      <option value={`by_fund`}>
      Fund Code
      </option>
      <option value={`by_program`}>
      Program Code
      </option>
    </Input>
        </div>

      

   
{
  form.from !=""?
  <div className="col-md-6">
  <CustomTypeahead
    ref={repRef1}
    labelKey="head"
    label={`Select ${form.from} Code`}
    options={form.from==="eco"?aheadData1:form.from==="function"?aheadData2:form.from==="program"?aheadData3:form.from==="fund"?aheadData4:form.from==="geo"?aheadData5:[]}
    onChange={(s) => {
      if (s.length) {
        console.log(s);
        setForm((p) => ({
          ...p,
          description: s[0].mapped_description,
          economic_code: s[0].mapped_code,
        }));
      }
    }}
  />
</div>:null
}
{
  form.type===""?null:<div className='col-md-6 mt-4'>
<Typeahead
  id="basic-typeahead-multiple"
  labelKey="head"  // Ensure this matches the key in your options
  label={`Select ${form.from}`}
  multiple
  onChange={setMultiSelections}
  // onChange={(selected) => {
  //   const newSelection = {
  //     ...selected,
  //     type: `${form.from}_${form.type}`,
  //     description: form.description,
  //     economic_code: form.economic_code
  //   };
  //   setMultiSelections((prevSelections) => [...prevSelections, newSelection]);
  // }}

  options={
    form.type === "by_economic" ? aheadData1 :
    form.type === "by_function" ? aheadData2 :
    form.type === "by_program" ? aheadData3 :
    form.type === "by_fund" ? aheadData4 :
    form.type === "by_geo" ? aheadData5 :
    []
  }
  placeholder={`Choose several ${form.from}`}
  selected={multiSelections}
/>


  </div>
}

  

                   

                  

                  

    </div>
    <center><Button onClick={handleSubmit} color='primary' className='mt-3'>Submit</Button></center>
    </>
  )
}
