import { Grid, selectClasses } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, Col, Input, Label, Row, Spinner } from "reactstrap";
import { useDropzone } from "react-dropzone";
import { useQuery } from "redux/action/api";
import { fetchApi, postApi } from "redux/action/api_tbeams";
// import { apiURL } from "redux/action/api_tbeams";
import { formatNumber } from "redux/action/api";
import { useSelector } from "react-redux";
import Attachment from "./Attachment";
import { useToasts } from "react-toast-notifications";
import { getSector } from "helper";
import CapitalProject from "layouts/tables/CapitalProject";

export default function NewVirementRequest({}) {
  const query = useQuery();
  const mdaname = query.get("mda_name");
  const mdacode = query.get("mda_code");
  const reserveId = query.get("reserve_id");
  // const params = useParams()
  const mdaRef = useRef();
  const navigate = useNavigate();
  const [treasuryAccount, setTreasuryAccount] = useState([]);
  const [mdaAccount, setMdaAccount] = useState([]);
  const [budgetSummary, setBudgetSummary] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [source, setSource] = useState({});
  const [destination, setDestination] = useState({});

  const [economicCode, setEconomicCode] = useState([]);
  const [economicCode1, setEconomicCode1] = useState([]);
  const [childCode, setChildCode] = useState([]);
  const tresuryTypeahead = useRef();
  const economicTypeahead = useRef();
  const economicTypeahead1 = useRef();
  const mdaCodeTypeahead = useRef();
  const mdaTypeahead = useRef();
  const _id = query.get("id");
  const imageId = moment().format("YYYYMMDDhhmmss");
  const id = useParams()?.id;
  const [showForm, setShowForm] = useState(true);
  const [budgetBalance, setMdaBudgetBalance] = useState(0);
  const user = useSelector((e) => e.auth.user);

  const [form, setForm] = useState({
    mda_name: "",
    destMdaCode: "",
    mda_code1: "",
    mda_code: "",
    mda_budget_balance1: "",
    sourceEconomicCode: "",
    destEconomicCode: "",
    query_type: "insert",
    amount: "",
    reserveType: "",
    reserveStatus: "Pending",
    userId: user.username,
  });

  const [steps, setSteps] = useState("1");
  const { addToast } = useToasts();

  const getList = () => {
    getSector({ type: "mdas" }, "mdas", (d) => {
      setBudgetSummary(d);
    });
  };

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    postApi(`select_mda_bank_details`, {
      query_type: "select",
    }).then((data) => {
      if (data.result && data.result.length) {
        console.log(data.result);

        const treasuryAccount = data.result.filter(
          (item) => item.account_type === "treasury_source_account"
        );
        setTreasuryAccount(treasuryAccount);

        const mdaAccount = data.result.filter(
          (item) => item.account_type === "mda_source_account"
        );
        setMdaAccount(mdaAccount);
      }
    });
  }, []);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (e.target.type === "checkbox") {
      setForm((p) => ({ ...p, [name]: value }));
    } else {
      setForm((p) => ({ ...p, [name]: value }));
    }
  };

  const getList3 = (mda_code, economic_code) => {
    fetchApi(
      `get-budget-summary?query_type=fetch_budget&mda_code=${mda_code}&economic_code=${economic_code}`
    )
      .then((d) => {
        console.log(d);
        if (d.result && d.result.length) {
          const selectedItem = d.result[0];
          console.log("Budget", selectedItem);
          setDestination((prev) => ({
            ...prev,
            ...selectedItem,
            balance: parseFloat(
              parseFloat(selectedItem.approve_amount) -
                parseFloat(selectedItem.actual_amount || 0)
            ),
          }));
          // (prev) => ({ ...prev, ...selectedItem });
        }
      })
      .catch((err) => console.log(err));
  };

  const handleEconomicCode = (selected) => {
    if (selected?.length) {
      console.log("selected budget", selected);
      const selectedItem = selected[0];
      setForm((p) => ({
        ...p,
        destEconomicCode: selectedItem.economic_code,
        destEconomicDescription: selectedItem.economic_description,
      }));
      getList3(form.destMdaCode, form.destEconomicCode);
    } else {
      console.log("no item selected");
    }

    // console.log(selected);
  };

  const handleEconomicCode2 = (selected) => {
    if (selected?.length) {
      console.log("selected budget", selected);
      const selectedItem = selected[0];
      setForm((p) => ({
        ...p,
        source_economic_code: selectedItem.economic_code,
        sourceEconomicDescription: selectedItem.economic_description,
      }));
      getList3(form.destMdaCode, form.destEconomicCode);
    } else {
      console.log("no item selected");
    }

    // console.log(selected);
  };

  const getList2 = () => {
    fetchApi(`get-budget-summary?query_type=recurrent`)
      .then((d) => {
        console.log(d);
        if (d.result) {
          console.log(d);
          setEconomicCode(d.result);
        }
      })
      .catch((err) => console.log(err));
  };

  const getList21 = useCallback((mda_code) => {
    fetchApi(
      `get-budget-summary?query_type=select_economic_code&&mda_code=${mda_code}`
    )
      .then((d) => {
        console.log(d);
        if (d.result && d.result.length) {
          console.log(d);
          setEconomicCode1(d.result);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    getList2();
  }, []);

  const handleMdaCode = (selected) => {
    if (selected?.length) {
      // console.log(selected);
      const selectedItem = selected[0];
      setForm((p) => ({
        ...p,
        destMdaCode: selectedItem.head,
        destMdaDescription: selectedItem.description,
      }));

      getList2(selectedItem.mda_code);
    } else {
      console.log("no item selected");
    }
  };

  const handleMdaCode1 = (selected) => {
    if (selected?.length) {
      // console.log(selected);
      const selectedItem = selected[0];
      setForm((p) => ({
        ...p,
        sourceMdaCode: selectedItem.head,
        sourceMdaDescription: selectedItem.description,
      }));

      getList21(selectedItem.mda_code);
    } else {
      console.log("no item selected");
    }
  };

  // const handlefund = (selected) => {
  //   if (selected?.length) {
  //     // console.log(selected);
  //     const selectedItem = selected[0];
  //     setForm((p) => ({
  //       ...p,
  //       fund_code: selectedItem.mda_code,
  //       fund_description: selectedItem.mda_name,
  //     }));
  //   } else {
  //     console.log("no item selected");
  //   }
  // };
  // const handlefunc = (selected) => {
  //   if (selected?.length) {
  //     // console.log(selected);
  //     const selectedItem = selected[0];
  //     setForm((p) => ({
  //       ...p,
  //       functional_code: selectedItem.functional_code,
  //       function_description: selectedItem.fund_description,
  //     }));
  //   } else {
  //     console.log("no item selected");
  //   }
  // };

  // const handleprogram = (selected) => {
  //   if (selected?.length) {
  //     // console.log(selected);
  //     const selectedItem = selected[0];
  //     setForm((p) => ({
  //       ...p,
  //       program_code: selectedItem.program_code,
  //       program_description: selectedItem.program_description,
  //     }));
  //   } else {
  //     console.log("no item selected");
  //   }
  // };
  // const handlegeo = (selected) => {
  //   if (selected?.length) {
  //     // console.log(selected);
  //     const selectedItem = selected[0];
  //     setForm((p) => ({
  //       ...p,
  //       geo_code: selectedItem.geo_code,
  //       geo_description: selectedItem.geo_description,
  //     }));
  //   } else {
  //     console.log("no item selected");
  //   }
  // };

  const getReserveInfo = (id) => {
    postApi(`planning-reservation`, { query_type: "select_by_id", id })
      .then((resp) => {
        if (resp && resp.success) {
          // alert(JSON.stringify(resp.result[0]))
          const selected = resp.result[0];
          setForm({
            ...form,
            ...selected,
            // mda_name: selected.admin_description,
            // mda_code1: "",
            // mda_code: "",
            // mda_budget_balance1: "",
            // sourceEconomicCode: "",
            destMdaCode: selected.dest_admin_code,
            destEconomicCode: selected.dest_economic_code,
            destFunctionalCode: selected.dest_functional_code,
            destFundCode: selected.dest_fund_code,
            destProgramCode: selected.dest_program_code,
            destGeoCode: selected.dest_geo_code,

            destMdaDescription: selected.dest_admin_description,
            destEconomicDescription: selected.dest_economic_description,
            destFunctionalDescription: selected.dest_functional_description,
            destFundDescription: selected.dest_fund_description,
            destProgramDescription: selected.dest_program_description,
            destGeoDescription: selected.dest_geo_description,

            query_type: "insert",
          });
          setDestination({
            ...selected,
            admin_code: selected.dest_admin_code,
            economic_code: selected.dest_economic_code,
            functional_code: selected.dest_functional_code,
            fund_code: selected.dest_fund_code,
            program_code: selected.dest_program_code,
            geo_code: selected.dest_geo_code,
            admin_description: selected.dest_admin_desc,
            economic_description: selected.dest_economic_desc,
            functional_description: selected.dest_functional_desc,
            fund_description: selected.dest_fund_desc,
            program_description: selected.dest_program_desc,
            geo_description: selected.dest_geo_desc,
          });

          // destBalance
          // getList3(selected.dest_admin_code, selected.destMdaCode);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (reserveId) {
      getReserveInfo(reserveId);
    }
  }, [reserveId]);

  const handleApi = () => {
    setIsLoading(true);

    postApi(`planning-reservation`, {
      ...form,
      query_type: "insert",
      sourceMdaCode: form.sourceMdaCode,
      sourceMdaDescription: source.sourceMdaDescription,
      sourceEconomicCode: form.source_economic_code,
      sourceEconomicDescription: form.sourceEconomicDescription,
      sourceFundCode: source.fund_code,
      sourceFundDescription: source.fund_description,
      sourceFunctionalCode: source.functional_code,
      sourceFunctionalDescription: source.function_description,
      sourceProgramCode: source.program_code,
      sourceProgramDescription: source.program_description,
      sourceGeoCode: source.geo_code,
      sourceGeoDescription: source.geo_description,
      reserveType: steps === "2" ? "Capital" : "Recurrent",
    })
      .then((data) => {
        if (data.success) {
          console.log(data);
          //   alert("submit success");
          addToast("submit success", {
            appearance: "error",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
          setShowForm(false);
          navigate("/Virement");
        }
      })
      .catch((err) => {
        alert(err);
        addToast(err, {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchMdaBankDetails = useCallback(() => {
    const query_type = "select";
    // fetchApi(`select_mda_bank_details`, {
    //   query_type,
    // })
    //   .then((data) => {
    //     if (data && data.result && data.result.length) {
    //       console.log(data);
    //       setChildCode(data.result);
    //     }
    //   })

    //   .catch((err) => {
    //     console.log(err);
    //   });

    // console.log(childCode);
  });

  // useEffect(() => {
  //   fetchMdaBankDetails();
  // }, [fetchMdaBankDetails]);

  // useEffect(() => {
  //   postApi(`post_payment_schedule`, {
  //     query_type: "select_by_id",
  //     id,
  //   }).then((data) => {
  //     if (data && data.result && data.result.length) {
  //       setForm(data.result[0]);
  //       setMdaBudgetBalance(data.result[0].mda_budget_balance);
  //       mdaTypeahead.current?.setState({
  //         text: data.result[0].mda_account_name,
  //       });

  //       economicTypeahead.current?.setState({
  //         text: data.result[0].destEconomicCode,
  //       });

  //       economicTypeahead1.current?.setState({
  //         text: data.result[0].sourceEconomicCode,
  //       });
  //       tresuryTypeahead.current?.setState({
  //         text: data.result[0].treasury_account_name,
  //       });
  //       mdaCodeTypeahead.current?.setState({ text: data.result[0].mda_name });
  //     }
  //   });
  // }, [id]);

  //   const handleApi1 = (approval_no) => {
  //       const formData = new FormData();
  //     if (form.approved_amount && form.approved_amount !== "") {
  //       form.approval_no = approval_no;
  //       for (let i = 0; i < acceptedFiles.length; i++) {
  //         formData.append("files", acceptedFiles[i]);
  //       }

  //       if (!acceptedFiles.length) {
  //         setForm((p) => ({ ...p, approval: "not approved" }));
  //         formData.append("form", JSON.stringify(form));

  //         fetch(`${apiURL}/post_images`, {
  //           method: "POST",

  //           body: formData,
  //         })
  //           .then((response) => response.json())
  //           .then((result) => {
  //             console.log(result);
  //           })

  //           .catch((err) => {
  //             console.log(err);
  //           });
  //       } else {
  //         formData.append("form", JSON.stringify(form));

  //         fetch(`${apiURL}/post_images`, {
  //           method: "POST",

  //           body: formData,
  //         })
  //           .then((response) => response.json())
  //           .then((result) => {
  //             console.log(result);
  //           })

  //           .catch((err) => {
  //             console.log(err);
  //           });
  //       }
  //     } else {
  //       alert("Please enter a valid amount");
  //     }
  //   };

  // const getApprovalById = useCallback(() => {
  //   if (_id) {
  //     fetchApi(
  //       `get-approve-col?id=${_id}`,
  //       (res) => {
  //         if (res.success && res.result && res.result.length) {
  //           setForm({
  //             ...res.result[0],
  //             approved_amount: res.result[0].amount,
  //             query_type: "update_app",
  //           });
  //         }
  //       },
  //       (err) => {
  //         alert(`error occured ${err}`);
  //       }
  //     );
  //   }
  // }, [_id]);

  // useEffect(() => {
  //   getApprovalById();
  // }, [getApprovalById]);

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } =
    useDropzone({ accept: "image/*, .pdf, text/plain" });

  return (
    <DashboardLayout>
      <MDBox pt={2} pb={3} pl={2}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h5" color="white">
                  New Virement Request
                </MDTypography>
              </MDBox>
              {/* {JSON.stringify(economicCode)} */}
              {/* <Row></Row> */}
              <Col
                md={5}
                style={{
                  marginLeft: 9,
                }}
                className="mt-4"
              >
                <button
                  className="btn btn-success"
                  style={{ marginRight: "10px", marginLeft: "10px" }}
                  onClick={() => navigate("/Virement")}
                >
                  Click here to go back
                </button>
              </Col>
              <Row
                style={{
                  marginLeft: 8,
                  marginBottom: -20,
                  marginTop: 20,
                }}
              >
                <Col md={6}>
                  <Label>Reserve Type</Label>
                  <Input
                    type="select"
                    value={steps}
                    onChange={({ target: { value } }) => {
                      setSteps(value);
                    }}
                  >
                    <option>--Select--</option>
                    <option value="2">Capital</option>
                    <option value="1">Recurrent</option>
                  </Input>
                </Col>
              </Row>
              {/* {JSON.stringify(steps)} */}
              {/* <h1>economic</h1> */}
              {steps === "1" ? (
                <>
                  <Row className="mt-1 px-4 py-4">
                    <h4
                      style={{
                        color: "#59B15D",
                        marginTop: 30,
                      }}
                    >
                      Requesting MDA
                    </h4>

                    <Col md={3}>
                      <Label>Description</Label>
                      <Typeahead
                        size="sm"
                        id="20"
                        onChange={(selected) => handleMdaCode(selected)}
                        // onInputChange={(text) => console.log(text)}
                        options={budgetSummary.length ? budgetSummary : []}
                        labelKey={(option) =>
                          `${option.description}-${option.head}`
                        }
                        placeholder="Select---"
                        // defaultInputValue={mdaname ? mdaname : ""}
                        // ref={mdaRef}
                      />
                      {/* <Input value={destination.admin_description} disabled /> */}
                    </Col>
                    <Col md={3}>
                      <Label>Administration Code</Label>
                      <Input
                        type="text"
                        value={form.destMdaCode}
                        name="mda_code"
                        onChange={handleChange}
                        disabled
                      />
                    </Col>
                    <Col md={3}>
                      <Label>Select Economic Code</Label>
                      <Typeahead
                        size="sm"
                        id="20"
                        onChange={(selected) => handleEconomicCode(selected)}
                        // onInputChange={(text) => console.log(text)}
                        options={economicCode.length ? economicCode : []}
                        labelKey={(option) =>
                          `${option.economic_code} - ${option.economic_description}`
                        }
                        placeholder="Select---"
                        // ref={economicTypeahead}
                      />
                      {/* <Input value={destination.economic_code} disabled /> */}
                    </Col>
                    <Col md={3}>
                      <Label>code</Label>
                      <Input
                        type="text"
                        value={form.destEconomicCode}
                        // name="description"
                        // onChange={handleChange}
                        disabled
                      />
                    </Col>
                    {/* <Col md={3}>
                      <Label>Budget Balance</Label>
                      <Input
                        // type="number"
                        value={formatNumber(destination.vote_balance)}
                        name="amount"
                        // onChange={handleChange}
                        disabled
                      />
                    </Col> */}
                    <Col md={3}>
                      <Label>Approved Amounts</Label>
                      <Input
                        // type="number"
                        value={formatNumber(destination.approve_amount)}
                        name="amount"
                        // onChange={handleChange}
                        disabled
                      />
                    </Col>
                    <Col md={3}>
                      <Label>Balance</Label>
                      <Input
                        // type="number"
                        value={formatNumber(destination.balance)}
                        name="amount"
                        // onChange={handleChange}
                        disabled
                      />
                    </Col>
                    {steps === "2" && (
                      <div>
                        <p>
                          Fund Code: {destination.fund_code} Description:{" "}
                          {destination.fund_description}
                        </p>
                        <p>
                          Function Code: {destination.functional_code}{" "}
                          Description: {destination.functional_description}
                        </p>
                        <p>
                          Program Code: {destination.program_code} Description:{" "}
                          {destination.program_description}
                        </p>
                        <p>
                          Geo Code: {destination.geo_code} Description:{" "}
                          {destination.geo_description}
                        </p>
                      </div>
                    )}
                    {/* <p>{JSON.stringify(destination)}</p> */}

                    <h4
                      style={{
                        color: "#59B15D",
                        marginTop: 30,
                      }}
                    >
                      Please select source MDA
                    </h4>
                    <Col md={3}>
                      <Label>Select MDA</Label>
                      <Typeahead
                        size="sm"
                        id="20"
                        onChange={(selected) => handleMdaCode1(selected)}
                        onInputChange={(text) => console.log(text)}
                        options={budgetSummary.length ? budgetSummary : []}
                        labelKey={(option) =>
                          `${option.description}-${option.head}`
                        }
                        placeholder="Select---"
                        // defaultInputValue={mdaname ? mdaname : ""}
                        ref={mdaRef}
                      />
                    </Col>
                    <Col md={3}>
                      <Label>Administration Code</Label>
                      <Input
                        type="text"
                        value={form.sourceMdaCode}
                        name="mda_code1"
                        onChange={handleChange}
                        disabled
                      />
                    </Col>
                    <Col md={3}>
                      <Label>Select Economic Code</Label>
                      <Typeahead
                        size="sm"
                        id="20"
                        onChange={(selected) => {
                          // console.log(selected, "DDDD");
                          handleEconomicCode2(selected);
                        }}
                        // onInputChange={(text) => console.log(text)}
                        options={economicCode.length ? economicCode : []}
                        labelKey={(option) =>
                          `${option.economic_code} - ${option.economic_description}`
                        }
                        placeholder="Select---"
                        // ref={economicTypeahead1}
                      />
                    </Col>
                    <Col md={3}>
                      <Label>Description</Label>
                      <Input
                        type="text"
                        value={form.source_economic_code}
                        name="sourceEconomicDescription"
                        // onChange={handleChange}
                        disabled
                      />
                    </Col>
                    {/* 
                    <Col md={3}>
                      <Label>Select Fund Code</Label>
                      <Typeahead
                        size="sm"
                        id="20"
                        onChange={(selected) => handlefund(selected)}
                        // onInputChange={(text) => console.log(text)}
                        options={newData?.length ? newData : []}
                        labelKey={(option) =>
                          `${option.fund_code} - ${option.fund_description}`
                        }
                        placeholder="Select---"
                        // ref={economicTypeahead1}
                      />
                    </Col>
                    <Col md={3}>
                      <Label>Fund Description</Label>
                      <Input
                        type="text"
                        value={form.fund_description}
                        name="fund_description"
                        onChange={handleChange}
                        disabled
                      />
                    </Col>
                    <Col md={3}>
                      <Label>Select Function Code</Label>
                      <Typeahead
                        size="sm"
                        id="20"
                        onChange={(selected) => handlefunc(selected)}
                        // onInputChange={(text) => console.log(text)}
                        options={newData?.length ? newData : []}
                        labelKey={(option) =>
                          `${option.functional_code} - ${option.function_description}`
                        }
                        placeholder="Select---"
                        // ref={economicTypeahead1}
                      />
                    </Col>
                    <Col md={3}>
                      <Label>Functional Description</Label>
                      <Input
                        type="text"
                        value={form.function_description}
                        name="function_description"
                        onChange={handleChange}
                        disabled
                      />
                    </Col>
                    <Col md={3}>
                      <Label>Select Programme Code</Label>
                      <Typeahead
                        size="sm"
                        id="20"
                        onChange={(selected) => handleprogram(selected)}
                        // onInputChange={(text) => console.log(text)}
                        options={newData?.length ? newData : []}
                        labelKey={(option) =>
                          `${option.program_code} - ${option.program_description}`
                        }
                        placeholder="Select---"
                        // ref={economicTypeahead1}
                      />
                    </Col>
                    <Col md={3}>
                      <Label>Programme Description</Label>
                      <Input
                        type="text"
                        value={form.program_description}
                        name="program_description"
                        onChange={handleChange}
                        disabled
                      />
                    </Col>
                    <Col md={3}>
                      <Label>Select Geo Code</Label>
                      <Typeahead
                        size="sm"
                        id="20"
                        onChange={(selected) => handlegeo(selected)}
                        // onInputChange={(text) => console.log(text)}
                        options={newData?.length ? newData : []}
                        labelKey={(option) =>
                          `${option.geo_code} - ${option.geo_description}`
                        }
                        placeholder="Select---"
                        // ref={economicTypeahead1}
                      />
                    </Col>
                    <Col md={3}>
                      <Label>Geo Description</Label>
                      <Input
                        type="text"
                        value={form.geo_description}
                        name="sourceEconomicDescription"
                        onChange={handleChange}
                        disabled
                      />
                    </Col> */}

                    {/* <Col md={3}>
                      <Label>Vote Charge</Label>
                      <Input
                        type="text"
                        value={`${form.sourceMdaCode}/${form.sourceEconomicCode}`}
                        name="mda_code1"
                        onChange={handleChange}
                        disabled
                      />
                    </Col> */}

                    {/* <Col md={3}>
                      <Label>
                        Vote Balance({formatNumber(form.mda_budget_balance1)})
                      </Label>
                      <Input
                        type="text"
                        value={form.voteBalance}
                        name="mda_budget_balance1"
                        onChange={handleChange}
                        disabled
                      />
                    </Col> */}
                    <Col md={3}>
                      <Label>Amount ({formatNumber(form.amount)})</Label>
                      <Input
                        type="number"
                        value={form.amount}
                        name="amount"
                        onChange={handleChange}
                      />
                    </Col>

                    {steps === "2" && (
                      <div>
                        <p>
                          Fund Code: {source.fund_code} Description:{" "}
                          {source.fund_description}
                        </p>
                        <p>
                          Function Code: {source.functional_code} Description:{" "}
                          {source.functional_description}
                        </p>
                        <p>
                          Program Code: {source.program_code} Description:{" "}
                          {source.program_description}
                        </p>
                        <p>
                          Geo Code: {source.geo_code} Description:{" "}
                          {source.geo_description}
                        </p>
                      </div>
                    )}

                    {/* <Col md={12} className="py-4 ">
                    <Attachment
                      acceptedFiles={acceptedFiles}
                      getRootProps={getRootProps}
                      getInputProps={getInputProps}
                      isDragActive={isDragActive}
                    />
                  </Col> */}

                    {/* {JSON.stringify(source)} */}

                    <center className="mb-3">
                      <Button
                        color="success"
                        onClick={handleApi}
                        disabled={isLoading}
                      >
                        {isLoading ? <Spinner color="light" size="sm" /> : null}
                        Submit
                      </Button>
                    </center>
                  </Row>
                </>
              ) : (
                <CapitalProject generalForm={form} />
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
