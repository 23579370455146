import ReportExpByMDA from "./ReportExpByMDA";

export default function FunctionalByMDA() {
  const listdata = [
    { value: "total_func_by_mda", title: "Total Function by MDA" },
  ];

  return (
    <ReportExpByMDA
      type="total_func_by_mda"
      route="/reports"
      options={listdata}
      title="Total Function by MDA"
      chartType="get_head_func"
    />
  );
}
