import Dashboard from "layouts/dashboard";
// import Tables from "layouts/tables";

import Virement from "layouts/virement/virement";
// import Tables from "layouts/tables";
// import Billing from "layouts/billing";
// import RTL from "layouts/rtl";
// import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
// import SignUp from "layouts/authentication/sign-up";

// @mui icons
import Icon from "@mui/material/Icon";
import AdminSegment from "layouts/tables/AdministrativeSegment";
import EconomicSegment from "layouts/tables/EconomicSegemnt";
import FunctionalSegment from "layouts/tables/FunctionalSegment";
import ProgramSegment from "layouts/tables/ProgramSegment";
import FundSegment from "layouts/tables/FundSegment";
import GeoSegment from "layouts/tables/GeoSegment";
import SegmentForm from "layouts/tables/Segment";
import Report from "layouts/tables/Report";

import CreatUser from "layouts/tables/CreateUser";
import ManageUser from "layouts/tables/ManageUsers";
import SubSubOrg from "layouts/tables/SubSubOrg";
import SubSubSubOrg from "layouts/tables/SubSubSub";
import Revised from "layouts/tables/Revised";
import Supplemetary from "layouts/tables/Supplementary";
import Approved from "layouts/tables/Approved";
import HouseRecom from "layouts/tables/HouseREcom";
import EXCO from "layouts/tables/Exco";
import MOPB from "layouts/tables/Mopb";
import AdminMapping from "layouts/tables/AdminMapping";
import EcoMapping from "layouts/tables/EconomicMapping";
import FuncMap from "layouts/tables/FunctionalMapping";
import ProgramMaping from "layouts/tables/ProgramMaping";
import FundMaping from "layouts/tables/FundMap";
import GeoMaping from "layouts/tables/GeoMapping";
import Proposedtable from "layouts/tables/ProposedTable";
import AgricultureSector from "examples/M&E/AgricultureSector";
import CommerceSector from "examples/M&E/CommerceSector";
import EducationSector from "examples/M&E/EducationSector";
import EnvironmentNaturalResourcesSector from "examples/M&E/EnvironmentNaturalResourcesSector";
import HealthSector from "examples/M&E/HealthSector";
import HousingandTransportSector from "examples/M&E/HousingandTransportSector";
import KanoStateMandE from "examples/M&E/KanoStateMandE";
import SampleMonitoring from "examples/M&E/SampleMonitoring";
import SectorBudgetCeiling from "layouts/tables/SectorBudgetCeiling";
import MDABudgetCelling from "layouts/tables/MDABudgetCelling";
import OtherSetup from "examples/admin/OtherSetup";
import BudgetView from "layouts/tables/BudgetView";
import ProfileNew from "examples/profile/Profile";
import ApprovedBudget from "layouts/tables/ApprovedBudget";

import EcoReport from "layouts/tables/reports/EcoReport";
import TotalRevenuebyEconomic from "layouts/tables/reports/TotalRevenuebyEconomic";
import ProgramReport from "layouts/tables/reports/ProgramReport";
import FundReport from "layouts/tables/reports/FundReport";
import GeoReport from "layouts/tables/reports/GeoReport";
import AdminQuaterly from "layouts/tables/reports/AdminQuertly";
import EcoQuaterly from "layouts/tables/reports/EcoQuarterly";
import FuncQuaterly from "layouts/tables/reports/FuncQuarterly";
import ProgramQuaterly from "layouts/tables/reports/ProgramQuart";
import FundQuaterly from "layouts/tables/reports/FundQuarterly";
import GeoQuaterly from "layouts/tables/reports/GeoQuarter";
import BudgetProposal from "layouts/tables/BudgetProposal";
import ReportByLoc from "layouts/tables/reports/ReportByLoc";
import ReportByFund from "layouts/tables/reports/ReportByFund";
import ReportByFunc from "layouts/tables/reports/ReportByFunc";
import BudgetOverview from "layouts/tables/reports/BudgetOverview";
import ReleaseCollection from "layouts/tables/ReleaseCollection";
import Tables from "layouts/tables";
import NewReleaseCollection from "layouts/tables/NewReleaseCollection";
import ReleaseCancellation from "layouts/tables/ReleaseCancellation";
import EditNewReleaseCollection from "layouts/tables/EditNewReleaseCollection";
import CcCopies from "layouts/tables/CcCopies";
import PlanningReserve from "layouts/tables/PlanningReserve";
import NewPlanningReserve from "layouts/tables/NewPlanningReserve";
import InternalAudit from "layouts/tables/InternalAudit";
import ApprovalReview from "layouts/tables/Aoroval_Reviewal";
import ReleaseUnclear from "layouts/tables/ReleaseUnclear";
import ViewRelease from "layouts/tables/ViewRelease";
import ReleaseReport from "layouts/tables/ReleaseReport";
import ReleaseView from "layouts/tables/ReleaseView";
import ReportByEco from "layouts/tables/reports/ReportByEco";
import PerformanceView from "components/Tree/PerformanceView";
import NewVirementRequest from "layouts/virement/NewVirement";
import PublicationReportsNew from "layouts/tables/reports/PublicationReportsNew";
import VirementTable from "layouts/virement/VirementTable";
import ContingencyReserve from "layouts/virement/ContingencyReserve";
import AIE from "layouts/tables/AIE";
import ExpByDepartment from "layouts/tables/reports/ExpByDepartment";
import BudgetBreakdown from "layouts/tables/BudgetBreakdown";
import RevenueReportsDash from "layouts/tables/reports/RevenueReportsDash";
import ExcelUpload from "examples/admin/UploadExcel";
import ContengencyTable from "layouts/tables/ConetengencyTable";
import AppropriationLaw from "layouts/tables/reports/AppropriationLaw";
import CapitalProjects from "layouts/tables/reports/CapitalProject";
import SubBudgetCeiling from "layouts/tables/SubBudgetCeiling";
import MDACellingTable from "layouts/tables/CeillingTable";
import ExpensesByDepartment from "layouts/tables/reports/ExpensesByDepartment";
import RevenueByMDA from "layouts/tables/reports/RevenueByMDA";
import FunctionalByMDA from "layouts/tables/reports/FunctionalByMDA";
// const user = useSelector((e) => e.auth.user);
// alert(JSON.stringify(user))
const routes = [
  {
    type: "collapse",
    key: "dashboard",
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "ccCopies",
    name: "CC_Copies",
    key: "CC_Copies",
    route: "/cccopies",
    con: <Icon fontSize="small">bar_chart</Icon>,
    component: <CcCopies />,
  },
  {
    type: "collapse",
    key: "profile",
    route: "/profile",
    component: <ProfileNew />,
  },
  {
    type: "collapse",
    name: "Account Chart",
    key: "chart",
    icon: <Icon fontSize="small">bar_chart</Icon>,
    route: "/chart",
    component: <Tables />,
  },
  {
    type: "Virement",
    name: "Virement",
    key: "Virement",
    icon: <Icon fontSize="small">New Virement</Icon>,
    route: "/Virement",
    component: <Virement />,
  },
  {
    type: "Virement",
    name: "Review-Virement",
    key: "Review-Virement",
    icon: <Icon fontSize="small">Review Virement</Icon>,
    route: "/Review-Virement",
    component: <PlanningReserve />,
  },
  {
    type: "Virement",
    name: "Approve-Virement",
    key: "Approve-Virement",
    icon: <Icon fontSize="small">Approve Virement</Icon>,
    route: "/Approve-Virement",
    component: <PlanningReserve />,
  },
  // {
  //   type: "Virement",
  //   name: "Planning Reserve",
  //   key: "PlanningReserve",
  //   icon: <Icon fontSize="small">P</Icon>,
  //   route: "/Virement/planning-reserve",
  //   component: <PlanningReserveForm />,
  // },
  {
    type: "BudgetAmend",
    name: "Contingency Reserve Recurrent",
    route: "contingency-table-recurerent",
    key: "contingency-table-recurrent",
    icon: <Icon fontSize="small">C</Icon>,
    component: <ContengencyTable />,
  },
  {
    type: "BudgetAmend",
    name: "Contingency Reserve Capital",
    route: "contingency-table-capital",
    key: "contingency-table-capital",
    icon: <Icon fontSize="small">C</Icon>,
    component: <ContengencyTable />,
  },
  {
    type: "BudgetAmend",
    name: "AIE",
    route: "contingency-table-aie",
    key: "contingency-table-aie",
    icon: <Icon fontSize="small">C</Icon>,
    component: <ContengencyTable />,
  },
  {
    // type: "BudgetAmend",
    // name: "Contingency Reserve Recurrent",
    // key: "contingency-reserve-recurrent",
    // icon: <Icon fontSize="small">C</Icon>,
    route: "contingency-reserve-recurrent",
    component: <ContingencyReserve />,
  },
  {
    // type: "BudgetAmend",
    // name: "Contingency Reserve Capital",
    // key: "contingency-reserve-capital",
    // icon: <Icon fontSize="small">C</Icon>,
    route: "contingency-reserve-capital",
    component: <ContingencyReserve />,
  },
  {
    type: "BudgetAmend",
    name: "Supplementary",
    key: "Supplementary",
    icon: <Icon fontSize="small"> star </Icon>,
    route: "/Supplementary",
    component: <Supplemetary />,
  },

  {
    // type: "BudgetAmend",
    // name: "AIE",
    // key: "aie",
    // icon: <Icon fontSize="small">event</Icon>,
    route: "/contingency-reserve-aie",
    component: <ContingencyReserve />,
  },
  {
    type: "collapse",
    name: "Administrative Segment",
    key: "admin",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/admin",
    component: <AdminSegment />,
  },
  {
    type: "collapse",
    name: "Economic Segment",
    key: "economic",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/economic",
    component: <EconomicSegment />,
  },
  {
    type: "collapse",
    name: "Geo Segment",
    key: "geo",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/geo",
    component: <GeoSegment />,
  },
  {
    type: "collapse",
    name: "Programme Segment",
    key: "program",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/program",
    component: <ProgramSegment />,
  },
  {
    type: "collapse",
    name: "Functional Segment",
    key: "functional",
    icon: <Icon fontSize="small">money</Icon>,
    route: "/functional",
    component: <FunctionalSegment />,
  },

  {
    type: "collapse",
    name: "Fund Segment",
    key: "fund",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/fund",
    component: <FundSegment />,
  },

  // {
  //   type: "budget",
  //   name: "Budget Mapping",
  //   key: "budget",
  //   icon: <Icon fontSize="small">map</Icon>,
  //   route: "/budget",
  //   component: <Budget />,
  // },
  // {
  //   type: "budget",
  //   name: "Budget Ceilling",
  //   key: "budget-celling",
  //   icon: <Icon fontSize="small">map</Icon>,
  //   route: "/budget-celling",
  //   component: <BudgetCeiling />,
  // },

  {
    type: "collection",
    name: "Release Processing",
    key: "release-collection",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/release-collection",
    component: <ReleaseCollection />,
  },
  {
    type: "collection",
    name: "Internal Control",
    key: "internal-audit",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/internal-audit",
    component: <InternalAudit />,
  },
  {
    type: "collection",
    name: "Approval Review",
    key: "approval-review",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/approval-review",
    component: <ApprovalReview />,
  },

  {
    type: "collection",
    key: "view-release",
    route: "/view-release",
    component: <ViewRelease />,
  },

  {
    type: "collection",
    name: "Release Cancellation",
    key: "cancellation-of-release",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/cancellation-of-release",
    component: <ReleaseCancellation />,
  },

  {
    type: "collection",
    name: "Unclear Release",
    key: "unclear-of-release",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/unclear-of-release",
    component: <ReleaseUnclear />,
  },

  {
    type: "planning",
    name: "Planning Reserve",
    key: "Supplimentary",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/planning-reservation",
    component: <PlanningReserve />,
  },

  {
    type: "budget",
    name: "Main Org Budget Ceilling",
    key: "sector-budget-celling",
    icon: <Icon fontSize="small">category</Icon>,
    route: "/sector-budget-celling",
    component: <SectorBudgetCeiling />,
  },

  {
    type: "budget",
    name: "Sub MainOrg Budget Ceilling",
    key: "mda-budget-celling",
    icon: <Icon fontSize="small">map</Icon>,
    route: "/mda-budget-celling",
    component: <MDABudgetCelling />,
  },

  {
    type: "budget",
    name: "MDA Ceilling",
    key: "mda-celling",
    icon: <Icon fontSize="small">category</Icon>,
    route: "/mda-celling",
    component: <SubBudgetCeiling />,
  },

  {
    type: "budget",
    name: "Ceilling Report",
    key: "mda-report-celling",
    icon: <Icon fontSize="small">map</Icon>,
    route: "/mda-report-celling",
    component: <MDACellingTable />,
  },

  {
    key: "budget2",
    route: "/budget-form",
    component: <BudgetProposal />,
  },
  {
    key: "budget2",
    route: "/edit-new-realise-collection",
    component: <EditNewReleaseCollection />,
  },
  {
    key: "budget2",
    route: "/new-plnning-reservation",
    component: <NewVirementRequest />,
  },
  {
    key: "budget2",
    route: "/new-realise-collection",
    component: <NewReleaseCollection />,
  },
  {
    type: "report",
    name: "Approved budget",
    key: "approved-budget",
    icon: <Icon fontSize="small">work</Icon>,
    route: "/approved-budget",
    component: <ApprovedBudget />,
  },
  {
    type: "report",
    name: "Budget Report Tracker",
    key: "budgets-tracker",
    icon: <Icon fontSize="small">directions</Icon>,
    route: "/budgets-tracker",
    component: <BudgetView />,
  },

  {
    type: "report",
    name: "Publication Reports",
    key: "publication-reports",
    icon: <Icon fontSize="small">book</Icon>,
    route: "/publication-reports",
    component: <PublicationReportsNew />,
  },

  {
    type: "report",
    name: "Appropriation Law",
    key: "appropriation-law",
    icon: <Icon fontSize="small">book</Icon>,
    route: "/appropriation-law",
    component: <AppropriationLaw />,
  },

  {
    type: "report",
    name: "Budget Overview",
    key: "budget-overview",
    icon: <Icon fontSize="small">loyalty</Icon>,
    route: "/budget-overview",
    component: <BudgetOverview />,
  },

  {
    type: "report",
    name: "Summary Revenue Reports",
    key: "revenue-reports",
    droptype: "revenue-reports",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/revenue-reports",
    component: <RevenueReportsDash />,
  },

  // {
  //   type: "report",
  //   name: "Receipt Revenue by Fund",
  //   key: "receipt-revenue-by-fund",
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   route: "/receipt-revenue-by-fund",
  //   component: <ProgramQuaterly />,
  // },
  // {
  //   type: "report",
  //   name: "Release Report",
  //   key: "release_report",
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   route: "/release_report",
  //   component: <Report />,
  // },
  {
    type: "report",
    name: "Revenue Reports by Admin SS",
    key: "total-revenue-by-admin",
    droptype: "revenue-reports",
    icon: <Icon fontSize="small">account_balance</Icon>,
    route: "/total-revenue-by-admin",
    component: <AdminQuaterly />,
  },

  {
    type: "report",
    name: "Revenue Reports by Admin SSS",
    key: "total-revenue-by-department",
    droptype: "revenue-reports",
    icon: <Icon fontSize="small">account_balance</Icon>,
    route: "/total-revenue-by-department",
    component: <ExpensesByDepartment />,
  },
  {
    type: "report",
    name: "Total Revenue by Economic",
    droptype: "revenue-reports",
    key: "total-revenue-by-economic",
    icon: <Icon fontSize="small">summarize</Icon>,
    route: "/total-revenue-by-economic",
    component: <TotalRevenuebyEconomic />,
  },
  {
    type: "report",
    name: "Revenue by MDA",
    droptype: "revenue-reports",
    key: "revenue-by-mda",
    icon: <Icon fontSize="small">summarize</Icon>,
    route: "/revenue-by-mda",
    component: <RevenueByMDA />,
  },
  {
    type: "report",
    name: "Capital Receipts by item",
    droptype: "revenue-reports",
    key: "capital-recepts-by-item",
    icon: <Icon fontSize="small">account_balance_wallet</Icon>,
    route: "/capital-recepts-by-item",
    component: <FuncQuaterly />,
  },

  {
    type: "report",
    name: "Total Revenue by Fund",
    key: "total-revenue-by-fund",
    droptype: "revenue-reports",
    icon: <Icon fontSize="small">location_on</Icon>,
    route: "/total-revenue-by-fund",
    component: <ProgramReport />,
  },

  // {
  //   type: "report",
  //   name: "Capital Receipts by Fund",
  //   key: "capital-receipts-by-fund",
  //   droptype: "revenue-reports",
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   route: "/capital-receipts-by-fund",
  //   component: <FundReport />,
  // },
  // {
  //   type: "report",
  //   name: "Capital Expenditure",
  //   droptype: "expenditure",
  //   key: "capital-expenditure",
  //   icon: <Icon fontSize="small">code</Icon>,
  //   route: "/capital-expenditure",
  //   component: <EcoQuaterly />,
  // },
  {
    type: "report",
    name: "Summary Expenditure Reports",
    droptype: "expenditure",
    key: "exp-by-mda",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/exp-by-mda",
    component: <Report />,
  },
  {
    type: "report",
    name: "Expenditure by Admin SS",
    droptype: "expenditure",
    key: "expenditure-reports-by-admin",
    icon: <Icon fontSize="small">monetization_on</Icon>,
    route: "/expenditure-reports-by-admin",
    component: <EcoReport />,
  },

  {
    type: "report",
    name: "Expenditure by Admin SSS",
    droptype: "expenditure",
    key: "expenditure-reports-by-department",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/expenditure-reports-by-department",
    component: <ExpByDepartment />,
  },

  {
    type: "report",
    name: "Expenditure By MDA",
    droptype: "expenditure",
    key: "exp-by-mda-report",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/exp-by-mda-report",
    component: <GeoReport />,
  },
  {
    type: "report",
    name: "Function Report By MDA",
    droptype: "expenditure",
    key: "function-by-mda-report",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/function-by-mda-report",
    component: <FunctionalByMDA />,
  },
  {
    type: "report",
    name: "Exp by Programme",
    droptype: "expenditure",
    key: "exp_by_prog",
    icon: <Icon fontSize="small">code</Icon>,
    route: "/exp_by_prog",
    component: <ProgramQuaterly />,
  },
  {
    type: "report",
    name: "Exp by Location",
    droptype: "expenditure",
    key: "exp_by_loc",
    icon: <Icon fontSize="small">location_on</Icon>,
    route: "/exp_by_loc",
    component: <ReportByLoc />,
  },
  {
    type: "report",
    name: "Exp by Fund",
    droptype: "expenditure",
    key: "exp_by_fund",
    icon: <Icon fontSize="small">account_balance_wallet</Icon>,
    route: "/exp_by_fund",
    component: <ReportByFund />,
  },
  {
    type: "report",
    name: "Exp by Economic",
    droptype: "expenditure",
    key: "exp_by_eco",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/exp_by_eco",
    component: <ReportByEco />,
  },
  {
    type: "report",
    name: "Exp by Function",
    key: "exp_by_func",
    droptype: "expenditure",
    icon: <Icon fontSize="small">functions</Icon>,
    route: "/exp_by_func",
    component: <ReportByFunc />,
  },

  {
    type: "report",
    name: "Expenditure by Project",
    key: "exp_by_project",
    droptype: "expenditure",
    icon: <Icon fontSize="small">functions</Icon>,
    route: "/exp_by_project",
    component: <CapitalProjects />,
  },

  // {
  //   type: "report",
  //   name: "Personnel Exp by Fund",
  //   key: "geo-report",
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   route: "/geo-report",
  //   component: <GeoReport />,
  // },
  // {
  //   type: "report",
  //   name: "Personnel Exp by Fund",
  //   key: "geo-report",
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   route: "/geo-report",
  //   component: <GeoReport />,
  // },

  {
    // type: "collapse",
    // name: "Form",
    key: "form",
    // icon: <Icon fontSize="small">table_view</Icon>,
    route: "/form/:id",
    component: <SegmentForm />,
  },

  {
    key: "create-user",
    // icon: <Icon fontSize="small">table_view</Icon>,
    route: "/create-user",
    component: <CreatUser />,
  },
  {
    // type: "collapse",
    // name: "Form",
    key: "form",
    // icon: <Icon fontSize="small">table_view</Icon>,
    route: "/form",
    component: <SegmentForm />,
  },
  {
    // type: "collapse",
    // name: "Form",
    key: "SignIn",
    // icon: <Icon fontSize="small">table_view</Icon>,
    route: "/SignIn",
    component: <SignIn />,
  },
  {
    key: "new-release-collection",
    route: "/edit-new-release-collection",
    component: <EditNewReleaseCollection />,
  },
  // {
  //   // type: "collapse",
  //   // name: "Form",
  //   key: "sign-up",
  //   // icon: <Icon fontSize="small">table_view</Icon>,
  //   route: "/sign-up",
  //   component: <SignUp />,
  // },
  {
    type: "sub",
    name: "Sub Table",
    key: "sub-org",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/sub-org",
    component: <Profile />,
  },
  {
    type: "sub",
    name: "Sub Sub Table",
    key: "sub-sub-org",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/sub-sub-org",
    component: <SubSubOrg />,
  },
  {
    type: "sub",
    name: "Sub Sub Sub Table",
    key: "sub-sub-sub-org",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/sub-sub-sub-org",
    component: <SubSubSubOrg />,
  },
  {
    type: "budget_type",
    name: "Proposed Budget",
    key: "budget2",
    icon: <Icon fontSize="small">reorder</Icon>,
    route: "/budget2",
    component: <Proposedtable />,
  },

  {
    type: "budget_type",
    name: "MOPB Recommendation",
    key: "mopb",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/mopb",
    component: <MOPB />,
  },
  {
    type: "budget_type",
    name: "Exco Recommendation",
    key: "Exco",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/Exco",
    component: <EXCO />,
  },
  {
    type: "budget_type",
    name: "House Recommendation",
    key: "House",
    icon: <Icon fontSize="small"> house </Icon>,
    route: "/House",
    component: <HouseRecom />,
  },
  {
    type: "budget_type",
    name: "Approve Recommendation",
    key: "Approve",
    icon: <Icon fontSize="small">checkbox-marked </Icon>,
    route: "/Approve",
    component: <Approved />,
  },
  {
    type: "budget_type",
    name: "Budget Breakdown",
    key: "BudgetBreakdown",
    icon: <Icon fontSize="small">reorder</Icon>,
    route: "/BudgetBreakdown",
    component: <BudgetBreakdown />,
  },
  {
    // type: "report",
    // name: "Release Report",
    key: "release_view",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/release_view",
    component: <ReleaseView />,
  },

  {
    type: "budget_type",
    name: "Revised Estimate",
    key: "Revised",
    icon: <Icon fontSize="small">event</Icon>,
    route: "/Revised",
    component: <Revised />,
  },

  // {
  //   type: "mapping",
  //   name: "MDA Mapping",
  //   key: "admin-maping",
  //   icon: <Icon fontSize="small">receipt_long</Icon>,
  //   route: "/admin-maping",
  //   component: <AdminMapping />,
  // },
  {
    type: "mapping",
    name: "Economic Segment Mapping",
    key: "economic-mapping",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/economic-mapping",
    component: <EcoMapping />,
  },
  {
    type: "mapping",
    name: "Functional Segment Mapping",
    key: "functional-maping",
    icon: <Icon fontSize="small">money</Icon>,
    route: "/functional-maping",
    component: <FuncMap />,
  },
  {
    type: "mapping",
    name: "Programme Segment Mapping",
    key: "program-maping",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/program-maping",
    component: <ProgramMaping />,
  },
  {
    type: "mapping",
    name: "Fund Segment Mapping",
    key: "fund-mapping",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/fund-mapping",
    component: <FundMaping />,
  },
  {
    type: "mapping",
    name: "Geo Segment Mapping",
    key: "geo-maping",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/geo-maping",
    component: <GeoMaping />,
  },

  {
    type: "m&e",
    name: "Agriculture Sector",
    key: "agric-sector",
    icon: <Icon fontSize="small">agriculture</Icon>,
    route: "/agric-sector",
    component: <AgricultureSector />,
  },

  {
    type: "m&e",
    name: "Commerce Sector",
    key: "commerce-sector",
    icon: <Icon fontSize="small">shopping_cart</Icon>,
    route: "/commerce-sector",
    component: <CommerceSector />,
  },

  {
    type: "m&e",
    name: "Education Sector",
    key: "education-sector",
    icon: <Icon fontSize="small">school</Icon>,
    route: "/education-sector",
    component: <EducationSector />,
  },

  {
    type: "m&e",
    name: "Natural Resource Sector",
    key: "natural-resource-sector",
    icon: <Icon fontSize="small">nature</Icon>,
    route: "/natural-resource-sector",
    component: <EnvironmentNaturalResourcesSector />,
  },

  {
    type: "m&e",
    name: "Health Sector",
    key: "health-sector",
    icon: <Icon fontSize="small">local_hospital</Icon>,
    route: "/health-sector",
    component: <HealthSector />,
  },

  {
    type: "m&e",
    name: "Housing and Transport Sector",
    key: "housing-transport-sector",
    icon: <Icon fontSize="small">house</Icon>,
    route: "/housing-transport-sector",
    component: <HousingandTransportSector />,
  },
  {
    type: "m&e",
    name: "Kano State M&E",
    key: "state-me",
    icon: <Icon fontSize="small">location_city</Icon>,
    route: "/state-me",
    component: <KanoStateMandE />,
  },
  // {
  //   type: "m&e",
  //   name: "Sample Monitoring",
  //   key: "sample-monitor",
  //   icon: <Icon fontSize="small">insights</Icon>,
  //   route: "/sample-monitor",
  //   component: <SampleMonitoring />,
  // },

  {
    type: "new_admin",
    name: "User Management",
    key: "manage-user",
    icon: <Icon fontSize="small">settings</Icon>,
    route: "/manage-user",
    component: <ManageUser />,
  },
  {
    type: "new_admin",
    name: "Other Setup",
    key: "other-set-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/other-set-up",
    component: <OtherSetup />,
  },

  {
    type: "new_admin",
    name: "Upload Budget",
    key: "udget-upload",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/budget-upload",
    component: <ExcelUpload />,
  },
];

export default routes;
