import React from "react";
import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { capitalizeWords } from "redux/action/api";

// Create styles using StyleSheet
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    padding: 10,
    fontFamily: "Helvetica",
    fontSize: 5,
  },
  heading: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 5,
    marginBottom: 5,
  },
  tableContainer: {
    flexDirection: "row",
    marginTop: 0,
    borderWidth: 0.5,
    borderColor: "black",
    borderStyle: "solid",
    fontSize: 5,
    marginRight: 5,
  },
  tableHeader: {
    backgroundColor: "#f0f0f0",
    borderWidth: 0.5,
    borderColor: "black",
    borderStyle: "solid",
    padding: 5,
    textAlign: "center",
    fontWeight: "bold",
    flex: 5,
    fontSize: 5,
  },
  tableData: {
    borderWidth: 0.5,
    borderColor: "black",
    borderStyle: "solid",
    padding: 5,
    textAlign: "center",
    flex: 5, // Adjust the flex value to change column width
    fontSize: 5,
  },
  section: {
    width: 200,
    "@media max-width: 400": {
      width: 500,
    },
    "@media orientation: landscape": {
      width: 1000,
    },
  },
});

// Component to generate PDF
// eslint-disable-next-line react/prop-types
const MyPDF = ({ data = [] }) => {
  return (
    <Document>
      <Page
        size="A4"
        style={[styles.page, styles.section]}
        orientation="landscape"
      >
        <View style={styles.section}>
          <Text style={styles.heading}>Budget Proposal</Text>

          {/* Bordered Black Table */}
          <View style={styles.tableContainer}>
            {Object.keys(data[0]).map((key) => {
              // Exclude 'id' key and filter out empty strings
              if (
                key !== "id" &&
                key !== "status" &&
                key !== "start_date" &&
                key !== "end_date" &&
                data.every((row) => row[key] !== "")
              ) {
                return (
                  <Text key={key} style={styles.tableHeader}>
                    {capitalizeWords(key)}
                  </Text>
                );
              }
              return null;
            })}
          </View>
          {data.map((row, index) => (
            <View key={index} style={styles.tableContainer}>
              {Object.entries(row).map(([key, value]) => {
                // Exclude 'id' key and filter out empty strings
                if (
                  key !== "id" &&
                  key !== "status" &&
                  key !== "start_date" &&
                  key !== "end_date" &&
                  value !== "" &&
                  value !== null &&
                  value !== undefined
                ) {
                  return (
                    <Text key={key} style={styles.tableData}>
                      {key === "budget_amount" || key === "actual_amount"
                        ? parseFloat(row[key]).toLocaleString()
                        : value}
                    </Text>
                  );
                }
                return null;
              })}
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

export default MyPDF;
