import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import BarCahrt from "./components/OrdersOverview/BarChart";
import { _fetchApi } from "redux/action/api";
import { useEffect, useState } from "react";
import { formatNumber } from "redux/action/api";
import NewDashboard from "layouts/DashboardNew";

function Dashboard() {
  const { sales, tasks } = reportsLineChartData;
  const [mda, setMda] = useState([]);
  const [budgeted, setBudgeted] = useState([]);
  const [expense, setExpense] = useState([]);
  const [revenue, setRevenue] = useState([]);
  const [revenueTop, setRevenueTop] = useState([]);
  const [expenseTop, setExpenseTop] = useState([]);
  const [budgetActual, setBudgetActual] = useState([]);

  const getAllData = (query_type, cb) => {
    _fetchApi(
      `/dashboard-budget?query_type=${query_type}`,
      (data) => {
        cb(data.results);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  useEffect(() => {
    getAllData("mda", setMda);
    getAllData("budget_amount", setBudgeted);
    getAllData("expenses", setExpense);
    getAllData("revenue", setRevenue);
    getAllData("revenue-top", setRevenueTop);
    getAllData("expenses-top", setExpenseTop);
    getAllData("budget-actual", setBudgetActual);
  }, []);

  let top5Revenue = {
    labels: revenueTop?.map((item) => item.description.substr(0, 5)),
    datasets: {
      label: "Revenue",
      data: revenueTop?.map((item) => item.amount),
    },
  };

  let top5Expense = {
    labels: expenseTop?.map((item) => item.description.substr(0, 5)),
    datasets: {
      label: "Expense",
      data: expenseTop?.map((item) => item.amount),
    },
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* {JSON.stringify(expense)} */}
      <NewDashboard />
      {/* <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                // color="dark"
                icon="weekend"
                title="Count Mda's"
                count={mda[0]?.total}
                // percentage={{
                //   color: "success",
                //   amount: "+55%",
                //   label: "than lask week",
                // }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="leaderboard"
                title="Approved budget"
                count={`₦${parseFloat(
                  budgeted[0]?.total || 0
                ).toLocaleString()}`}
                // percentage={{
                //   color: "success",
                //   amount: "+3%",
                //   label: "than last month",
                // }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                // color="primary"
                icon="store"
                title="Revenue"
                count={`₦${parseFloat(
                  revenue[0]?.total || 0
                ).toLocaleString()}`}
                // percentage={{
                //   color: "success",
                //   amount: "+1%",
                //   label: "than yesterday",
                // }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                // color="primary"
                icon="person_add"
                title="Expenditure"
                count={`₦${parseFloat(expense[0]?.total || 0).toLocaleString()}`}
                // percentage={{
                //   color: "success",
                //   amount: "",
                //   label: "Just updated",
                // }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={6}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="Revenue"
                  // description="Last Campaign Performance"
                  // date="just updated"
                  chart={top5Revenue}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="Expenditure"
                  // description={
                  //   <>
                  //     (<strong>+15%</strong>) increase in today sales.
                  //   </>
                  // }
                  // date="updated 4 min ago"
                  chart={top5Expense}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="Revenue"
                  // description="Last Campaign Performance"
                  // date="just updated"
                  chart={top5Revenue}
                />
              </MDBox>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <MDBox mb={3}>
                <BarCahrt data={budgetActual} />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={8}>
              {/* <Projects /> */}
            {/* </Grid>
            <Grid item xs={12} md={6} lg={4}>
              {/* <OrdersOverview /> */}
            {/* </Grid>
          </Grid>
        </MDBox> 
      </MDBox> */} 
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
