import ReportTotalEco from "./ReportTotalEco";

const listdata = [
  { value: "total_revenue_by_economic", title: "Total Revenue by Economic" },
  // { value: "mda_revenue_by_eco", title: "MDA Revenue by Economic" },
];
function TotalRevenuebyEconomic() {
  return (
    <ReportTotalEco
      type="total_revenue_by_economic"
      route="/reports"
      options={listdata}
      title="Total Revenue by Economic"
    />
  );
}

export default TotalRevenuebyEconomic;
