/* eslint-disable react/no-unescaped-entities */
import React, { useCallback, useEffect, useRef, useState } from "react";

import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import { getSector } from "helper";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import { Card, Grid } from "@mui/material";
import SearchBar from "components/SearchBar";
import CustomTable from "components/CustomTable";
import { _postApi } from "redux/action/api";
import Footer from "examples/Footer";
import MDTypography from "components/MDTypography";
import { _fetchApi } from "redux/action/api";
import Scrollbar from "components/Tree/Scrollbar";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Label,
  Badge,
  ButtonGroup,
  Col,
  Alert,
} from "reactstrap";
import { formatNumber } from "redux/action/api";
import CustomTypeahead from "components/CustomTypeahead";
import { today } from "helper";
import { year } from "helper";
import { useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { useYear } from "useYearList";
import BudgetForm from "./BudgetForm";
import { BiTrash } from "react-icons/bi";
import { Edit } from "@mui/icons-material";
import ProposalTable from "./ProposalTable";
import { getNextYear } from "./getBudgetYear";
import { getMDaType } from "helper";
import CommentModal from "./Comment";

function Exco() {
  const years = useYear();
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };
  const [modalOpen, setModalOpen] = useState(false);
  const [editData, setEditData] = useState(null);

  const handleEditClick = (item) => {
    setEditData(item);
    toggleModal();
  };
  const [huh,setHuh]=useState();

    const [modalOpen2, setModalOpen2] = useState(false);
  const toggleModal2 = () => {
    setModalOpen2(!modalOpen2);
  };

      const handleEditClick2 = (item,type) => {
    setEditData(item);
    toggleModal2();
    setHuh(type)
  };

 
    const [editingIndex, setEditingIndex] = useState(-1);
     const handleInputBlur = () => {
    setEditingIndex(-1);
      handleEditClick2(editData)
  };

  const handleDoubleClick = (index) => {
    setEditingIndex(index);
  };
  const fields = [
    {
      title: "Project/ Receipt Description",
      custom: true,
      component: (item) => <div>{item.description}</div>,
      rowSpan: true,
    },
    {
      title: "Administrative",
      custom: true,
      component: (item) => item.admin_description,
      rowSpan: true,
      width: 100,
    },
    {
      title: "Admin Code",
      custom: true,
      component: (item) => item.admin_code,
      rowSpan: true,
    },
    {
      title: "Economic Description",
      custom: true,
      component: (item) =>
        item.status === "final-mopb" ? (
          <a href="#" onClick={() => handleEditClick(item)}>
            {item.economic_description}
          </a>
        ) : (
          item.economic_description
        ),
      rowSpan: true,
    },
    {
      title: "Economic Code",
      custom: true,
      component: (item) =>
        item.status === "final-mopb" ? (
          <div onClick={() => handleEditClick(item)}>{item.economic_code}</div>
        ) : (
          item.economic_code
        ),
      rowSpan: true,
    },
    {
      title: "Program Description",
      custom: true,
      component: (item) =>
        item.status === "final-mopb" ? (
          <div onClick={() => handleEditClick(item)}>
            {item.program_description}
          </div>
        ) : (
          item.program_description
        ),
      rowSpan: true,
    },
    {
      title: "Program Code",
      custom: true,
      component: (item) =>
        item.status === "final-mopb" ? (
          <div onClick={() => handleEditClick(item)}>{item.program_code}</div>
        ) : (
          item.program_code
        ),
      rowSpan: true,
    },
    {
      title: "Functional Description",
      custom: true,
      component: (item) =>
        item.status === "final-mopb" ? (
          <div onClick={() => handleEditClick(item)}>
            {item.function_description}
          </div>
        ) : (
          item.function_description
        ),
      rowSpan: true,
    },
    {
      title: "Functional Code",
      custom: true,
      component: (item) =>
        item.status === "final-mopb" ? (
          <div onClick={() => handleEditClick(item)}>{item.function_code}</div>
        ) : (
          item.function_code
        ),
      rowSpan: true,
    },
    {
      title: "Geo Description",
      custom: true,
      component: (item) =>
        item.status === "final-mopb" ? (
          <div onClick={() => handleEditClick(item)}>
            {item.geo_description}
          </div>
        ) : (
          item.geo_description
        ),
      rowSpan: true,
    },
    {
      title: "Geo Code",
      custom: true,
      component: (item) =>
        item.status === "final-mopb" ? (
          <div onClick={() => handleEditClick(item)}>{item.geo_code}</div>
        ) : (
          item.geo_code
        ),
      rowSpan: true,
    },
    {
      title: "Fund Description",
      custom: true,
      component: (item) =>
        item.status === "final-mopb" ? (
          <div onClick={() => handleEditClick(item)}>
            {item.fund_description}
          </div>
        ) : (
          item.fund_description
        ),
    },
    {
      title: "Fund Code",
      custom: true,
      component: (item) =>
        item.status === "final-mopb" ? (
          <div onClick={() => handleEditClick(item)}>{item.fund_code}</div>
        ) : (
          item.fund_code
        ),
    },
    {
      title: "Fund Amount",
      custom: true,
      component: (item) => (
        <div style={{ textAlign: "right" }}>{formatNumber(item.fund_amt1)}</div>
      ),
    },
    {
      title: "Proposed Amount(₦)",
      custom: true,
      component: (item) => (
        <div style={{ float: "right" }}>{formatNumber(item.budget_amount)}</div>
      ),
      rowSpan: true,
    },
    {
      title: "MOPB Estimate(₦)",
      custom: true,
      component: (item) => (
        <div style={{ float: "right" }}>{formatNumber(item.mopb_amount)}</div>
      ),
      rowSpan: true,
    },
    {
      title: "EXCO Adjustments(₦)",
      custom: true,
      component: (item, idx) => (
        <div
          style={{ float: "right" }}
          onDoubleClick={() => handleDoubleClick(idx)}
        >
          {item.status === "final-mopb" && item.type_of_budget != "Capital" ? (
            editingIndex === idx ? (
              <Input
                name="exco_amount"
                value={item.exco_amount || item.mopb_amount}
                onChange={({ target: { value } }) => handleChanges(value, idx)}
                onBlur={handleInputBlur}
                style={{ width: "120px", textAlign: "right" }}
              />
            ) : (
              formatNumber(item.exco_amount || item.mopb_amount)
            )
          ) : (
            formatNumber(item.exco_amount || item.mopb_amount)
          )}
        </div>
      ),
      rowSpan: true,
    },
     {
      title: "Action",
      custom: true,
      width: 200,
      component: (item) => (
        <div className="text-center d-flex flex-direction-row justify-content-between">
          {/* <button
            disabled={item.status === "draft" ? false : true}
            className="btn btn-danger btn-sm"
            color="primary"
            onClick={() => handleDelete(item.id)}
          >
            <BiTrash />
          </button> */}
          <Button
            // disabled={item.status === "draft" ? false : true}
            color="primary"
            onClick={() => handleEditClick2(item,"edit")}
            size="sm"
          >
          Show Comment
          </Button>
        </div>
      ),
      rowSpan: true,
    },

    // {
    //   title: "Action",
    //   custom: true,
    //   width: 200,
    //   component: (item) => (
    //     <div className="text-center d-flex flex-direction-row justify-content-between">
    //       <button
    //         disabled={item.status === "draft" ? false : true}
    //         className="btn btn-danger btn-sm"
    //         color="primary"
    //         onClick={() => handleDelete(item.id)}
    //       >
    //         <BiTrash />
    //       </button>
    //       <Button
    //         disabled={item.status === "draft" ? false : true}
    //         color="primary"
    //         onClick={() => handleEditClick(item)}
    //         size="sm"
    //       >
    //         <Edit />
    //       </Button>
    //     </div>
    //   ),
    //   rowSpan: true,
    // },
    // {
    //   title: "House Adjustment",
    //   custom: true,
    //   component: (item) => (
    //     <div style={{ float: "right" }}>
    //       {formatNumber(parseFloat(item.exco_amount - item.house_amount))}
    //     </div>
    //   ),
    // },
    // {
    //   title: "Action",
    //   custom: true,
    //   component: (item) => (
    //     <div className="text-center">
    //       <button
    //         className="btn btn-primary"
    //         color="primary"
    //         onClick={() => handleSubmit(item)}
    //       >
    //         Submit
    //       </button>
    //     </div>
    //   ),
    // },
  ];

  const [results, setResults] = useState();
  const [form, setForm] = useState({
    budget_year: "",
    economic_code: "",
    economic_description: "",
    program_code: "",
    program_description: "",
    function_code: "",
    function_description: "",
    fund_code: "",
    fund_description: "",
    geo_code: "",
    geo_description: "",
    budget_amount: "",
    actual_amount: "",
    budget_type: "",
    start_date: "",
    end_date: "",
    segment: "",
    // mda_name: "",
  });
  // let data = results&&results.map(item => item.name)

  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  useEffect(() => {
    getNextYear((data) => setForm((p) => ({ ...p, budget_year: data })));
  }, []);

  const getBudgetNew = useCallback(() => {
    _fetchApi(
      `/get-budget-new/${form.mda_name}?query_type=exco&budget_year=${form.budget_year}`,
      (res) => {
        const modifiedResults = res.results
          .map((item) => {
            if (!item.exco_amount) {
              return { ...item, exco_amount: item.mopb_amount };
            }
            return item;
          })
          .filter(
            (item) =>
              item.status === "final-exco" ||
              item.status === "final-house" ||
              item.status === "final-mopb" ||
              item.status === "approved"
          );

        setResults(modifiedResults);
      },
      (err) => {
        console.log(err);
      }
    );
  }, [form.mda_name, form.budget_year]);

  useEffect(() => {
    getBudgetNew();
  }, [getBudgetNew]);

  const [results5, setResults5] = useState([]);
  // let data = results&&results.map(item => item.name)
  useEffect(() => {
    getSector({ type: "ADMINISTRATIVE SEGMENT" }, "select", setResults5);
  }, []);
 
  const handleChanges = (value, index) => {
    const arr = [...results];
    arr[index].exco_amount = value;
    setResults(arr);
  };
  const { addToast } = useToasts();
  const [mda, setMda] = useState();
  const [rSelected, setRSelected] = useState(1);
  const [pastData, setPastData] = useState([]);

  const fetchPastProposeLength = useCallback(() => {
    getMDaType(
      { type: "", sub_head: form.budget_year },
      form.budget_year,
      (d) => {
        setPastData(d);
      },
      "exco"
    );
  }, [form.budget_year]);

const getWaitingData = useCallback(() => {
  getMDaType(
    { type: "final-mopb", sub_head: form.budget_year },
    form.budget_year,
    (d) => {
      setMda(d);
      // alert(JSON.stringify(d))
    },
    "type"
  );
}, [form.budget_year]);
  const mdaRef = useRef(null);
  useEffect(() => {
    getWaitingData();
    fetchPastProposeLength();
  }, [getWaitingData, fetchPastProposeLength]);

  let aheadData99 = mda?.map((i) => ({
    head: `${i.head}-${i.description}(${i.line_item})`,
    description: i.description,
    code: i.head,
  }));

  const handleSubmit = (name = "") => {
    const filteredResults = results?.filter(
      (item) => item.status === "final-mopb"
    );
    let type = name === "final-exco" ? "UPDATE" : "INSERT";

    _postApi(
      `/post-budget?query_type=${type}&status=${name}`,
      name === "final-exco" ? filteredResults : [form],
      (res) => {
        if (res.success) {
          getMDaType(
            { type: "final-mopb", sub_head: form.budget_year },
            form.budget_year,
            (d) => {
              setMda(d);
            },
            "type"
          );
          getBudgetNew();
          fetchPastProposeLength();
          getWaitingData()
          addToast("Submitted Successfully", {
            appearance: "info",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
          setData([]);
        }
      },
      (err) => {
        console.log(err);
        // alert('An error occurred!');
      }
    );
  };
  const [budget, setBudget] = useState([]);
  const getBudgetType = () => {
    // setLoading(true);
    _fetchApi(
      `/get-budget-new/null?query_type=type&budget_type=${form.type}&budget_year=${form.year}`,
      (res) => {
        setBudget(res.results), setLoading(false);
      },
      (err) => console.log(err)
    );
  };
  useEffect(() => {
    getBudgetType();
  }, [form.type]);

  const filteredResults1 = results?.filter((item) => {
    if (!form.status || form.status === "All") {
      return true; // If no status is selected, or "All" is selected, return all items
    }
    return item.type_of_budget === form.status;
  });
  const filteredResults = results?.filter((item) => {
    if (!form.status || form.status === "All") {
      return item.status === "final-mopb";
    }
    return (
      item.status === "final-mopb" &&
      item.type_of_budget === form.status
    );
  });
  const totalAmount =
    rSelected === 1
      ? filteredResults?.reduce(
          (total, item) => parseFloat(item.mopb_amount) + total,
          0
        )
      : filteredResults1?.reduce(
          (total, item) => parseFloat(item.mopb_amount) + total,
          0
        );
  const [results4, setResult4] = useState([]);
  useEffect(() => {
    getSector({ type: "mdas" }, "mdas", (d) => {
      setResult4(d);
    });
  }, []);
  let aheadData = pastData?.map((i) => ({
    head: `${i.head}-${i.description}`,
    description: i.description,
    code: i.head,
  }));
  const user = useSelector((e) => e.auth.user);
  const [modal3, setModal3] = useState(false);
  const toggle3 = () => setModal3(!modal3);
  const repRef = useRef();
  const repRef1 = useRef();
  const repRef2 = useRef();
  const repRef3 = useRef();
  const repRef4 = useRef();
  const repRef5 = useRef();
  const [results6, setResults6] = useState([]);
  const [results1, setResults1] = useState([]);
  const [results2, setResults2] = useState([]);
  const [results3, setResults3] = useState([]);
  const [results9, setResults9] = useState([]);
  const [results10, setResults10] = useState([]);
  // let data = results&&results.map(item => item.name)
  useEffect(() => {
    getSector({ type: "ADMINISTRATIVE SEGMENT" }, "select", setResults6);
  }, []);

  useEffect(() => {
    getSector({ type: "ECONOMIC SEGMENT" }, "select", setResults1);
  }, []);

  useEffect(() => {
    getSector({ type: "FUNCTIONAL SEGMENT" }, "select", setResults2);
  }, []);

  useEffect(() => {
    getSector({ type: "PROGRAMME SEGMENT" }, "select", setResults3);
  }, []);

  useEffect(() => {
    getSector({ type: "FUND SEGMENT" }, "select", setResults9);
  }, []);

  useEffect(() => {
    getSector({ type: "GEO SEGMENT" }, "select", setResults10);
  }, []);

  let aheadData6 =
    results9 &&
    results9?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));
  let aheadData1 =
    results6 &&
    results6?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));
  let aheadData2 =
    results2 &&
    results2?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));
  let aheadData3 =
    results3 &&
    results3?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));
  let aheadData4 =
    results1 &&
    results1?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));
  let aheadData5 =
    results10 &&
    results10?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));
  const handleChanging = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  const handleSave = (updatedItem) => {
    const updatedItems = results.map((item) =>
      item.id === updatedItem.id ? updatedItem : item
    );
    setResults(updatedItems);
    toggleModal();
  };
  let newBudget = { type_of_budget: "All" };
  let isObjectExists = budget?.some(
    (item) => item.type_of_budget === newBudget.type_of_budget
  );

  if (!isObjectExists) {
    budget?.push(newBudget);
  } 
    const selectedResults = rSelected === 1 ? filteredResults : filteredResults1;

   const distinctResults = Array.from(
    new Map(selectedResults?.map(item => [item.type_of_budget, { type_of_budget: item.type_of_budget }])).values()
  );
  return (
    <DashboardLayout>
      <center>
        {form.mda_name && <h2>{/* {form.mda_code}- {form.mda_name} */}</h2>}
      </center>
      {/* {JSON.stringify({ results })} */}
      <MDBox pt={1} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography
                  variant="h5"
                  color="white"
                  className="text-center"
                >
                  Exco Adjustment Page
                </MDTypography>
              </MDBox>
              {/* <div className="">
                <Button
                  color="primary"
                  className="pr-5 pl-5 m-4"
                  style={{ float: "right" }}
                  onClick={() => toggle3()}
                >
                  Add New Proposal
                </Button>
              </div> */}

              <Modal isOpen={modal3} toggle={toggle3} size="lg">
                <ModalHeader>Continue With</ModalHeader>
                <ModalBody>
                  {/* {JSON.stringify(form)} */}
                  <div className=" row mb-4 p-3">
                    <div className="col-md-6 ">
                      <Label>Select budget year</Label>
                      <Input
                        type="select"
                        name="budget_year"
                        value={form.budget_year}
                        onChange={handleChange}
                      >
                        <option>--select--</option>
                        {years?.map((item) => (
                          <option key={item}>{item}</option>
                        ))}
                      </Input>
                    </div>

                    <div className="col-md-6">
                      <CustomTypeahead
                        ref={repRef}
                        labelKey="head"
                        label="Adminstrative Segment"
                        options={aheadData1}
                        onChange={(s) => {
                          if (s.length) {
                            setForm((p) => ({
                              ...p,
                              admin_code: s[0].code,
                              admin_description: s[0].description,
                              mda_name: s[0].description,
                            }));
                          }
                        }}
                      />
                    </div>
                    <div className="col-md-6">
                      <CustomTypeahead
                        ref={repRef1}
                        labelKey="head"
                        label="Economic Segment"
                        options={aheadData4}
                        onChange={(s) => {
                          if (s.length) {
                            setForm((p) => ({
                              ...p,
                              economic_code: s[0].code,
                              economic_description: s[0].description,
                            }));
                          }
                        }}
                      />
                    </div>
                    <div className="col-md-6">
                      <CustomTypeahead
                        ref={repRef2}
                        labelKey="head"
                        label="Fund Segment"
                        options={aheadData6}
                        onChange={(s) => {
                          if (s.length) {
                            setForm((p) => ({
                              ...p,
                              fund_code: s[0].code,
                              fund_description: s[0].description,
                            }));
                          }
                        }}
                      />
                    </div>
                    <div className="col-md-6">
                      <CustomTypeahead
                        ref={repRef3}
                        labelKey="head"
                        label="Geo Segment"
                        options={aheadData5}
                        onChange={(s) => {
                          if (s.length) {
                            setForm((p) => ({
                              ...p,
                              geo_code: s[0].code,
                              geo_description: s[0].description,
                            }));
                          }
                        }}
                      />
                    </div>

                    <div className="col-md-6">
                      <CustomTypeahead
                        ref={repRef4}
                        labelKey="head"
                        label="Program Segment"
                        options={aheadData3}
                        onChange={(s) => {
                          if (s.length) {
                            setForm((p) => ({
                              ...p,
                              program_code: s[0].code,
                              program_description: s[0].description,
                            }));
                          }
                        }}
                      />
                    </div>
                    <div className="col-md-6">
                      <CustomTypeahead
                        ref={repRef5}
                        labelKey="head"
                        label="Functional Segment"
                        options={aheadData2}
                        onChange={(s) => {
                          if (s.length) {
                            setForm((p) => ({
                              ...p,
                              function_code: s[0].code,
                              function_description: s[0].description,
                            }));
                          }
                        }}
                      />
                    </div>

                    <div className="col-md-6">
                      <lable>Exco Amount</lable>
                      <input
                        className="form-control"
                        type="number"
                        maxLength="4"
                        name="exco_amount"
                        value={form.exco_amount}
                        onChange={handleChanging}
                      />
                    </div>
                  </div>
                  <center>
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        handleSubmit("final-mopb");
                        toggle3();
                      }}
                    >
                      Add
                    </button>
                  </center>
                </ModalBody>
              </Modal>
              <MDBox>
                <MDBox pt={0}>
                  {/* {JSON.stringify(form)} */}
                  {editData && (
                    <BudgetForm
                      toggle={toggleModal}
                       toggle2={toggleModal2}
                      isOpen={modalOpen}
                      initialData={editData}
                      onSave={handleSave}
                      title={"Exco Adjustment Form"}
                      names="exco_amount"
                    />
                  )}
                   {editData && (
                    <CommentModal
                      toggle={toggleModal2}
                      isOpen={modalOpen2}
                      data={editData}
                      onSave={handleSave}
                      title={"exco Recommendation Form"}
                      names="exco recommendations"
                      edit={huh}
                    />
                  )}
                  <div className=" row p-3">
                    {/* <div className="col-md-3 mb-3">
                      <label>Select Sector</label>
                      <select
                        name="sector_type"
                        onChange={handleChange}
                        className="form-control"
                        value={form.sector_type}
                      >
                        <option>...Select...</option>
                        <option value="01%">Adminstrative Sector</option>
                        <option value="02%">Economic Sector</option>
                        <option value="05%">Social Sector</option>
                        <option value="03%">Law And Justice</option>
                      </select>
                    </div> */}

                    <Col md={4} className="mt-4">
                      <ButtonGroup size="sm">
                        <Button
                          color="primary"
                          outline
                          onClick={() => {
                            setRSelected(1);
                            mdaRef?.current?.clear();
                            setForm({ budget_year: form.budget_year });
                          }}
                          active={rSelected === 1}
                          size="sm"
                        >
                          MOPB Adjusted Proposals{" "}
                          <Badge color="warning">{mda?.length}</Badge>
                        </Button>
                        <Button
                          color="primary"
                          outline
                          onClick={() => {
                            setRSelected(2);
                            mdaRef?.current?.clear();
                            setForm({ budget_year: form.budget_year });
                          }}
                          active={rSelected === 2}
                        >
                          Exco Adjusted Proposals
                          <Badge color="warning">{pastData?.length}</Badge>
                        </Button>
                      </ButtonGroup>
                    </Col>

                    <div className="col-md-2 mb-3">
                      <CustomTypeahead
                        labelKey="type_of_budget"
                        label="Filter by Budget type"
                        options={distinctResults || []}
                        onChange={(selected) => {
                          setForm((p) => ({
                            ...p,
                            status: selected[0]?.type_of_budget || "",
                          }));
                        }}
                        defaultSelected={[{ type_of_budget: "All" }]}
                      />
                    </div>
                    <div className="col-md-2">
                      <Label>Select budget year</Label>
                      <Input
                        type="select"
                        name="budget_year"
                        value={form.budget_year}
                        onChange={handleChange}
                      >
                        <option>--select--</option>
                        {years?.map((item) => (
                          <option key={item}>{item}</option>
                        ))}
                      </Input>
                    </div>
                    {/* {JSON.stringify({ form: form.transaction_type })} */}

                    <div className="col-md-3" style={{ marginTop: "25px" }}>
                      <Button
                        color="primary"
                        className="pr-5 pl-5"
                        onClick={() => toggle3()}
                        style={{ marginRight: 5 }}
                        disabled={filteredResults?.length === 0 ? true : false}
                      >
                        Add New Proposal
                      </Button>

                      <Button
                        color="primary"
                        className="pr-5 pl-5"
                        onClick={() => handleSubmit("final-exco")}
                        disabled={filteredResults?.length === 0 ? true : false}
                      >
                        Submit
                      </Button>
                    </div>

                    <div className="col-md-4 " style={{ marginTop: -20 }}>
                      <CustomTypeahead
                        labelKey="head"
                        label="Select MDA"
                        ref={mdaRef}
                        options={rSelected === 1 ? aheadData99 : aheadData}
                        onChange={(s) => {
                          if (s.length) {
                            console.log(s);
                            setForm((p) => ({
                              ...p,
                              mda_code: s[0].code,
                              mda_name: s[0].description,
                            }));
                          }
                        }}
                      />
                    </div>

                    <div className="col-md-4" style={{ marginTop: 10 }}>
                      <SearchBar
                        placeholder="Search By code or description"
                        // filterText={searchText}
                        // onFilterTextChange={handleSearchChange}
                      />
                    </div>

                    <div className="col-md-1"></div>
                    <div
                      className="col-md-3 text-right mt-4"
                      style={{ textAlign: "right" }}
                    >
                      <span style={{ fontWeight: "bold", float: "right" }}>
                        TOTAL : ₦{totalAmount?.toLocaleString()}
                      </span>
                    </div>

                    <Scrollbar>
                      <ProposalTable
                        fields={fields}
                        data={
                          rSelected === 1 ? filteredResults : filteredResults1
                        }
                        type="exco"
                      />
                    </Scrollbar>
                  </div>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Exco;
