/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from "react";
import ReleasesTable from "./ReleasesTable";

export default function ApprovalReview() {
  return (
    <ReleasesTable
      filterBy="approval Reviewed"
      approval_status="Approval Released"
      title="Approval Review"
      query_type="select_by_status"
      return_status="Approval Initiated Returned"
    />
  );
}
