import ReportsRevByFund from "./ReportsRevByFund";

function ProgramReport() {
  const listdata = [
    { value: "total_revenue_by_fund", title: "Total Revenue by Fund" },
    { value: "recurrent_revenue_by_fund", title: " Rec Revenue by Fund" },
    { value: "capital_revenue_by_fund", title: " Capital Receipts by Fund" },
  ];
  return (
    <ReportsRevByFund
      type="total_revenue_by_fund"
      header="Total Revenue by Fund"
      route="/reports"
      options={listdata}
    />
  );
}

export default ProgramReport;
