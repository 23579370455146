import React, { useState, useEffect, useCallback } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Button, Grid } from "@mui/material";
import { _postApi, _fetchApi } from "redux/action/api";
import { formatNumber } from "redux/action/api";
import { getSector } from "helper";
import CustomTypeahead from "components/CustomTypeahead";
import { year } from "helper";
import { useToasts } from "react-toast-notifications";
import { useSelector } from "react-redux";
import { useYear } from "useYearList";
import {
  Col,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import PreviousCeiling from "./PreviousCeiling";

export default function MDACellingTable() {
  const user = useSelector((state) => state.auth.user.username);
  const [data, setData] = useState([]);
  const [testarray, setSetarr] = useState([]);
  const [type, setType] = useState([]);
  const [form, setForm] = useState({
    description: "",
    year,
    totalAmt: 0,
    head: "",
  });
  const [percentage] = useState(true);
  const [isOpen, setIsopen] = useState(false);
  const toggle = () => setIsopen(!isOpen);

  const [totalAmounts, setTotalAmounts] = useState("");
  const years = useYear();
  const total = data?.reduce((a, b) => a + parseFloat(b.amt || 0), 0);
  const totalVal = data?.reduce((a, b) => a + parseFloat(b.total_amt || 0), 0);
  const items = data?.length && data[0];
  const checkStatus = items?.req_status === "approved";
  const _total = totalAmounts?.length && totalAmounts[0].total_amt;

  let query_type = testarray?.length ? "update" : "insert";

  const handleChange = ({ target: { name, value } }) =>
    setForm((p) => ({ ...p, [name]: value }));

  const [mainOrg, setMainOrg] = useState();
  const getCeilingAmt = useCallback(() => {
    _postApi(
      `/budgetCeiling?query_type=select_ceiling_mda`,
      { head: form.head, year: form.year },
      (data) => {
        setTotalAmounts(data.results);
      },
      (err) => console.log(err)
    );
  }, [form.head, form.year]);
  useEffect(() => {
    getSector({ type: "mdas" }, "mdas", (d) => {
      // Change the status of each object in the array to 'sector'
      const newData = d.map((item) => ({
        ...item,
        status: "sector",
        amount: 0,
        total_amt: 0,
      }));
      setMainOrg(newData);
    });
  }, []);

  useEffect(() => {
    getCeilingAmt();
  }, [getCeilingAmt]);

  const [results, setResults] = useState([]);
  useEffect(() => {
    // setLoading(true);
    _fetchApi(
      `/reports?query_type=budget_utilization_report&budget_year=${form.year}&mda_name=${form.description}&mda_code=${form.head}`,
      (data) => {
        // if (data.success) {
        let _data = data?.results?.filter(
          (i) =>
            i.prev_year2_actual_amount !== null ||
            i.prev_year1_actual_amount !== null ||
            i.prev_year1_approve_amount !== null
        );
        setResults(_data);
        // setLoading(false);
      },
      (err) => {
        console.log(err);
        // setLoading(false);
      }
    );
  }, [form.budget_year, form.head, form.description]);

  return (
    <div>
      <DashboardLayout>
        <Modal isOpen={isOpen} size="lg">
          <ModalHeader toggle={toggle}>Previous Year Ceilling</ModalHeader>
          <PreviousCeiling status="" subhead={form.head} year={form.year} />
          <ModalFooter>
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <MDBox pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12} p={3}>
              <Card className="p-3">
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography
                    variant="h5"
                    color="white"
                    classNam="text-center"
                  >
                    MDA Budget Ceiling Report
                  </MDTypography>
                </MDBox>
                {/* {JSON.stringify(results)} */}
                <MDBox pt={3}>
                  <div className="row">
                    <div className="col-md-4">
                      {/* <label>Select Sector</label>
                      <select
                        name="sector_type"
                        onChange={handleChange}
                        className="form-control"
                        value={form.sector_type}
                      >
                        <option>...Select...</option>

                        <option value="01%">Adminstrative Sector</option>
                        <option value="02%">Economic Sector</option>
                        <option value="05%">Social Sector</option>
                        <option value="03%">Law And Justice</option>
                      </select> */}
                      <CustomTypeahead
                        labelKey="description"
                        label="Select Sector"
                        options={mainOrg}
                        onChange={(s) => {
                          if (s.length) {
                            console.log(s);
                            setForm((p) => ({
                              ...p,
                              description: s[0]?.description,
                              sector_type: s[0]?.code,
                              head: s[0]?.head,
                            }));
                          }
                        }}
                      />
                    </div>
                    {/* <div className="col-md-4 mb-3">
                      <CustomTypeahead
                        labelKey="head"
                        label="Select MDA"
                        options={aheadData}
                        onChange={(s) => {
                          if (s.length) {
                            console.log(s);
                            setForm((p) => ({
                              ...p,
                              description: s[0]?.description,
                              head: s[0]?.code,
                              sub_head: s[0]?.sub_head,
                            }));
                          }
                        }}
                      />
                    </div> */}
                    <div className="col-md-4">
                      {/* <div className="form-group m-2" style={{ width: "60%" }}>
                        <label>Select Value Type:</label>
                        <div className="d-flex">
                          <div className="form-check ">
                            <input
                              type="radio"
                              name="valueType"
                              value="percentage"
                              className="form-check-input"
                              checked={percentage}
                              onChange={handleOptionChange}
                            />
                            <label className="form-check-label">
                              Percentage
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              type="radio"
                              name="valueType"
                              value="fixed"
                              className="form-check-input"
                              checked={!percentage}
                              onChange={handleOptionChange}
                            />
                            <label className="form-check-label">Fixed</label>
                          </div>
                        </div>
                      </div> */}
                    </div>
                    <div className="col-md-4 ">
                      <Label>Select year</Label>
                      <Input
                        type="select"
                        name="year"
                        value={form.year}
                        onChange={handleChange}
                      >
                        <option>--select--</option>
                        {years?.map((item) => (
                          <option key={item}>{item}</option>
                        ))}
                      </Input>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between mb-3">
                    {/* <small
                      className="text-primary"
                      style={{ cursor: "pointer", marginTop: 10 }}
                      onClick={toggle}
                    >
                      <b>View previous year ceiling</b>
                    </small> */}
                    {/* <div className="p-2" style={{ float: "right" }}>
                      Total Ceilling{" "}
                      <b>₦{formatNumber(form.totalAmount || _total)}</b>
                    </div> */}
                  </div>

                  <table className="table table-bordered table">
                    <thead
                      style={{
                        fontSize: "12px",
                        borderWidth: 1,
                        borderColor: "black",
                        textAlign: "center",
                        backgroundColor: "orange",
                      }}
                    >
                      <tr>
                        <th style={{ backgroundColor: "orange" }}>
                          Description
                        </th>
                        <th style={{ backgroundColor: "orange" }}>MDA Code</th>
                        <th style={{ backgroundColor: "orange" }}>
                          Budget Types
                        </th>
                        <th style={{ backgroundColor: "orange" }}>
                          Ceiling Amount
                        </th>
                        <th style={{ backgroundColor: "orange" }}>
                          Budget Utilization(%)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>TOTAL MDA CEILLING</td>
                        <td></td>
                        <td></td>
                        <td style={{ textAlign: "right" }}>
                          ₦{formatNumber(form.totalAmount || _total)}
                        </td>
                        <td></td>
                      </tr>
                      {results?.map((row, index) => (
                        <tr key={index}>
                          <td>{row.mda_name}</td>
                          <td>{row.mda_code}</td>
                          <td>{row.description}</td>
                          <td style={{ textAlign: "right" }}>
                            {row.total_amount_ceiling}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {row.utilization_percentage}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </div>
  );
}
