import React from "react";
import PropTypes from "prop-types";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import "./excel.css";
const CollectionsSummaryPDF = ({ data = [], title = "" }) => {
  // Function to recursively render the nested data with styling
  const renderDataWithStyle = (data) => {
    return data.map((node, index) => (
      <React.Fragment key={index}>
        {node.level !== 0 && (
          <tr>
            <td
              style={{
                fontWeight: node.children.length ? "bold" : "normal",
              }}
            >
              {!node.children.length ? node.head : node.head.replace(/0+$/, "")}
            </td>
            <td
              style={{
                fontWeight: node.children.length ? "bold" : "normal",
              }}
            >
              {node.description}
            </td>
            <td
              style={{
                fontWeight: node.children.length ? "bold" : "normal",
              }}
            >
              {node.level}
            </td>
          </tr>
        )}
        {node.children && renderDataWithStyle(node.children)}
      </React.Fragment>
    ));
  };

  const flattenedData = data.flat(Infinity);

  return (
    <div>
      <ReactHTMLTableToExcel
        id="export-excel-button"
        className="export-excel-button"
        table="hidden-table"
        filename={`${title || "Account chart"}`}
        sheet={title}
        buttonText="Export to Excel"
      />
      <table id="hidden-table" style={{ display: "none" }}>
        <thead>
          <tr>
            <th>Code</th>
            <th>Description</th>
            <th>Level</th>
          </tr>
        </thead>
        <tbody>{renderDataWithStyle(flattenedData)}</tbody>
      </table>
    </div>
  );
};

CollectionsSummaryPDF.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string,
};

export default CollectionsSummaryPDF;
