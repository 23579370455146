import moment from "moment";
import { _postApi } from "redux/action/api";

export const getSector = (obj = {}, query = "", cd = (f) => f) => {
  _postApi(
    `/account?query_type=${query}`,
    obj,
    (data) => {
      console.log(data);
      if (data.success && data?.results) {
        cd(data?.results);
      }
    },
    (err) => console.log(err)
  );
};

export const getMDaType = (
  obj = {},
  query = "",
  cd = (f) => f,
  type = "type"
) => {
  _postApi(
    `/account?query_type=${type}&head=${query}`,
    obj,
    (data) => {
      console.log(data);
      if (data.success && data?.results) {
        cd(data?.results);
      }
    },
    (err) => console.log(err)
  );
};

export const handleDelete = (head = "") => {
  // alert(head)
  _postApi(
    `/account?query_type=delete&head=${head}`,
    {},
    (data) => {
      if (data.success) {
      }
    },
    (err) => console.log(err)
  );
};

export const year = moment().format("YYYY");

export const yearsBeforeAndAfter = () => {
  const years = [];
  for (let i = parseInt(year) - 6; i <= parseInt(year) + 4; i++) {
    years.push(i);
  }
  return years;
};

export const removeLeadingZerosLeaveLast = (valueToProcess, leaveLastZero) => {
  let str = valueToProcess.toString(); // Keep the original string, including leading zeros

  if (leaveLastZero) {
    // If leaveLastZero is true, remove all trailing zeros except the last one
    str = str.replace(/0+$/, (match) => (match.length > 1 ? "0" : match));
  } else {
    // If leaveLastZero is false, remove all trailing zeros
    str = str.replace(/0+$/, "");
  }

  return str;
};