/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faNairaSign } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import React from "react";
import DM_SANS_NORMAL from "../../assets/DM_Sans/DM_Sans/static/DMSans_24pt-SemiBold.ttf";
import DM_SANS_BOLD from "../../assets/DM_Sans/DM_Sans/static/DMSans_24pt-Bold.ttf";
import DM_SANS_ITALIC from "../../assets/DM_Sans/DM_Sans/static/DMSans-Italic.ttf";
import { FaMoneyBillAlt } from "react-icons/fa";

Font.register({
  family: "DM_SANS",
  fonts: [
    { src: DM_SANS_NORMAL, fontWeight: 700 },
    {
      src: DM_SANS_BOLD,
      fontStyle: "bold",
    },
    {
      src: DM_SANS_ITALIC,
      fontStyle: "italic",
    },
  ],
});

const styles = StyleSheet.create({
  body: {
    width: "100%",
    flexDirection: "row",
    pageBreakInside: "avoid",
    paddingTop: 20,
    fontSize: 12,
  },
  image1: {
    width: 100,
    height: 70,
    marginLeft: -15,
  },
  header: {
    fontFamily: "DM_SANS",
    fontSize: 20,
    marginTop: 10,
  },
  para: {
    fontFamily: "DM_SANS",
    fontStyle: "italic",
    fontSize: 12,
    fontStyle: "bold",
  },
  para1: {
    fontSize: 12,
    fontFamily: "DM_SANS",
    fontStyle: "bold",
  },
  bodyHeader: {
    textDecoration: "underline",
    fontFamily: "DM_SANS",
    fontStyle: "bold",
    fontSize: 12,
    marginTop: 10,
    fontWeight: 20,
  },
});

export const RealiseCollectionPdf = ({
  form,
  selecOffer = (f) => f,
  user = {},
  today = (f) => f,
}) => {
  const taday = moment().format("Do MMMM, YYYY");
  const ignoreMDA =
    form.mda_name === "Ministry of Finance" ||
    form.mda_name === "Government House";
  // nigeriaCOA;
  return (
    <Document>
      <Page size="A4">
        <View style={{ paddingLeft: 50, paddingRight: 30 }}>
          <View style={styles.body}>
            <View style={{ width: "100%", alignItems: "center" }}>
              <Image
                src={require("../../assets/images/pngwing.com.png")}
                style={styles.image1}
              />

              <Text style={styles.header}>
                MINISTRY OF BUDGET & ECONOMIC PLANNING
              </Text>
              <Text style={styles.para}>
                Old Government House Office Complex, Katsina
              </Text>
              <Text
                style={{
                  fontFamily: "DM_SANS",
                  fontStyle: "italic",
                  fontSize: 12,
                }}
              >
                P.MB 2132, KATSINA
              </Text>
            </View>
          </View>
          <View
            style={{
              width: "100%",
              paddingBottom: 5,
              flexDirection: "row",
            }}
          >
            <View
              style={{
                width: "70%",
                flexDirection: "row",
              }}
            >
              <Text style={styles.para1}>Our Ref: </Text>
              <Text
                style={{
                  fontSize: 12,
                  fontFamily: "DM_SANS",
                  fontWeight: "bold",
                  textDecoration: "underline",
                }}
              >
                S/MBEP/BD/{form.approval_type === "Recurrent" ? "REC" : "CAP"}
                /FA/{moment().format("YY")}/VOL.I/
                {form?.approval_no.substring(4)}
              </Text>
            </View>
            <View
              style={{
                width: "30%",
              }}
            >
              <Text
                style={{
                  fontSize: 12,
                  fontFamily: "DM_SANS",
                  fontWeight: "bold",
                  textDecoration: "underline",
                }}
              >
                {taday}
              </Text>
            </View>
          </View>

          <View
            style={{
              width: "50%",
            }}
          >
            <Text
              style={{
                fontSize: 12,
                marginBottom: 3,
                fontFamily: "DM_SANS",
                fontStyle: "italic",
              }}
            >
              The Accountant General,
            </Text>
            <Text
              style={{
                fontSize: 12,
                marginBottom: 3,
                fontFamily: "DM_SANS",
                fontStyle: "italic",
              }}
            >
              Ministry of Finance,
            </Text>
            <Text
              style={{
                fontSize: 12,
                fontFamily: "DM_SANS",
                fontStyle: "italic",
              }}
            >
              Katsina,
            </Text>
          </View>

          <View
            style={{
              width: "100%",
              textAlign: "center",
              alignItems: "center",
            }}
          >
            <Text style={styles.bodyHeader}>SPECIAL RELEASE OF FUNDS</Text>
          </View>
          <View
            style={{
              width: "100%",
              textAlign: "justify",
            }}
          >
            <Text
              style={{
                fontSize: 12,
                lineHeight: 1.5,
                marginTop: 7,
                fontStyle: "bold",
              }}
            >
              I am directed to convey approval for the release of
              <Text
                style={{
                  fontFamily: "DM_SANS",
                  fontStyle: "bold",
                }}
              >
                {" "}
                (N{selecOffer(form.approved_amount)}) only
              </Text>{" "}
              to the {form?.copies?.officeTitle}{" "}
              {form?.copies1?.officeTitle && form?.copies1?.officeTitle !== null
                ? " payable to the "
                : ""}
              {form?.copies1?.officeTitle && form?.copies1?.officeTitle !== null
                ? form?.copies1?.officeTitle + " "
                : ""}
              {form.narration}.
              <Text
                style={{
                  fontFamily: "DM_SANS",
                  fontStyle: "bold",
                }}
              >
                The Vote of Charge is {form.mda_code}/
                {form.approval_type === "Recurrent"
                  ? form.mda_economic_code
                  : form.program_code}
                .
              </Text>
            </Text>

            <Text
              style={{
                fontSize: 12,
                lineHeight: 1.5,
                marginTop: 7,
              }}
            >
              This letter is copied to the{" "}
              {form?.copies1 && form?.copies1?.officeTitle},{" "}
              {ignoreMDA ? null : form?.copies?.officeTitle}, the PS, (GHKT),
              the HC (MOF), the Auditor-General, the Office of the
              Auditor-General for the State, and the Director of Main Account,
              Ministry of Finance.
            </Text>
          </View>
          <View
            style={{
              marginLeft: "auto",
              marginTop: 40,
              width: "40%",
              textAlign: "center",
            }}
          >
            <Text
              style={{
                fontSize: 12,
                fontFamily: "DM_SANS",
                fontStyle: "bold",
              }}
            >
              (SAMINU MUH'D K/SOLI)
            </Text>
            <Text
              style={{
                fontSize: 12,
                fontFamily: "DM_SANS",
                fontStyle: "bold",
              }}
            >
              FOR: HON, COMMISSIONER
            </Text>
          </View>
          <View>
            <Text
              style={{
                fontSize: 12,
                marginTop: -15,
                fontFamily: "DM_SANS",
                fontStyle: "bold",
              }}
            >
              CC:
            </Text>
          </View>

          <View
            style={{
              marginTop: -15,
            }}
          >
            {ignoreMDA ? null : (
              <View>
                <Text
                  style={{
                    fontSize: 8,
                    marginLeft: 50,
                    fontFamily: "DM_SANS",
                    fontStyle: "italic",
                  }}
                >
                  {form?.copies?.officeTitle &&
                  form?.copies?.officeTitle !== null
                    ? form?.copies?.officeTitle + ","
                    : null}
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    marginLeft: 50,
                    fontFamily: "DM_SANS",
                    fontStyle: "italic",
                  }}
                >
                  Katsina,
                </Text>
              </View>
            )}
            {form?.copies1?.officeTitle &&
              form?.copies1?.officeTitle !== null && (
                <View>
                  <Text
                    style={{
                      marginTop: 5,
                      fontSize: 8,
                      marginLeft: 50,
                      fontFamily: "DM_SANS",
                      fontStyle: "italic",
                    }}
                  >
                    {form?.copies1?.officeTitle + ","}
                  </Text>
                  <Text
                    style={{
                      fontSize: 8,
                      marginLeft: 50,
                      fontFamily: "DM_SANS",
                      fontStyle: "italic",
                    }}
                  >
                    Katsina,
                  </Text>
                </View>
              )}
            {/* <Text
              style={{
                fontSize: 8,
                marginLeft: 50,
                fontFamily: "DM_SANS",
                fontStyle: "italic",
              }}
            >
              Katsina,
            </Text> */}
            <Text
              style={{
                fontSize: 8,
                marginLeft: 50,
                fontFamily: "DM_SANS",
                fontStyle: "italic",
              }}
            >
              The Permanent Secretary,
            </Text>
            <Text
              style={{
                fontSize: 8,
                marginLeft: 50,
                fontFamily: "DM_SANS",
                fontStyle: "italic",
              }}
            >
              Government House,
            </Text>
            <Text
              style={{
                fontSize: 8,
                marginLeft: 50,
                fontFamily: "DM_SANS",
                fontStyle: "italic",
              }}
            >
              Katsina,
            </Text>
            <Text
              style={{
                fontSize: 8,
                marginLeft: 50,
                fontFamily: "DM_SANS",
                fontStyle: "italic",
              }}
            >
              The Hon. Commissioner,
            </Text>
            <Text
              style={{
                fontSize: 8,
                marginLeft: 50,
                fontFamily: "DM_SANS",
                fontStyle: "italic",
              }}
            >
              Ministry of Finance,
            </Text>
            <Text
              style={{
                fontSize: 8,
                marginLeft: 50,
                fontFamily: "DM_SANS",
                fontStyle: "italic",
              }}
            >
              Katsina,
            </Text>
            <Text
              style={{
                fontSize: 8,
                marginLeft: 50,
                fontFamily: "DM_SANS",
                fontStyle: "italic",
              }}
            >
              The Auditor General,
            </Text>
            <Text
              style={{
                fontSize: 8,
                marginLeft: 50,
                fontFamily: "DM_SANS",
                fontStyle: "italic",
              }}
            >
              Office of the Auditor-General for the State,
            </Text>
            <Text
              style={{
                fontSize: 8,
                marginLeft: 50,
                fontFamily: "DM_SANS",
                fontStyle: "italic",
              }}
            >
              Katsina,
            </Text>
            <Text
              style={{
                fontSize: 8,
                marginLeft: 50,
                fontFamily: "DM_SANS",
                fontStyle: "italic",
              }}
            >
              The Director Main-Account,
            </Text>
            <Text
              style={{
                fontSize: 8,
                marginLeft: 50,
                fontFamily: "DM_SANS",
                fontStyle: "italic",
              }}
            >
              Ministry of Finance,
            </Text>
            <Text
              style={{
                fontSize: 8,
                marginLeft: 50,
                fontFamily: "DM_SANS",
                fontStyle: "italic",
              }}
            >
              Katsina
            </Text>
          </View>
          <View
            style={{
              width: "100%",
              textAlign: "center",
            }}
          >
            <Text
              style={{
                fontSize: 12,
                marginTop: 10,
                fontFamily: "DM_SANS",
                fontStyle: "italic",
              }}
            >
              Above for your information and awareness, please.
            </Text>
          </View>
          <View>
            <View
              style={{
                marginLeft: "auto",
                marginTop: 35,
                width: "40%",
                textAlign: "center",
              }}
            >
              {/* {user && user.imageURL && (
                <Image
                  src={user.imageURL}
                  style={{
                    marginHorizontal: "20px",
                    marginLeft: "auto",
                  }}
                />
              )} */}
              <Text
                style={{
                  fontSize: 12,
                  fontFamily: "DM_SANS",
                  fontStyle: "bold",
                }}
              >
                (SAMINU MUH'D K/SOLI)
              </Text>
              <Text
                style={{
                  fontSize: 12,
                  fontFamily: "DM_SANS",
                  fontStyle: "bold",
                }}
              >
                FOR: HON, COMMISSIONER
              </Text>
            </View>
          </View>
        </View>
      </Page>

      <Page size="A4">
        <View style={{ paddingLeft: 50, paddingRight: 30 }}>
          <View style={styles.body}>
            <View style={{ width: "100%", alignItems: "center" }}>
              <Image
                src={require("../../assets/images/pngwing.com.png")}
                style={styles.image1}
              />

              <Text style={styles.header}>
                MINISTRY OF BUDGET & ECONOMIC PLANNING
              </Text>
              <Text style={styles.para}>
                Old Government House Office Complex, Katsina
              </Text>
              <Text
                style={{
                  fontFamily: "DM_SANS",
                  fontStyle: "italic",
                  fontSize: 12,
                }}
              >
                P.MB 2132, KATSINA
              </Text>
            </View>
          </View>
          <View
            style={{
              width: "100%",
              paddingBottom: 5,
              flexDirection: "row",
            }}
          >
            <View
              style={{
                width: "70%",
                flexDirection: "row",
              }}
            >
              <Text style={styles.para1}>Our Ref: </Text>
              <Text
                style={{
                  fontSize: 12,
                  fontFamily: "DM_SANS",
                  fontWeight: "bold",
                  textDecoration: "underline",
                }}
              >
                S/MBEP/BD/{form.approval_type === "Recurrent" ? "REC" : "CAP"}
                /FA/{moment().format("YY")}/VOL.I/
                {form?.approval_no.substring(4)}
              </Text>
            </View>
            <View
              style={{
                width: "30%",
              }}
            >
              <Text
                style={{
                  fontSize: 12,
                  fontFamily: "DM_SANS",
                  fontWeight: "bold",
                  textDecoration: "underline",
                }}
              >
                {taday}
              </Text>
            </View>
          </View>

          <View
            style={{
              width: "50%",
            }}
          >
            <Text
              style={{
                fontSize: 12,
                marginBottom: 3,
                fontFamily: "DM_SANS",
                fontStyle: "italic",
              }}
            >
              The Accountant General,
            </Text>
            <Text
              style={{
                fontSize: 12,
                marginBottom: 3,
                fontFamily: "DM_SANS",
                fontStyle: "italic",
              }}
            >
              Ministry of Finance,
            </Text>
            <Text
              style={{
                fontSize: 12,
                fontFamily: "DM_SANS",
                fontStyle: "italic",
              }}
            >
              Katsina,
            </Text>
          </View>

          <View
            style={{
              width: "100%",
              textAlign: "center",
              alignItems: "center",
            }}
          >
            <Text style={styles.bodyHeader}>SPECIAL RELEASE OF FUNDS</Text>
          </View>
          <View
            style={{
              width: "100%",
              textAlign: "justify",
            }}
          >
            <Text
              style={{
                fontSize: 12,
                lineHeight: 1.5,
                marginTop: 7,
                fontStyle: "bold",
              }}
            >
              I am directed to convey approval for the release of
              <Text
                style={{
                  fontFamily: "DM_SANS",
                  fontStyle: "bold",
                }}
              >
                {" "}
                (N{selecOffer(form.approved_amount)}) only
              </Text>{" "}
              to the {form?.copies?.officeTitle && form.copies?.officeTitle}{" "}
              {form?.copies1?.officeTitle && form?.copies1?.officeTitle !== null
                ? " payable to the "
                : ""}
              {form?.copies1?.officeTitle && form?.copies1?.officeTitle !== null
                ? form?.copies1?.officeTitle + " "
                : ""}{" "}
              {form.narration}.
              <Text
                style={{
                  fontFamily: "DM_SANS",
                  fontStyle: "bold",
                }}
              >
                The Vote of Charge is {form.mda_code}/
                {form.approval_type === "Recurrent"
                  ? form.mda_economic_code
                  : form.program_code}
                .
              </Text>
            </Text>

            <Text
              style={{
                fontSize: 12,
                lineHeight: 1.5,
                marginTop: 7,
              }}
            >
              This letter is copied to the{" "}
              {form?.copies1 && form?.copies1?.officeTitle},{" "}
              {ignoreMDA ? null : form?.copies?.officeTitle}, the PS, (GHKT),
              the HC (MOF), the Auditor-General, the Office of the
              Auditor-General for the State, and the Director of Main Account,
              Ministry of Finance.
            </Text>
          </View>

          <View
            style={{
              width: "100%",
              textAlign: "center",
            }}
          >
            {/* <Text
              style={{
                fontSize: 12,
                marginTop: 20,
                fontFamily: "DM_SANS",
                fontStyle: "italic",
              }}
            >
              Above for your information and awareness, please.
            </Text> */}
          </View>
          <View>
            <View
              style={{
                marginLeft: "auto",
                marginTop: 60,
                width: "40%",
                textAlign: "center",
              }}
            >
              {/* {user && user.imageURL && (
                <Image
                  src={user.imageURL}
                  style={{
                    marginHorizontal: "20px",
                    marginLeft: "auto",
                  }}
                />
              )} */}
              <Text
                style={{
                  fontSize: 12,
                  fontFamily: "DM_SANS",
                  fontStyle: "bold",
                }}
              >
                (SAMINU MUH'D K/SOLI)
              </Text>
              <Text
                style={{
                  fontSize: 12,
                  fontFamily: "DM_SANS",
                  fontStyle: "bold",
                }}
              >
                FOR: HON, COMMISSIONER
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
