import {
  LOADING_SIGNUP,
  AUTH,
  LOADING_LOGIN,
  AUTH_ERROR,
  LOGOUT,
  LOADING_APP,
  ERRORS,
} from "./type";
import { apiURL } from "./api";
// import { useHistory } from 'react-router-dom';

export function signup(objs = {}, success = (f) => f, error = (f) => f) {
  return (dispatch) => {
    dispatch({ type: LOADING_SIGNUP });
    fetch(`${apiURL}/sign_up`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(objs),
    })
      .then((raw) => raw.json())
      .then((datas) => {
        console.log(datas, "DDDDDD", objs);
        const { user, token, taxID } = datas;
        dispatch({ type: LOADING_SIGNUP });
        if (datas.success) {
          dispatch(
            login(
              objs.email,
              objs.password,
              (data) => {
                success(datas);
                dispatch({ type: AUTH, payload: user[0] });
                if (token) {
                  localStorage.setItem("@@auth_token", token);
                }
              },
              (err) => {
                error(err);
                if (err && err.msg)
                  dispatch({
                    type: AUTH_ERROR,
                    payload: err,
                  });
              }
            )
          );
        } else {
          dispatch({
            type: AUTH_ERROR,
            payload: "Server error, try again.",
          });
        }
      })
      .catch((error) => {
        dispatch({ type: LOADING_SIGNUP });
        console.log({ error });
      });
  };
}

export function login(
  { username, password },
  success = (f) => f,
  error = (f) => f
) {
  return (dispatch) => {
    dispatch({ type: LOADING_LOGIN });
    fetch(`${apiURL}/sign_in`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, password }),
    })
      .then((raw) => raw.json())
      .then((data) => {
        console.log(data, "KKDKDKDDK");
        dispatch({ type: LOADING_LOGIN });
        if (data.success) {
          const { token } = data;
          const { user } = data;
          dispatch({ type: AUTH, payload: user });
          // console.log(token, "tOKEN HERE");
          if (token) {
            // alert(token);
            localStorage.setItem("@@auth_token", token);
            // alert(JSON.stringify(token));
          }
          success(data);
          getUserProfile(token);
        } else {
          dispatch({ type: ERRORS, payload: data.msg });
          error(data);
          console.log(data, "ERRRRRROOOOOORRRR");
        }
      })
      .catch((err) => {
        dispatch({ type: LOADING_LOGIN });
        error(err);
        console.log("", "ERROR");
      });
  };
}

export async function getUserProfile(_token) {
  try {
    // alert(_token);

    let response = await fetch(`${apiURL}/verify-token`, {
      method: "GET",
      headers: {
        authorization: "Bearer " + _token,
      },
    });
    let data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
}

export function init(history) {
  return (dispatch) => {
    let token = localStorage.getItem("@@auth_token");
    // alert(token);
    // alert(token);
    // alert(JSON.stringify(token))
    if (token) {
      // token = token;
      /**
       * Token present
       * verifyToken */
      getUserProfile(token)
        .then((data) => {
          // alert(JSON.stringify(data));
          if (data.success) {
            /**
             * Token is valid
             * navigate user to dashboard */

            dispatch({ type: LOADING_APP });
            const { user } = data;
            // alert(JSON.stringify(user));
            dispatch({ type: AUTH, payload: user });

            // location = "/dashboard";
            // history("/dashboard");
          } else {
            callback();
            localStorage.removeItem("@@auth_token");
            // location = "/dashboard";
          }
        })
        .catch((error) => {
          dispatch({
            type: ERRORS,
            payload: { msg: "Authentication failed", error },
          });
        });
    } else {
      /**
       * No token found
       * navigate user to auth page
       */
      // callback();
      dispatch({ type: ERRORS, payload: { msg: "Authentication failed" } });
    }
  };
}

export function logout() {
  return (dispatch) => {
    localStorage.removeItem("@@auth_token");
    location = "/";
    dispatch({ type: LOGOUT });
  };
}
