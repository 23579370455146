/* eslint-disable react/prop-types */
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";

// Data
import React, { useCallback, useEffect, useRef, useState } from "react";
import Scrollbar from "components/Tree/Scrollbar";
import MDInput from "components/MDInput";
import { useNavigate } from "react-router-dom";

import moment from "moment";
import SearchBar from "components/SearchBar";
import { FaFileDownload } from "react-icons/fa";
import { _fetchApi } from "redux/action/api";
import { useYear } from "useYearList";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
} from "reactstrap";
import { capitalizeWords } from "redux/action/api";
import { Spinner } from "reactstrap";
// import { formatNumber } from "redux/action/api";
import { formatNumbers } from "redux/action/api";
import { postApi } from "redux/action/api_tbeams";
import { getAllData } from "layouts/DashboardNew";
import { DownloadTableExcel, downloadExcel } from "react-export-table-to-excel";
import htmlDocx from "html-docx-js/dist/html-docx";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { getNextYear } from "../getBudgetYear";
import { expiredSetting } from "examples/admin/helper";
export default function ReportBudgetShareCom({
  type = "budget_overview",
  route = "/get-report",
  options = [],
}) {
  const [results, setResults] = useState();
  const [form, setForm] = useState({
    budget_year: "",
    query_type: type,
  });

  useEffect(() => {
    getNextYear((data) => setForm((p) => ({ ...p, budget_year: data })));
  }, []);

  const [loading, setLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const years = useYear();

  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  const [selectedOption, setSelectedOption] = useState({
    value: "budget_overview",
    title: "Budget Overview",
  });
  const [otherRecurrent, setOtherRecurrent] = useState([]);

  useEffect(() => {
    getAllData(
      "other_recurrent_new",
      "",
      `${form.budget_year}-01-01`,
      setOtherRecurrent
    );
  }, [form.budget_year]);

  const handleChangeSelect = (event) => {
    const selectedIndex = event.target.value;
    setSelectedOption(options[selectedIndex]);
  };
  const [openingBal, seTOpeningBal] = useState({});

  const getOpeningBalance = useCallback(() => {
    postApi(`opening_balance?query_type=select`, {
      budget_year: form.budget_year,
    })
      .then((data) => {
        let _data = data?.results[0];
        seTOpeningBal(_data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  }, [form.budget_year]);

  useEffect(() => {
    getOpeningBalance();
  }, [getOpeningBalance]);

  useEffect(() => {
    setLoading(true);
    _fetchApi(
      `${route}?query_type=${selectedOption.value}&budget_year=${form.budget_year}`,
      (data) => {
        setResults(data?.results);
        setLoading(false);
        // console.log(data?.results, "RAW DATA");
      },
      (err) => {
        console.log(err);
        setLoading(false);
      }
    );
  }, [form.budget_year, selectedOption.value]);

  const groupedData = results?.reduce((acc, item) => {
    const economicCode = item.economic_code.substring(0, 2);
    const group =
      economicCode === "11"
        ? "11 - Govt. Share of FAAC"
        : economicCode === "12"
        ? "12 - Independent Revenue"
        : economicCode === "21"
        ? "21 - Personnel Cost"
        : economicCode === "22"
        ? "22 - Other Recurrent Costs"
        : economicCode === "2202"
        ? "2202 - Overhead"
        : economicCode >= "2203" && economicCode <= "2209"
        ? "2203 - 2209 - Other Recurrent"
        : economicCode === "13"
        ? "13 - AID AND GRANTS"
        : economicCode === "14"
        ? "14 - Capital Development (CDF) Receipts"
        : economicCode === "23"
        ? "23 - Capital_Expenditure"
        : "";

    const category =
      group === "11 - Govt. Share of FAAC" ||
      group === "12 - Independent Revenue"
        ? "Recurrent_Revenue"
        : group === "21 - Personnel Cost" ||
          group === "22 - Other Recurrent Costs" ||
          group === "2202 - Overhead cost" ||
          group === "2203 - 2209 - Other Recurrent"
        ? "Recurrent_Expenditure"
        : group === "13 - AID AND GRANTS" ||
          group === "14 - Capital Development (CDF) Receipts"
        ? "Other_Receipts"
        : group === "23 - Capital_Expenditure"
        ? "Capital_Expenditure"
        : "Closing_Balance" || "Opening_Balance";

    if (!acc[group]) {
      acc[group] = {
        category,
        prev_year2_actual_amount: 0,
        prev_year1_approve_amount: 0,
        prev_year1_actual_amount: 0,
        budget_amount: 0,
        approve_amount: 0,
      };
    }

    acc[group].prev_year2_actual_amount += item.prev_year2_actual_amount || 0;
    acc[group].prev_year1_approve_amount += item.prev_year1_approve_amount || 0;
    acc[group].prev_year1_actual_amount += item.prev_year1_actual_amount || 0;
    acc[group].budget_amount += item.budget_amount || 0;
    acc[group].approve_amount += item.approve_amount || 0;

    return acc;
  }, {});

  // Initialize totals
  // {"budget_overhead":54757499623.65995,"actual_overhead":0,"actual_other_recurrent":0,"budget_other_recurrent":15279893572.31}]
  let totals = {
    Recurrent_Revenue: {
      prev_year2_actual_amount: 0,
      prev_year1_approve_amount: 0,
      prev_year1_actual_amount: 0,
      budget_amount: 0,
      approve_amount: 0,
    },

    Recurrent_Expenditure: {
      prev_year2_actual_amount: 0,
      prev_year1_approve_amount: 0,
      prev_year1_actual_amount: 0,
      budget_amount: 0,
      approve_amount: 0,
    },

    "2202_Overhead_cost": {
      prev_year2_actual_amount: 0,
      prev_year1_approve_amount: 0,
      prev_year1_actual_amount: 0,
      budget_amount: 0,
      approve_amount: 0,
    },

    "Other_Recurrent_(2203-2209)": {
      prev_year2_actual_amount: 0,
      prev_year1_approve_amount: 0,
      prev_year1_actual_amount: 0,
      budget_amount: 0,
      approve_amount: 0,
    },

    Transfer_To_Capital_Account: {
      prev_year2_actual_amount: 0,
      prev_year1_approve_amount: 0,
      prev_year1_actual_amount: 0,
      budget_amount: 0,
      approve_amount: 0,
    },
    Other_Receipts: {
      prev_year2_actual_amount: 0,
      prev_year1_approve_amount: 0,
      prev_year1_actual_amount: 0,
      budget_amount: 0,
      approve_amount: 0,
    },
    Capital_Expenditure: {
      prev_year2_actual_amount: 0,
      prev_year1_approve_amount: 0,
      prev_year1_actual_amount: 0,
      budget_amount: 0,
      approve_amount: 0,
    },

    Total_Revenue: {
      prev_year2_actual_amount: 0,
      prev_year1_approve_amount: 0,
      prev_year1_actual_amount: 0,
      budget_amount: 0,
      approve_amount: 0,
    },
    Total_Expenditure: {
      prev_year2_actual_amount: 0,
      prev_year1_approve_amount: 0,
      prev_year1_actual_amount: 0,
      budget_amount: 0,
      approve_amount: 0,
    },

    Closing_Balance: {
      prev_year2_actual_amount: 0,
      prev_year1_approve_amount: 0,
      prev_year1_actual_amount: 0,
      budget_amount: 0,
      approve_amount: 0,
    },

    Opening_Balance: {
      prev_year2_actual_amount: openingBal.prev_year2_amt,
      prev_year1_approve_amount: openingBal.prev_year1_amt,
      prev_year1_actual_amount: openingBal.prev_year1_closing,
      budget_amount: openingBal.opening_balance,
      approve_amount: openingBal.opening_balance,
    },
  };
  // Calculate totals
  Object.keys(groupedData || {}).forEach((group) => {
    const category = groupedData[group].category;
    if (category) {
      Object.keys(totals[category] || {}).forEach((key) => {
        totals[category][key] += groupedData[group][key];
      });
    }
    if (
      category === "Opening_Balance" ||
      category === "Recurrent_Revenue" ||
      category === "Recurrent_Expenditure" ||
      category === "Opening_Balance"
    ) {
      totals.Transfer_To_Capital_Account.prev_year2_actual_amount =
        totals.Recurrent_Revenue.prev_year2_actual_amount +
        totals.Opening_Balance.prev_year2_actual_amount -
        totals.Recurrent_Expenditure.prev_year2_actual_amount;

      totals.Transfer_To_Capital_Account.prev_year1_approve_amount =
        totals.Opening_Balance.prev_year1_approve_amount +
        totals.Recurrent_Revenue.prev_year1_approve_amount -
        totals.Recurrent_Expenditure.prev_year1_approve_amount;

      totals.Transfer_To_Capital_Account.prev_year1_actual_amount =
        totals.Opening_Balance.prev_year1_actual_amount +
        totals.Recurrent_Revenue.prev_year1_actual_amount -
        totals.Recurrent_Expenditure.prev_year1_actual_amount;

      totals.Transfer_To_Capital_Account.budget_amount =
        totals.Opening_Balance.budget_amount +
        totals.Recurrent_Revenue.budget_amount -
        totals.Recurrent_Expenditure.budget_amount;

      totals.Transfer_To_Capital_Account.approve_amount =
        totals.Opening_Balance.approve_amount +
        totals.Recurrent_Revenue.approve_amount -
        totals.Recurrent_Expenditure.approve_amount;
    }
    // total revenue
    if (category === "Recurrent_Revenue" || category === "Other_Receipts") {
      totals.Total_Revenue.prev_year2_actual_amount =
        parseFloat(totals.Recurrent_Revenue.prev_year2_actual_amount) +
        // parseFloat(totals.Opening_Balance.approve_amount) +
        parseFloat(totals.Other_Receipts.prev_year2_actual_amount);

      totals.Total_Revenue.prev_year1_approve_amount =
        parseFloat(totals.Recurrent_Revenue.prev_year1_approve_amount) +
        // parseFloat(totals.Opening_Balance.approve_amount) +
        parseFloat(totals.Other_Receipts.prev_year1_approve_amount);

      totals.Total_Revenue.prev_year1_actual_amount =
        parseFloat(totals.Opening_Balance.prev_year1_actual_amount) +
        parseFloat(totals.Recurrent_Revenue.prev_year1_actual_amount) +
        // parseFloat(totals.Opening_Balance.approve_amount) +
        parseFloat(totals.Other_Receipts.prev_year1_actual_amount);

      totals.Total_Revenue.budget_amount =
        parseFloat(totals.Opening_Balance.budget_amount) +
        parseFloat(totals.Recurrent_Revenue.budget_amount) +
        // parseFloat(totals.Opening_Balance.approve_amount) +
        parseFloat(totals.Other_Receipts.budget_amount);

      totals.Total_Revenue.approve_amount =
        parseFloat(totals.Opening_Balance.approve_amount) +
        parseFloat(totals.Recurrent_Revenue.approve_amount) +
        // parseFloat(totals.Opening_Balance.approve_amount) +
        parseFloat(totals.Other_Receipts.approve_amount);
    }
    // Total Expenditure
    if (
      category === "Capital_Expenditure" ||
      category === "Recurrent_Expenditure"
    ) {
      totals.Total_Expenditure.prev_year2_actual_amount =
        parseFloat(totals.Capital_Expenditure.prev_year2_actual_amount) +
        parseFloat(totals.Recurrent_Expenditure.prev_year2_actual_amount);

      totals.Total_Expenditure.prev_year1_approve_amount =
        parseFloat(totals.Capital_Expenditure.prev_year1_approve_amount) +
        parseFloat(totals.Recurrent_Expenditure.prev_year1_approve_amount);

      totals.Total_Expenditure.prev_year1_actual_amount =
        parseFloat(totals.Capital_Expenditure.prev_year1_actual_amount) +
        parseFloat(totals.Recurrent_Expenditure.prev_year1_actual_amount);

      totals.Total_Expenditure.budget_amount =
        parseFloat(totals.Capital_Expenditure.budget_amount) +
        parseFloat(totals.Recurrent_Expenditure.budget_amount);

      totals.Total_Expenditure.approve_amount =
        parseFloat(totals.Capital_Expenditure.approve_amount) +
        parseFloat(totals.Recurrent_Expenditure.approve_amount);
    }

    // Closing balance

    if (
      category === "Total_Revenue" ||
      category === "Total_Expenditure" ||
      category === "Capital_Expenditure" ||
      category === "Recurrent_Expenditure" ||
      category === "Opening_Balance"
    ) {
      totals.Closing_Balance.prev_year2_actual_amount =
        parseFloat(totals.Total_Revenue.prev_year2_actual_amount || 0) -
        parseFloat(totals.Total_Expenditure.prev_year2_actual_amount || 0);

      totals.Closing_Balance.prev_year1_approve_amount =
        parseFloat(totals.Total_Revenue.prev_year1_approve_amount) -
        parseFloat(totals.Total_Expenditure.prev_year1_approve_amount);

      totals.Closing_Balance.prev_year1_actual_amount =
        parseFloat(totals.Total_Revenue.prev_year1_actual_amount) -
        parseFloat(totals.Total_Expenditure.prev_year1_actual_amount);

      totals.Closing_Balance.budget_amount =
        parseFloat(totals.Total_Revenue.budget_amount) -
        parseFloat(totals.Total_Expenditure.budget_amount);

      totals.Closing_Balance.approve_amount =
        parseFloat(totals.Total_Revenue.approve_amount) -
        parseFloat(totals.Total_Expenditure.approve_amount);
    }

    if (
      category === "Capital_Expenditure" ||
      category === "Recurrent_Expenditure" ||
      category === "Opening_Balance"
    ) {
      totals.Opening_Balance.prev_year1_approve_amount =
        openingBal.prev_year1_amt;

      totals.Opening_Balance.budget_amount = openingBal.opening_balance;

      totals.Opening_Balance.approve_amount = openingBal.opening_balance;
    }

    if (
      category === "Recurrent_Expenditure" ||
      category === "Opening_Balance"
    ) {
      totals["2202_Overhead_cost"].prev_year2_actual_amount =
        otherRecurrent[0]?.two_actual_overhead;
      totals["2202_Overhead_cost"].prev_year1_approve_amount =
        otherRecurrent[0]?.one_budget_overhead;
      totals["2202_Overhead_cost"].prev_year1_actual_amount =
        otherRecurrent[0]?.one_actual_overhead;
      totals["2202_Overhead_cost"].budget_amount =
        otherRecurrent[0]?.budget_overhead;

      totals["2202_Overhead_cost"].approve_amount =
        otherRecurrent[0]?.budget_overhead;
    }
    if (
      category === "Recurrent_Expenditure" ||
      category === "Opening_Balance"
    ) {
      totals["Other_Recurrent_(2203-2209)"].prev_year2_actual_amount =
        otherRecurrent[0]?.two_actual_other_recurrent;
      totals["Other_Recurrent_(2203-2209)"].prev_year1_approve_amount =
        otherRecurrent[0]?.one_budget_other_recurrent;
      totals["Other_Recurrent_(2203-2209)"].prev_year1_actual_amount =
        otherRecurrent[0]?.one_actual_other_recurrent;

      totals["Other_Recurrent_(2203-2209)"].budget_amount =
        otherRecurrent[0]?.budget_other_recurrent;

      totals["Other_Recurrent_(2203-2209)"].approve_amount =
        otherRecurrent[0]?.budget_other_recurrent;
    }
  });

  // console.log(groupedData, "LALALAL");

  const report = {
    Opening_Balance: [],
    Recurrent_Revenue: [],
    Recurrent_Expenditure: [],
    "2202_Overhead_cost": [],
    "Other_Recurrent_(2203-2209)": [],
    Transfer_To_Capital_Account: [],
    Other_Receipts: [],
    Capital_Expenditure: [],
    Total_Revenue: [],
    Total_Expenditure: [],
    "": [],
    Closing_Balance: [],
  };

  Object.keys(groupedData || {}).forEach((item) => {
    const category = groupedData[item].category;
    if (category) {
      report[category]?.push({ item, ...groupedData[item] });
    }
  });

  // Display totals in the console
  // console.log("Totals:", totals);
  const tableRef = useRef(null);

  const handleExportToWord = () => {
    const tableHtml = tableRef.current.outerHTML;
    const converted = htmlDocx.asBlob(tableHtml);
    saveAs(converted, `${type}-report.docx`);
  };
  const [pdfLoading, setPdfLoading] = useState(false);

  const handleExportToPDF = () => {
    setPdfLoading(true);
    const input = document.getElementById("table2");
    console.log(input);
    if (input) {
      html2canvas(input)
        .then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const pdf = new jsPDF("p", "mm", "a4");
          const imgProps = pdf.getImageProperties(imgData);
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
          pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
          pdf.save(`${title.replace(/ /g, "_")}.pdf`);
          setPdfLoading(false);
        })
        .catch((error) => {
          console.error("Error generating PDF:", error);
          setPdfLoading(false);
        });
    } else {
      console.error("Element not found");
      setPdfLoading(false);
    }
  };

  const generateTable = () => {
    return (
      <table className="table table-striped table-bordered" ref={tableRef}>
        <thead
          style={{
            fontSize: "12px",
            borderWidth: 1,
            borderColor: "black",
            textAlign: "center",
          }}
        >
          <tr style={{ backgroundColor: "orange" }}>
            <th style={{ backgroundColor: "orange" }}>Items</th>
            <th style={{ backgroundColor: "orange", fontWeight: "bolder" }}>{`${
              parseInt(form.budget_year) - 2
            } Full year  Actual(₦)`}</th>
            <th style={{ backgroundColor: "orange" }}>{`${
              parseInt(form.budget_year) - 1
            } Approved Budget(₦)`}</th>
            <th style={{ backgroundColor: "orange" }}>{`${
              parseInt(form.budget_year) - 1
            } Performance January To August(₦)`}</th>
            {/* <th>{`${parseInt(form.budget_year)} MDA Proposal`}</th> */}
            <th style={{ backgroundColor: "orange" }}>{`${parseInt(
              form.budget_year
            )} Proposed Budget`}</th>
            <th style={{ backgroundColor: "orange" }}>{`${parseInt(
              form.budget_year
            )} Approved Budget`}</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(report).map((category) => (
            <React.Fragment key={category}>
              <tr
                key={`${category}-totals`}
                style={{
                  fontSize: "13px",
                  fontWeight:
                    category === "2202_Overhead_cost" ||
                    category === "Other_Recurrent_(2203-2209)"
                      ? "italic"
                      : "bold",
                }}
              >
                <td
                  style={{
                    fontStyle:
                      category === "2202_Overhead_cost" ||
                      category === "Other_Recurrent_(2203-2209)"
                        ? "italic"
                        : "bold",
                  }}
                >
                  {/* {JSON.stringify(category)} */}
                  {capitalizeWords(category)}
                </td>
                <td
                  style={{
                    textAlign: "right",
                    fontStyle:
                      category === "2202_Overhead_cost" ||
                      category === "Other_Recurrent_(2203-2209)"
                        ? "italic"
                        : "bold",
                  }}
                >
                  {formatNumbers(totals[category]?.prev_year2_actual_amount)}{" "}
                </td>
                <td
                  style={{
                    textAlign: "right",
                    fontStyle:
                      category === "2202_Overhead_cost" ||
                      category === "Other_Recurrent_(2203-2209)"
                        ? "italic"
                        : "bold",
                  }}
                >
                  {formatNumbers(totals[category]?.prev_year1_approve_amount)}
                </td>
                <td
                  style={{
                    textAlign: "right",
                    fontStyle:
                      category === "2202_Overhead_cost" ||
                      category === "Other_Recurrent_(2203-2209)"
                        ? "italic"
                        : "bold",
                  }}
                >
                  {formatNumbers(totals[category]?.prev_year1_actual_amount)}
                </td>
                {/* <td style={{ textAlign: "right" }}>
                  {formatNumbers(totals[category]?.budget_amount}
                </td> */}
                <td
                  style={{
                    textAlign: "right",
                    fontStyle:
                      category === "2202_Overhead_cost" ||
                      category === "Other_Recurrent_(2203-2209)"
                        ? "italic"
                        : "bold",
                  }}
                >
                  {formatNumbers(totals[category]?.approve_amount)}
                </td>
                <td
                  style={{
                    textAlign: "right",
                    fontStyle:
                      category === "2202_Overhead_cost" ||
                      category === "Other_Recurrent_(2203-2209)"
                        ? "italic"
                        : "bold",
                  }}
                >
                  {formatNumbers(totals[category]?.approve_amount)}
                </td>
              </tr>
              {report[category].map((item) => (
                <tr key={item.item} style={{ fontSize: "13px" }}>
                  <td>
                    {/* {JSON.stringify(report["2202_Overhead_cost"])} */}
                    {capitalizeWords(item.item).toUpperCase()}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {isNaN(parseFloat(item.prev_year2_actual_amount))
                      ? "---"
                      : formatNumbers(
                          parseFloat(item.prev_year2_actual_amount)
                        )}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {isNaN(parseFloat(item.prev_year1_approve_amount))
                      ? "---"
                      : formatNumbers(
                          parseFloat(item.prev_year1_approve_amount)
                        )}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {isNaN(parseFloat(item.prev_year1_actual_amount))
                      ? "---"
                      : formatNumbers(
                          parseFloat(item.prev_year1_actual_amount)
                        )}
                  </td>
                  {/* <td style={{ textAlign: "right" }}>
                    {isNaN(parseFloat(item.budget_amount))
                      ? "---"
                      : parseFloat(item.budget_amount)}
                  </td> */}
                  <td style={{ textAlign: "right" }}>
                    {isNaN(parseFloat(item.approve_amount))
                      ? "---"
                      : formatNumbers(parseFloat(item.approve_amount))}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {isNaN(parseFloat(item.approve_amount))
                      ? "---"
                      : formatNumbers(parseFloat(item.approve_amount))}
                  </td>
                </tr>
              ))}
              {/* Display category totals */}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <DashboardLayout>
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h5" color="white">
                  {capitalizeWords(selectedOption.value)}
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {pdfLoading && (
                  <div className="loading-container text-center">
                    <Spinner color="primary" style={{ marginTop: "20px" }} />
                    Generating PDF, please wait...
                  </div>
                )}
                {/* {JSON.stringify({ openingBal })} */}
                <div className="d-flex justify-content-between m-3">
                  <div className="col-md-2">
                    <Input
                      type="select"
                      name="budget_year"
                      value={form.budget_year}
                      onChange={handleChange}
                    >
                      <option>--select--</option>
                      {years?.map((item) => (
                        <option key={item}>{item}</option>
                      ))}
                    </Input>
                  </div>
                  <div className="col-md-4">
                    <select
                      className="form-control"
                      name="query_type"
                      value={
                        selectedOption
                          ? options.findIndex(
                              (opt) => opt.value === selectedOption.value
                            )
                          : ""
                      }
                      onChange={handleChangeSelect}
                    >
                      <option>Select report type</option>
                      {options.map((item, i) => (
                        <option value={i} key={i}>
                          {item.title}
                        </option>
                      ))}
                    </select>
                  </div>
                  <SearchBar />
                  {/* <CSVLink
                      data={results && results ? results : []}
                      className="text-white"
                      filename={selectedOption.value}
                      headers={headers}
                    >
                      <FaFileDownload color="white" size="1.2rem" /> Download
                      Excelddd
                    </CSVLink> */}
                  <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                    <DropdownToggle
                      className="btn btn-primary text-white mb-3 ml-3"
                      caret
                    >
                      <FaFileDownload
                        color="white"
                        size="1.2rem"
                        className="mr-5"
                      />
                      Download
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={() => console.log("Option 1 clicked")}
                      >
                        <DownloadTableExcel
                          filename="Budget Overview"
                          sheet="Budget Overview"
                          currentTableRef={tableRef.current}
                        >
                          <span> Export excel </span>
                        </DownloadTableExcel>
                      </DropdownItem>
                      <DropdownItem onClick={() => handleExportToPDF()}>
                        PDF
                      </DropdownItem>
                      <DropdownItem />

                      <DropdownItem onClick={() => handleExportToWord()}>
                        Word
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
                <div className="p-3">
                  {loading ? (
                    <div className="loading-container text-center">
                      <Spinner color="primary" style={{ marginTop: "20px" }} />{" "}
                      Loading...
                    </div>
                  ) : (
                    <Scrollbar>{generateTable()}</Scrollbar>
                  )}
                </div>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
