import CapitalExpShare from "./CapitalExpShare";
const listdata = [
  { value: "capital_exp_by_project", title: "Capital Expenditure by Project" },
];
function CapitalProjects() {
  return (
    <CapitalExpShare
      type="capital_exp_by_project"
      route="/reports"
      options={listdata}
      title="Capital Expenses by Project"
    />
  );
}

export default CapitalProjects;
