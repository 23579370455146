import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import InputForm from "./Component/InputForm";

import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Grid } from "@mui/material";
import { getSector } from "helper";
import AgricIndex from "./AgricIndex";
export default function HealthSector() {
  const [form, setForm] = useState({
    date: "",
    legal_frameworks_sector: "",
    stakeholders_sector: "",
    major_crops: "",
    trained_agricultural_practices: "",
    applying_agricultural_practices: "",
    trained_agricultural_skills: "",
    applying_agricultural_skills: "",
    agricultural_contribution_percentage: "",
    farmers_adopted_technology: "",
    registered_farmers: "",
    forecast_registered_farmers_percentage: "",
    engineering_registered_farmers_percentage: "",
    livestock_production_growth_rate: "",
    registered_farmers_finance: "",
    registered_farmers_insurance_subsidy: "",
    fisheries_growth_rate_percentage: "",
    farmers_clusters_facility: "",
    first_Latitude: "",
    first_Longitude: "",
    first_altitude: "",
    first_accuracy: "",
    second_Latitude: "",
    second_Longitude: "",
    second_altitude: "",
    second_accuracy: "",
    third_Latitude: "",
    third_Longitude: "",
    third_altitude: "",
    third_accuracy: "",
    actual_expenditure: "",
    personnel_expenditure: "",
    overhead_expenditure: "",
    capital_expenditure: "",
    adequate_manpower: "",
    adequate_infrastructure_facilities: "",
    linkage_with_other_sectors: "",
    non_governmental_organisations: "",
    suggestions_for_improvements: "",
    name_of_m_and_staff: "",
    mda_name: "",
    economic_code: "",
    function_code: "",
    program_code: "",
    fund_code: "",
    geo_code: "",
  });
  const [display, setDisplay] = useState(true);
  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
    console.log(form);
  };
  const toggle = (selectedItem) => {
    if (selectedItem) {
      setForm({
        ...form,
        mda_name: selectedItem.mda_name,
        economic_code: selectedItem.economic_code,
        function_code: selectedItem.function_code,
        program_code: selectedItem.program_code,
        fund_code: selectedItem.fund_code,
        geo_code: selectedItem.geo_code,
      });
    }
    setDisplay(!display);
  };

  const handleSubmit = () => {
    console.log(form);
  };
  const [results, setResults] = useState([]);
  const [results1, setResults1] = useState([]);
  const [results2, setResults2] = useState([]);
  const [results3, setResults3] = useState([]);
  const [results4, setResults4] = useState([]);
  const [results5, setResults5] = useState([]);
  // let data = results&&results.map(item => item.name)
  useEffect(() => {
    getSector({ type: "ADMINISTRATIVE SEGMENT" }, "select", setResults);
  }, []);

  useEffect(() => {
    getSector({ type: "ECONOMIC SEGMENT" }, "select", setResults1);
  }, []);

  useEffect(() => {
    getSector({ type: "FUNCTIONAL SEGMENT" }, "select", setResults2);
  }, []);

  useEffect(() => {
    getSector({ type: "PROGRAMME SEGMENT" }, "select", setResults3);
  }, []);

  useEffect(() => {
    getSector({ type: "FUND SEGMENT" }, "select", setResults4);
  }, []);

  useEffect(() => {
    getSector({ type: "GEO SEGMENT" }, "select", setResults5);
  }, []);
  const repRef = useRef();

  const repRef1 = useRef();
  const repRef2 = useRef();
  const repRef3 = useRef();
  const repRef4 = useRef();
  const repRef5 = useRef();
  let aheadData =
    results &&
    results?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));
  let aheadData1 =
    results1 &&
    results1?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));
  let aheadData2 =
    results2 &&
    results2?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));
  let aheadData3 =
    results3 &&
    results3?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));
  let aheadData4 =
    results4 &&
    results4?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));
  let aheadData5 =
    results5 &&
    results5?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));

  return (
    <div>
      <DashboardLayout>
        <MDBox pt={2} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card className="">
              <MDBox>
              <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                    <MDTypography variant="h5" color="white">
                        Health Sector Data Aggregation Tool
                      </MDTypography>
                      </MDBox>
                      {display ? (
                    <AgricIndex toggle={toggle} />
                  ) : (
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  p={3}
                >
                  <MDBox color="text" px={2}>
                  <Button onClick={() => toggle()}>Go Back</Button>
                  <Row className="p-0 mt-3">
                      <Row>
                        <Col md={4}>
                          <label>Date</label>
                          <InputForm
                            id="exampleSelect"
                            style={{
                              marginBottom: 20,
                            }}
                            value={form.date}
                            name="date"
                            onChange={handleChange}
                            type="date"
                          />
                        </Col>
                      </Row>
                      <div className="col-md-6">
                          <InputForm
                            value={form.mda_name}
                            name="mda_name"
                            label="MDA NAME"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-6">
                          <InputForm
                            value={form.economic_code}
                            name="economic_code"
                            label="ECONOMIC CODE"
                            onChange={handleChange}
                          />
                        </div>

                        <div className="col-md-6">
                          <InputForm
                            value={form.function_code}
                            name="function_code"
                            label="Functional Code"
                            onChange={handleChange}
                          />
                        </div>

                        <div className="col-md-6">
                          <InputForm
                            value={form.program_code}
                            name="program_code"
                            label="Program Code"
                            onChange={handleChange}
                          />
                        </div>

                        <div className="col-md-6">
                          <InputForm
                            value={form.fund_code}
                            name="fund_code"
                            label="Fund Code"
                            onChange={handleChange}
                          />
                        </div>

                        <div className="col-md-6">
                          <InputForm
                            value={form.geo_code}
                            name="geo_code"
                            label="Geo Code"
                            onChange={handleChange}
                          />
                        </div>
                      {/* SECTION ONE: Overview */}

                      <div style={{ display: "flex", marginBottom: 20 }}>
                        <div className="underline"></div>
                        <label
                          className="section"
                          style={{
                            paddingBottom: 10,
                            paddingTop: 10,
                            fontSize: 15,
                            fontWeight: "bold",
                          }}
                        >
                          SECTION ONE: Overview
                        </label>
                        <div className="underline"></div>
                      </div>

                      <Row>
                        <Col md={4}>
                          <label style={{ height: 35 }}>
                            What policies and/or legal frameworks are in place
                            in the sector?
                          </label>
                          <InputForm
                            id="exampleSelect"
                            value={form.legal_frameworks_sector}
                            name="legal_frameworks_sector"
                            onChange={handleChange}
                          />
                        </Col>
                        <Col md={4}>
                          <label style={{ height: 35 }}>
                            Who are the key stakeholders in the sector? (MDAs,
                            Donor agencies & development partners,
                            non-governmental organisations etc.)
                          </label>
                          <InputForm
                            id="exampleSelect"
                            value={form.stakeholders_sector}
                            name="stakeholders_sector"
                            onChange={handleChange}
                          />
                        </Col>
                      </Row>

                      {/* SECTION TWO: Outcome Performance */}

                      <div
                        style={{
                          display: "flex",
                          marginTop: 20,
                          marginBottom: 20,
                        }}
                      >
                        <div className="underline"></div>
                        <label
                          className="section"
                          style={{
                            paddingBottom: 10,
                            paddingTop: 10,
                            fontSize: 15,
                            fontWeight: "bold",
                          }}
                        >
                          SECTION TWO: Outcome Performance
                        </label>
                        <div className="underline"></div>
                      </div>

                      <Col md={4}>
                        <label style={{ height: 35 }}>
                          What is the maternal mortality per 100,000 live births
                          within the period?
                        </label>
                        <InputForm
                          id="exampleSelect"
                          value={form.major_crops}
                          name="major_crops"
                          placeholder="indicate timeframe"
                          onChange={handleChange}
                        />
                      </Col>
                      <Col md={4}>
                        <label style={{ height: 35 }}>
                          What percentage of delivery by skilled provider
                          (Skilled Birth Attendant (SBA))?
                        </label>
                        <InputForm
                          id="exampleSelect"
                          value={form.trained_agricultural_practices}
                          name="trained_agricultural_practices"
                          onChange={handleChange}
                        />
                      </Col>
                      <Col md={4}>
                        <label style={{ height: 35 }}>
                          What is the contraceptive prevalence rate (CPR) within
                          the period?
                        </label>
                        <InputForm
                          id="exampleSelect"
                          value={form.applying_agricultural_practices}
                          name="applying_agricultural_practices"
                          placeholder="*in percentage indicate timeframe"
                          onChange={handleChange}
                        />
                      </Col>
                      <Col md={4}>
                        <label style={{ height: 35 }}>
                          What is the routine immunization (RI) coverage rate?
                        </label>
                        <InputForm
                          id="exampleSelect"
                          value={form.trained_agricultural_skills}
                          name="trained_agricultural_skills"
                          placeholder="*in percentage"
                          onChange={handleChange}
                        />
                      </Col>
                      <Col md={4}>
                        <label style={{ height: 35 }}>
                          What is the rate of infant mortality per 1000 live
                          birth within the period?
                        </label>
                        <InputForm
                          id="exampleSelect"
                          value={form.applying_agricultural_skills}
                          name="applying_agricultural_skills"
                          onChange={handleChange}
                        />
                      </Col>
                      <Col md={4}>
                        <label style={{ height: 35 }}>
                          {" "}
                          What is the rate of under five mortality per 1000 live
                          birth within the period?
                        </label>
                        <InputForm
                          id="exampleSelect"
                          value={form.agricultural_contribution_percentage}
                          name="agricultural_contribution_percentage"
                          onChange={handleChange}
                        />
                      </Col>
                      <Col md={4}>
                        <label style={{ height: 35 }}>
                          What is the prevalence rate of malaria in children
                          under-five?
                        </label>
                        <InputForm
                          id="exampleSelect"
                          value={form.farmers_adopted_technology}
                          name="farmers_adopted_technology"
                          placeholder="*in percentage"
                          onChange={handleChange}
                        />
                      </Col>
                      <Col md={4}>
                        <label style={{ height: 35 }}>
                          What percentage of Wasting within the period (2
                          standard deviation (2SD))?
                        </label>
                        <InputForm
                          id="exampleSelect"
                          value={form.farmers_adopted_technology}
                          name="farmers_adopted_technology"
                          placeholder="*in percentage"
                          onChange={handleChange}
                        />
                      </Col>
                      <Col md={4}>
                        <label style={{ height: 35 }}>
                          What percentage of Wasting within the period (3
                          standard deviation (3SD))?
                        </label>
                        <InputForm
                          id="exampleSelect"
                          value={form.farmers_adopted_technology}
                          name="farmers_adopted_technology"
                          placeholder="*in percentage"
                          onChange={handleChange}
                        />
                      </Col>
                      <Col md={4}>
                        <label style={{ height: 35 }}>
                          What percentage of Stunting within the period (2
                          standard deviation (2SD))?
                        </label>
                        <InputForm
                          id="exampleSelect"
                          value={form.farmers_adopted_technology}
                          name="farmers_adopted_technology"
                          placeholder="*in percentage"
                          onChange={handleChange}
                        />
                      </Col>
                      <Col md={4}>
                        <label style={{ height: 35 }}>
                          What percentage of Stunting within the period (3
                          standard deviation (3SD))?
                        </label>
                        <InputForm
                          id="exampleSelect"
                          value={form.farmers_adopted_technology}
                          name="farmers_adopted_technology"
                          placeholder="*in percentage"
                          onChange={handleChange}
                        />
                      </Col>
                      <Col md={4}>
                        <label style={{ height: 35 }}>
                          What is the proportion of tuberculosis cases detected
                          and cured under directly observed treatment short
                          courses?
                        </label>
                        <InputForm
                          id="exampleSelect"
                          value={form.farmers_adopted_technology}
                          name="farmers_adopted_technology"
                          placeholder="*in percentage"
                          onChange={handleChange}
                        />
                      </Col>
                      <Col md={4}>
                        <label style={{ height: 35 }}>
                          What is the percentage of population covered by any
                          risk protection mechanism?
                        </label>
                        <InputForm
                          id="exampleSelect"
                          value={form.farmers_adopted_technology}
                          name="farmers_adopted_technology"
                          placeholder="*in percentage"
                          onChange={handleChange}
                        />
                      </Col>
                      <Col md={4}>
                        <label style={{ height: 35 }}>
                          What is the rate of health workers attrition within
                          the period?
                        </label>
                        <InputForm
                          id="exampleSelect"
                          value={form.farmers_adopted_technology}
                          name="farmers_adopted_technology"
                          placeholder="*in percentage"
                          onChange={handleChange}
                        />
                      </Col>

                      {/* First Project Location */}

                      <Col md={4}>
                        <label style={{ height: 35 }}>
                          Please provide picture(s) evidence of Projects/
                          Programmes/ Interventions started, ongoing or
                          completed within the period.
                        </label>
                        <input
                          type="file"
                          style={{
                            border: "1px solid #000",
                            padding: 6,
                            width: "100%",
                          }}
                        />
                      </Col>
                      <Row className="mb-3">
                        <label
                          style={{
                            paddingBottom: 12,
                            paddingTop: 12,
                            fontSize: 13,
                          }}
                        >
                          Please provide the location of the project/ programme/
                          intervention.
                        </label>
                        <Col md={3}>
                          <InputForm
                            id="exampleSelect"
                            value={form.first_Latitude}
                            name="first_Latitude"
                            placeholder="Latitude (x.y °)"
                            onChange={handleChange}
                          />
                        </Col>
                        <Col md={3}>
                          <InputForm
                            id="exampleSelect"
                            value={form.first_Longitude}
                            name="first_Longitude"
                            placeholder="Longitude (x.y °)"
                            onChange={handleChange}
                          />
                        </Col>
                        <Col md={3}>
                          <InputForm
                            id="exampleSelect"
                            value={form.first_altitude}
                            name="first_altitude"
                            placeholder="altitude (m)"
                            onChange={handleChange}
                          />
                        </Col>
                        <Col md={3}>
                          <InputForm
                            id="exampleSelect"
                            value={form.first_accuracy}
                            name="first_accuracy"
                            placeholder="accuracy (m)"
                            onChange={handleChange}
                          />
                        </Col>
                      </Row>

                      {/* Second Project Location */}

                      <Col md={4}>
                        <label style={{ height: 35 }}>
                          Please provide picture(s) evidence of Projects/
                          Programmes/ Interventions started, ongoing or
                          completed within the period.
                        </label>
                        <input
                          type="file"
                          style={{
                            border: "1px solid #000",
                            padding: 6,
                            width: "100%",
                          }}
                        />
                      </Col>
                      <Row>
                        <label
                          style={{
                            paddingBottom: 12,
                            paddingTop: 12,
                            fontSize: 13,
                          }}
                        >
                          Please provide the location of the project/ programme/
                          intervention.
                        </label>
                        <Col md={3}>
                          <InputForm
                            id="exampleSelect"
                            value={form.second_Latitude}
                            name="second_Latitude"
                            placeholder="Latitude (x.y °)"
                            onChange={handleChange}
                          />
                        </Col>
                        <Col md={3}>
                          <InputForm
                            id="exampleSelect"
                            value={form.second_Longitude}
                            name="second_Longitude"
                            placeholder="Longitude (x.y °)"
                            onChange={handleChange}
                          />
                        </Col>
                        <Col md={3}>
                          <InputForm
                            id="exampleSelect"
                            value={form.second_altitude}
                            name="second_altitude"
                            placeholder="altitude (m)"
                            onChange={handleChange}
                          />
                        </Col>
                        <Col md={3}>
                          <InputForm
                            id="exampleSelect"
                            value={form.second_accuracy}
                            name="second_accuracy"
                            placeholder="accuracy (m)"
                            onChange={handleChange}
                          />
                        </Col>
                      </Row>

                      {/* Third Project Location */}

                      <Col md={4}>
                        <label style={{ height: 35 }}>
                          Please provide picture(s) evidence of Projects/
                          Programmes/ Interventions started, ongoing or
                          completed within the period.
                        </label>
                        <input
                          type="file"
                          style={{
                            border: "1px solid #000",
                            padding: 6,
                            width: "100%",
                          }}
                        />
                      </Col>
                      <Row>
                        <label
                          style={{
                            paddingBottom: 12,
                            paddingTop: 12,
                            fontSize: 13,
                          }}
                        >
                          Please provide the location of the project/ programme/
                          intervention.
                        </label>
                        <Col md={3}>
                          <InputForm
                            id="exampleSelect"
                            value={form.third_Latitude}
                            name="third_Latitude"
                            placeholder="Latitude (x.y °)"
                            onChange={handleChange}
                          />
                        </Col>
                        <Col md={3}>
                          <InputForm
                            id="exampleSelect"
                            value={form.third_Longitude}
                            name="third_Longitude"
                            placeholder="Longitude (x.y °)"
                            onChange={handleChange}
                          />
                        </Col>
                        <Col md={3}>
                          <InputForm
                            id="exampleSelect"
                            value={form.third_altitude}
                            name="third_altitude"
                            placeholder="altitude (m)"
                            onChange={handleChange}
                          />
                        </Col>
                        <Col md={3}>
                          <InputForm
                            id="exampleSelect"
                            value={form.third_accuracy}
                            name="third_accuracy"
                            placeholder="accuracy (m)"
                            onChange={handleChange}
                          />
                        </Col>
                      </Row>

                      {/* SECTION THREE: Financial Performance */}

                      <div
                        style={{
                          display: "flex",
                          marginTop: 20,
                          marginBottom: 20,
                        }}
                      >
                        <div className="underline"></div>
                        <label
                          className="section"
                          style={{
                            paddingBottom: 10,
                            paddingTop: 10,
                            fontSize: 15,
                            fontWeight: "bold",
                          }}
                        >
                          SECTION THREE: Financial Performance
                        </label>
                        <div className="underline"></div>
                      </div>

                      <Col md={4}>
                        <label style={{ height: 35 }}>
                          What is the sector(s) actual expenditure for the
                          quarter/year (indicate the timeframe)
                        </label>
                        <InputForm
                          id="exampleSelect"
                          value={form.actual_expenditure}
                          name="actual_expenditure"
                          onChange={handleChange}
                        />
                      </Col>
                      <Col md={4}>
                        <label style={{ height: 35 }}>
                          What is the actual personnel expenditure for the
                          quarter/year? (indicate timeframe)
                        </label>
                        <InputForm
                          id="exampleSelect"
                          value={form.personnel_expenditure}
                          name="personnel_expenditure"
                          onChange={handleChange}
                        />
                      </Col>
                      <Col md={4}>
                        <label style={{ height: 35 }}>
                          What is the actual overhead expenditure for the
                          quarter/year? (indicate timeframe)
                        </label>
                        <InputForm
                          id="exampleSelect"
                          value={form.overhead_expenditure}
                          name="overhead_expenditure"
                          onChange={handleChange}
                        />
                      </Col>
                      <Col md={4}>
                        <label style={{ height: 35 }}>
                          {" "}
                          What is the actual capital expenditure for the
                          quarter/year? (indicate timeframe)
                        </label>
                        <InputForm
                          id="exampleSelect"
                          value={form.capital_expenditure}
                          name="capital_expenditure"
                          onChange={handleChange}
                        />
                      </Col>

                      {/* SECTION FOUR: Other Performance Indicators */}

                      <div
                        style={{
                          display: "flex",
                          marginTop: 20,
                          marginBottom: 20,
                        }}
                      >
                        <div className="underline"></div>
                        <label
                          className="section"
                          style={{
                            paddingBottom: 10,
                            paddingTop: 10,
                            fontSize: 15,
                            fontWeight: "bold",
                          }}
                        >
                          SECTION FOUR: Other Performance Indicators
                        </label>
                        <div className="underline"></div>
                      </div>

                      <Col md={4}>
                        <label style={{ height: 35 }}>
                          Are there adequate manpower in the sector? Are there
                          any requirements for manpower?
                        </label>
                        <InputForm
                          id="exampleSelect"
                          value={form.adequate_manpower}
                          name="adequate_manpower"
                          onChange={handleChange}
                        />
                      </Col>
                      <Col md={4}>
                        <label style={{ height: 35 }}>
                          Are there adequate infrastructure and facilities? How
                          many more have been developed within the period under
                          review?
                        </label>
                        <InputForm
                          id="exampleSelect"
                          value={form.adequate_infrastructure_facilities}
                          name="adequate_infrastructure_facilities"
                          onChange={handleChange}
                        />
                      </Col>
                      <Col md={4}>
                        <label style={{ height: 35 }}>
                          {" "}
                          Is there any linkage with other sectors? How?
                        </label>
                        <InputForm
                          id="exampleSelect"
                          value={form.linkage_with_other_sectors}
                          name="linkage_with_other_sectors"
                          onChange={handleChange}
                        />
                      </Col>
                      <Col md={4}>
                        <label style={{ height: 35 }}>
                          Give a brief summary of the type of support received
                          from development partners and/or non-governmental
                          organisations.
                        </label>
                        <InputForm
                          id="exampleSelect"
                          value={form.non_governmental_organisations}
                          name="non_governmental_organisations"
                          onChange={handleChange}
                        />
                      </Col>
                      <Col md={4}>
                        <label style={{ height: 35 }}>
                          Going by the above responses, kindly provide
                          suggestions for improvements.
                        </label>
                        <InputForm
                          id="exampleSelect"
                          value={form.suggestions_for_improvements}
                          name="suggestions_for_improvements"
                          onChange={handleChange}
                        />
                      </Col>
                      <Row>
                        <Col md={4}>
                          <label>Name of M&E Staff</label>
                          <InputForm
                            id="exampleSelect"
                            value={form.name_of_m_and_staff}
                            name="name_of_m_and_staff"
                            onChange={handleChange}
                          />
                        </Col>
                      </Row>
                      </Row>
                     
                    <div>
                      <center>
                        <Button
                        color="primary"
                        className="px-5"
                        style={{ float: "right" }}
                          onClick={handleSubmit()}
                        >
                          Submit
                        </Button>
                      </center>
                    </div>
                    </MDBox>
                </MDBox>
                  )}
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </div>
  );
}
