import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import bgImage from "assets/images/kano.png";
import { login } from "redux/action/auth";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
// import { bg } from "redux/action/api";

function Basic() {
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [form, setForm] = useState({
    username: "",
    password: "",
  });
  const authError = useSelector((state) => state.auth.error);

  const handleChange = ({ target: { name, value } }) => {
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const history = useNavigate();
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const handleLogin = (e) => {
    setLoading(true);
    e.preventDefault();
    dispatch(
      login({ ...form }, (data) => {
        if (data.success) {
          addToast("Login Successfully", {
            appearance: "info",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
          history(`/dashboard`);
          // location = `/dashboard`;
          setLoading(false);
          // console.log(data, "KKDKDKDDK");
        } else {
          setLoading(false);
          // console.log(data, "PPPPPPPPPPPPPP ERRRRRR");
          addToast(data.msg, {
            appearance: "error",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
        }
      }),
      (err) => {
        setLoading(false);
        // console.log(err, ":ERROR");
        addToast(err.msg, {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
      }
    );
  };
  let errorBoolen = authError === Object(authError) ? false : true;
  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
          <Grid
            container
            spacing={3}
            justifyContent="center"
            sx={{ mt: 1, mb: 2 }}
          >
            <Grid item xs={2}>
              <MDTypography
                component={MuiLink}
                href="#"
                variant="body1"
                color="white"
              >
                {/* <FacebookIcon color="inherit" /> */}
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography
                component={MuiLink}
                href="#"
                variant="body1"
                color="white"
              >
                {/* <GitHubIcon color="inherit" /> */}
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography
                component={MuiLink}
                href="#"
                variant="body1"
                color="white"
              >
                {/* <GoogleIcon color="inherit" /> */}
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            {/* {JSON.stringify(form)} */}
            <MDBox mb={2}>
              <MDInput
                type="email"
                name="username"
                value={form.username}
                onChange={handleChange}
                label="Email"
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                name="password"
                value={form.password}
                onChange={handleChange}
                label="Password"
                fullWidth
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
            {/* {JSON.stringify(errorBoolen)} */}
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color={errorBoolen ? "error" : "success"}
                onClick={handleLogin}
                fullWidth
              >
                {loading && !errorBoolen ? (
                  <span className="spinner-border text-white"></span>
                ) : (
                  "Sign in"
                )}
              </MDButton>
            </MDBox>
            <center>
              <MDTypography variant="error" fontWeight="regular" color="error">
                {authError === Object(authError) ? "" : authError}
              </MDTypography>
            </center>

            {/* <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox> */}
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
