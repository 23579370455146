import React, { useState, useEffect, useCallback } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Button, Grid } from "@mui/material";
import { _postApi, _fetchApi } from "redux/action/api";
import { formatNumber } from "redux/action/api";
import { getSector } from "helper";
// import CustomTypeahead from "components/CustomTypeahead";
import { year } from "helper";
import { useToasts } from "react-toast-notifications";
import { useSelector } from "react-redux";
import { Col, InputGroup, InputGroupText, Spinner } from "reactstrap";
import { useYear } from "useYearList";
import { Input, Label, Modal, ModalFooter, ModalHeader } from "reactstrap";
import PreviousCeiling from "./PreviousCeiling";
import CustomTypeahead from "components/CustomTypeahead";

export default function SectorBudgetCeiling() {
  const user = useSelector((state) => state.auth.user.username);

  const [data, setData] = useState([]);
  const [getTotal, setGetTotal] = useState([]);
  const [form, setForm] = useState({
    description: "Administrative Segment",
    year,
    totalAmt: 0,
    head: "01000000000000000000",
    totalAmount: "",
  });
  const [percentage] = useState(true);
  const [loading, setLoading] = useState(false);
  const [fetchloading, setFetchLoading] = useState(false);
  const [isOpen, setIsopen] = useState(false);
  const toggle = () => setIsopen(!isOpen);
  const years = useYear();

  const handleChange = ({ target: { name, value } }) =>
    setForm((p) => ({ ...p, [name]: value }));

  const getCellingData = useCallback(() => {
    setFetchLoading(true);
    _postApi(
      `/budgetCeiling?query_type=select_ceiling`,
      { status: "sector", year: form.year, head: form.head },
      (data) => {
        if (data.results?.length) {
          setData(data.results);
          setGetTotal(data.results);
          setFetchLoading(false);
        } else {
          getSector({ sub_head: form.head }, "mdas_approved1", (d) => {
            // Change the status of each object in the array to 'sector'
            const newData = d.map((item) => ({
              ...item,
              status: "sector",
              amount: 0,
              total_amt: 0,
            }));
            setData(newData);
            setFetchLoading(false);
          });
        }
      },
      (err) => {
        console.log(err);
        setFetchLoading(false);
      }
    );
  }, [form.year, form.type, form.head]);

  useEffect(() => {
    // getHeads();
    getCellingData();
  }, [getCellingData]);

  const total = data?.reduce((a, b) => a + parseFloat(b.amt || 0), 0);
  const totalVal = data?.reduce((a, b) => a + parseFloat(b.total_amt || 0), 0);
  const items = data?.length && data[0];
  const checkStatus = items?.req_status === "approved";
  let query_type = getTotal?.length ? "update" : "insert";
  const _total =
    getTotal?.reduce((a, b) => a + parseFloat(b.total_amt), 0) || "";

  useEffect(() => {
    if (percentage && total > 100) {
      alert("Must not grater than 100%");
    }
  }, [percentage, total]);

  const pvalue = percentage ? "Percentage" : "Value";

  const handleTableChange = (value, index) => {
    let newArr = [];
    // const parsedValue = parseFloat(value);
    // const remainingAmount = totalAmount - percentage ? parsedValue <= 100 : 0;

    data?.forEach((item, i) => {
      if (i === index) {
        newArr.push({
          ...item,
          amt: value,
          total_amt:
            (parseFloat(value || item.amt) / 100) *
            parseFloat(form.totalAmount || _total),
        });
      } else {
        newArr.push(item);
      }
    });
    setData(newArr);
  };
  const { addToast } = useToasts();

  const handleSubmit = (req_status) => {
    setLoading(true);
    if (percentage && total > 100) {
      alert("Total percentage cannot exceed 100%");
      setLoading(false);
      return;
    } else if (parseFloat(totalVal) < parseFloat(form.totalAmount || _total)) {
      alert("Total amount is less than ceiling provided");
      setLoading(false);
    } else {
      _postApi(
        `/insert-budgetCeiling?query_type=${query_type}`,
        {
          data,
          pvalue,
          req_status,
          created_by: user,
          updated_by: query_type === "insert" ? null : user,
          year: form.year,
        },
        (data) => {
          console.log(data);
          addToast("Submit successfully", {
            appearance: "info",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
          getCellingData();
          setLoading(false);
          setData([]);
        },
        (err) => {
          console.log(err);
          setLoading(false);
        }
      );
    }
  };

  let newData = [
    { name: "Adminstrative Sector", code: "01%", head: "010000000000" },
    { name: "Economic Sector", code: "02%", head: "020000000000" },
    { name: "Social Sector", code: "05%", head: "030000000000" },
    { name: "Law And Justice", code: "03%", head: "050000000000" },
  ];
  return (
    <div>
      <DashboardLayout>
        {/* {JSON.stringify(query_type)} */}
        <Modal isOpen={isOpen} size="lg">
          <ModalHeader toggle={toggle}>Previous Year Ceilling</ModalHeader>
          <PreviousCeiling year={form.year} />
          <ModalFooter>
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <MDBox pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12} p={3}>
              <Card className="p-3">
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h5" color="white">
                    Main Org Ceiling
                  </MDTypography>
                </MDBox>
                {/* {JSON.stringify(form)} */}

                <MDBox pt={3}>
                  <div className="row">
                    {/* <div className="col-md-4">
                      <CustomTypeahead
                        labelKey="name"
                        label="Select Sector"
                        options={newData}
                        onChange={(s) => {
                          if (s.length) {
                            console.log(s);
                            setForm((p) => ({
                              ...p,
                              description: s[0]?.description,
                              sector_type: s[0]?.code,
                              head: s[0]?.head,
                            }));
                          }
                        }}
                      />
                    </div> */}
                    <div className="col-md-4">
                      <label htmlFor="totalAmount">
                        Total Amount: ₦
                        {formatNumber(form.totalAmount || _total)}
                      </label>
                      <input
                        type="number"
                        name="totalAmount"
                        className="form-control"
                        value={form.totalAmount || _total}
                        onChange={handleChange}
                        style={{ width: "100%" }}
                        disabled={!checkStatus ? false : true}
                      />
                    </div>
                    <div className="col-md-4 ">
                      <Label>Select year</Label>
                      <Input
                        type="select"
                        name="year"
                        value={form.year}
                        onChange={handleChange}
                      >
                        <option>--select--</option>
                        {years?.map((item) => (
                          <option key={item}>{item}</option>
                        ))}
                      </Input>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <small className="text-primary">
                      <b
                        style={{ cursor: "pointer", marginTop: 10 }}
                        onClick={toggle}
                      >
                        View previous year ceiling
                      </b>
                    </small>
                    <div className="p-2" style={{ float: "right" }}>
                      Total Ceilling{" "}
                      <b>₦{formatNumber(form.totalAmount || _total)}</b>
                    </div>
                  </div>
                  {fetchloading && <Spinner />}
                  <table className="table table-border table-responsive">
                    <thead>
                      <tr>
                        <th>Description</th>
                        <th>Ceiling ({total || 0}%)</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.map((row, index) => (
                        <>
                          <tr key={index}>
                            <td>{row.description?.toUpperCase()}</td>
                            <td
                              style={{
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Col md={4}>
                                <InputGroup>
                                  <Input
                                    // type="number"
                                    maxLength={2}
                                    className="form-control text-center"
                                    value={row.amt || ""}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      handleTableChange(value, index);
                                    }}
                                    disabled={!checkStatus ? false : true}
                                  />
                                  <InputGroupText>%</InputGroupText>
                                </InputGroup>
                              </Col>
                              {/* <input
                                type="number"
                                maxLength={2}
                                className="form-control text-center"
                                value={row.amt || ""}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  handleTableChange(value, index);
                                }}
                                disabled={!checkStatus ? false : true}
                              /> */}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              ₦ {formatNumber(row.total_amt)}
                            </td>
                          </tr>
                        </>
                      ))}
                      <tr>
                        <th>Total</th>
                        <th>
                          ₦{total || 0}
                          {percentage && "%"}
                        </th>
                        <th style={{ textAlign: "right" }}>
                          ₦ {formatNumber(totalVal)}
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </MDBox>
                {!checkStatus && (
                  <center>
                    <button
                      className="btn btn-primary pr-5 pl-5 m-2"
                      onClick={() => handleSubmit("approved")}
                    >
                      {fetchloading ? <Spinner /> : "Submit now"}
                    </button>
                    <button
                      className="btn btn-warning pr-5 pl-5 m-2"
                      onClick={() => handleSubmit("draft")}
                    >
                      {fetchloading ? <Spinner /> : "Save As Draft"}
                    </button>
                  </center>
                )}
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </div>
  );
}
