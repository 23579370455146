import ReportsAdminShareCom from "./ReportsAdminShareCom";
const listdata = [
  { value: "total_revenue_by_dep", title: "Total Revenue by Department" },
  {
    value: "revenue_by_dep_new",
    title: "Recurrent Revenue by Department",code: 'Revenue'
  },
  { value: "revenue_by_dep_new", title: "Capital Receipt by Department", code: 'Capital Receipt' },
];
function ExpensesByDepartment() {
  return (
    <ReportsAdminShareCom
      type="total_revenue_by_dep"
      route="/reports"
      options={listdata}
      title="Total Revenue by Department"
    />
  );
}

export default ExpensesByDepartment;
