import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Grid } from "@mui/material";

import { getSector } from "helper";
import { BsTrash } from "react-icons/bs";
import CustomTypeahead from "components/CustomTypeahead";

import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { _fetchApi } from "redux/action/api";
import { _postApi } from "redux/action/api";
import { useQuery } from "redux/action/api";
import { ToastProvider, useToasts } from "react-toast-notifications";

function SegmentForm() {
  const navigate = useNavigate();
  const params = useParams();
  const query = useQuery();
  const getHead = query.get("code");
  const gettype = query.get("type");
  const [results, setResults] = useState([]);
  const [inputs, setInputs] = useState({
    head: "",
    description: "",
    remarks: "",
    type: gettype,
  });
  let id = params.id;
  let submit = id ? "Update" : "Submit";
  // const [form,setForm] = useState()
  const getIds = useCallback(() => {
    _fetchApi(
      `/getId/${id}`,
      (data) => {
        // if (data.success) {

        setResults(data.results);
        console.log({ form: data.results[0] });
        setInputs(data.results[0][0]);
        // }
      },
      (err) => console.log(err)
    );
  }, [params]);
  useEffect(() => {
    getIds();
  }, [getIds]);

  useEffect(() => {
    _fetchApi(
      `/getNumber?id=2`,
      (data) => {
        // if (data.success) {
        // setResults(data.results);
        if (submit === "Submit") {
          let news = data.results[0][0].code;
          setInputs((p) => ({ ...p, head: news, sub_head: getHead }));
          console.log({ form: data.results[0] });
        }

        // setInputs(data.results[0][0]);
        // }
      },
      (err) => console.log(err)
    );
  }, [getHead]);

  const handleChange = ({ target: { name, value } }) => {
    setInputs((p) => ({ ...p, [name]: value }));
  };
  const { addToast } = useToasts();
  let type = id ? "update" : "insert";
  let heads = inputs?.head;
  const handleSubmit = () => {
    console.log(inputs);
    let query_type = type;
    if (
      inputs.head === "" ||
      inputs.description === "" ||
      inputs.remarks === ""
    ) {
      // alert("complate form");
      addToast("complate form", {
        appearance: "info",
        autoDismiss: true,
        autoDismissTimeout: 5000,
      });
    } else {
      _postApi(
        `/account?query_type=${type}&head=${heads}`,
        inputs,
        (data) => {
          if (data.success) {
            addToast("Saved Successfully", {
              appearance: "info",
              autoDismiss: true,
              autoDismissTimeout: 5000,
            });
            navigate(-1);
          }
        },
        (err) => console.log(err)
      );
    }
  };

  return (
    <DashboardLayout>
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={3}
              >
                <MDBox>
                  <MDTypography variant="h6" gutterBottom>
                    SegmentForm
                  </MDTypography>
                </MDBox>
                <MDBox color="text" px={2}></MDBox>
              </MDBox>
              <MDBox>
                <MDBox pt={3}>
                  <div className=" row mb-4 p-3">
                    {/* {JSON.stringify(inputs)} */}
                    <div className="col-md-6">
                      <lable>CODE</lable>
                      <input
                        className="form-control"
                        type="number"
                        name="head"
                        value={inputs.head}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-6">
                      <lable>Description</lable>
                      <input
                        className="form-control"
                        name="description"
                        value={inputs.description}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-6">
                      <lable>Remarks</lable>
                      <input
                        className="form-control"
                        name="remarks"
                        value={inputs.remarks}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <center>
                    {" "}
                    <button
                      className="btn btn-primary py-3 mb-3"
                      onClick={handleSubmit}
                    >
                      {submit}
                    </button>
                  </center>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default SegmentForm;
