import React from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types"; // Import PropTypes
import { Button } from "reactstrap";
import { ArrowLeft } from "@mui/icons-material";

function BackButton({
  text = "Click to go back",
  size = "md",
  className = "",
}) {
  const navigate = useNavigate();

  return (
    <Button
      color="primary"
      size={size}
      className={`mb-1 ${className}`}
      onClick={() => navigate(-1)}
    >
      <span className="d-flex flex-direction-row align-items-center">
        <ArrowLeft className="mr-1" fontSize="small" />{" "}
        {/* Changed size to fontSize */}
        {text}
      </span>
    </Button>
  );
}

BackButton.propTypes = {
  text: PropTypes.string, // text prop should be a string
  size: PropTypes.oneOf(["sm", "md", "lg"]), // size prop should be one of these values
  className: PropTypes.string, // className prop should be a string
};

export default BackButton;
