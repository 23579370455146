import { Grid, selectClasses } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Input,
  Label,
  Row,
  Spinner,
  FormGroup,
  Alert,
} from "reactstrap";
import { useDropzone } from "react-dropzone";
import Attachment from "./Attachment";
import { useQuery } from "redux/action/api";
import { fetchApi, postApi } from "redux/action/api_tbeams";
import { apiURL } from "redux/action/api_tbeams";
import CapitalProject, { getDistinctObjects } from "./CapitalProject";
import WarningModal from "./WarningModal";
import { PDFViewer } from "@react-pdf/renderer";
import { formatNumber } from "redux/action/api";
import { useToasts } from "react-toast-notifications";
import SelectedDataTable from "./SelectedDataTable";
import { NewRelisePdf } from "./NewRelisePdf";
import { RealiseCollectionPdf } from "./RealiseCollectionPdf";

export default function NewReleaseCollection({}) {
  const query = useQuery();
  const mdacode = query.get("mda_code");
  const mdaRef = useRef();
  const economicRef = useRef();
  const navigate = useNavigate();
  const [treasuryAccount, setTreasuryAccount] = useState([]);
  const [mdaAccount, setMdaAccount] = useState([]);
  const [budgetSummary, setBudgetSummary] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showAmountWarningModal, setShowAmountWarningModal] = useState(false);
  const [submittingReserveRequest, setSubmittingReserveRequest] =
    useState(false);
  const [selectedBudget, setSelectedBudget] = useState({});

  const [economicCode, setEconomicCode] = useState([]);
  const [childCode, setChildCode] = useState([]);
  const economicTypeahead = useRef();
  const _id = query.get("id");
  const imageId = moment().format("YYYYMMDDhhmmss");
  const [showForm, setShowForm] = useState(true);

  const [form, setForm] = useState({
    collection_date: moment().format("YYYY-MM-DD"),
    approval_date: moment().format("YYYY-MM-DD"),
    mda_description: "",
    mda_budget_balance: "",
    mda_economic_code: "",
    approved_by: "HE",
    approved_amount: "",
    approval: "Approved",
    query_type: "insert",
    imageId,
    narration: "to facilitate the ",
    approval_no: "",
    approval_status: "Approval Initiated",
    add_remark: "",
    sent_to: {
      title: "",
      office: "",
    },
    mda_head: "",
    approval_type: "Recurrent",
    copies: {},
    copies1: {},
  });

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const { addToast } = useToasts();

  const getCCCopies = useCallback(() => {
    postApi(`post-cc?query_type=select_mda`, { mda_name: form.mda_name })
      .then((data) => {
        console.log("data", data);
        if (data.results) {
          setForm((p) => ({ ...p, copies: data.results[0] }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [form.mda_name]);

  const getCCCopies1 = useCallback(() => {
    postApi(`post-cc?query_type=select_mda`, { mda_name: form.mda_head })
      .then((data) => {
        console.log("data", data);
        if (data.results) {
          setForm((p) => ({ ...p, copies1: data.results[0] }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [form.mda_head]);

  useEffect(() => {
    getCCCopies();
    getCCCopies1();
  }, [getCCCopies, getCCCopies1]);

  const handleAddToList = () => {
    if (form.approved_amount === "") {
      addToast("Uncomplete form", {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 5000,
      });
    } else {
      setSelectedData((prevData) => [...prevData, form]);
      setShowTable(true);
      setForm({
        mda_description: "",
        mda_name: "",
        mda_budget_balance: "",
        mda_economic_code: "",
        narration: "to facilitate the",
        description: "",
        mda_code: "",
        approved_amount: "",
        approval_type: "Recurrent",
        approval_status: "Approval Initiated",
        add_remark: "",
        sent_to: {
          title: "",
          office: "",
        },
      });
      mdaRef?.current?.setState({ text: "" });
      economicRef?.current?.setState({ text: "" });
    }
  };

  const submitPlanningReserveRequest = () => {
    setSubmittingReserveRequest(true);
    setIsLoading(true);
    form.sent_to_mda = form.sent_to.office || "";
    form.sent_to_office = form.sent_to.title || "";
    form.query_type = "insert";
    (form.approval_status = "No Budget"),
      postApi(`post_approval_collection`, { form })
        .then((data) => {
          if (data.success) {
            console.log(data);
            handleApi1(data.approval_no);

            postApi(`planning-reservation`, {
              destMdaCode: selectedBudget.admin_code,
              destEconomicCode: selectedBudget.economic_code,
              destMdaDescription: selectedBudget.admin_description,
              destEconomicDescription: selectedBudget.economic_description,
              destFundCode: selectedBudget.fund_code,
              destFundDescription: selectedBudget.fund_description,
              destFunctionalCode: selectedBudget.function_code,
              destFunctionalDescription: selectedBudget.function_description,
              destProgramCode: selectedBudget.program_code,
              destProgramDescription: selectedBudget.program_description,
              destGeoCode: selectedBudget.geo_code,
              destGeoDescription: selectedBudget.geo_description,
              approvedAmount: form.approved_amount,
              query_type: "insert",
              reserveStatus: "Pending",
              approvalNo: data.approval_no,
            })
              .then((data) => {
                if (data.success) {
                  console.log(data);
                  addToast("submit success", {
                    appearance: "info",
                    autoDismiss: true,
                    autoDismissTimeout: 5000,
                  });
                  setShowForm(false);
                  navigate("/planning-reservation");
                }
              })
              .catch((err) => {
                addToast(err, {
                  appearance: "error",
                  autoDismiss: true,
                  autoDismissTimeout: 5000,
                });
              })
              .finally(() => {
                setSubmittingReserveRequest(false);
              });
            setShowForm(false);
          }
        })
        .catch((err) => {
          addToast(err, {
            appearance: "error",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
  };

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } =
    useDropzone({ accept: "image/*, .pdf, text/plain" });

  const [steps, setSteps] = useState("1");

  const getList = () => {
    fetchApi(`get-budget-summary?query_type=list`)
      .then((d) => {
        console.log("d", d);
        if (d.result) {
          setBudgetSummary(d.result);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    postApi(`select_mda_bank_details`, {
      query_type: "select",
    }).then((data) => {
      if (data.result) {
        console.log(data.result);

        const treasuryAccount = data.result.filter(
          (item) => item.account_type === "treasury_source_account"
        );
        setTreasuryAccount(treasuryAccount);

        const mdaAccount = data.result.filter(
          (item) => item.account_type === "mda_source_account"
        );
        setMdaAccount(mdaAccount);
      }
    });
  }, []);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (e.target.type === "checkbox") {
      setForm((p) => ({ ...p, [name]: value }));
    } else {
      setForm((p) => ({ ...p, [name]: value }));
    }
  };

  const getBudgetBalance = useCallback(
    (economic_code) => {
      postApi(`update_budget_balance`, {
        query_type: "select",
        mda_name: form.mda_name,
        mda_code: form.mda_code,
        amount: null,
        economic_code: economic_code,
        geo_code: form.geo_code,
        program_code: null,
        functional_code: form.functional_code,
      })
        .then((d) => {
          console.log("data", d);
          if (d.success) {
            const selectedItem = d.data[0];
            setForm((p) => ({
              ...p,
              description: selectedItem?.budget_description,
              mda_budget_balance:
                selectedItem?.mopb_balance == 0
                  ? "Budget Exausted"
                  : selectedItem?.mopb_balance,
              budget:
                selectedItem?.approve_amount == 0
                  ? "Budget Exausted"
                  : selectedItem?.approve_amount,
            }));
          }
        })
        .catch((err) => {
          // Handle errors
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [form.mda_code]
  );

  const handleEconomicCode = (selected) => {
    if (selected?.length) {
      console.log("selected budget", selected);
      const selectedItem = selected[0];
      setSelectedBudget(selected[0]);
      setForm((p) => ({
        ...p,
        mda_economic_code: selectedItem.economic_code,
        description: selectedItem.economic_description,
        mda_description: selectedItem.economic_description,
      }));
      getBudgetBalance(selectedItem.economic_code);
    } else {
      console.log("no item selected");
    }
  };

  const getList2 = useCallback((mda_code) => {
    fetchApi(
      `get-budget-summary?query_type=select_economic_code&&mda_code=${mda_code}`
    )
      .then((d) => {
        console.log(d);
        if (d.result) {
          console.log(d);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const getList5 = useCallback(() => {
    fetchApi(`get-budget-summary?query_type=recurrent`)
      .then((d) => {
        console.log(d);
        if (d.result) {
          console.log(d);
          setEconomicCode(d.result);
          economicTypeahead.current.focus();
        }
      })
      .catch((err) => console.log(err));
    mdaRef.current.focus();
  }, [mdacode]);

  useEffect(() => {
    getList5();
  }, [getList5]);

  const handleMdaCode = (selected) => {
    if (selected?.length) {
      console.log(selected);
      const selectedItem = selected[0];
      setForm((p) => ({
        ...p,
        mda_code: selectedItem.mda_code,
        mda_name: selectedItem.mda_name,
      }));

      getList2(selectedItem.mda_code);
    } else {
      console.log("no item selected");
    }
  };

  const handleMdaCode1 = (selected) => {
    if (selected?.length) {
      // console.log(selected);
      const selectedItem = selected[0];
      setForm((p) => ({
        ...p,
        mda_head_code: selectedItem.mda_code,
        mda_head: selectedItem.mda_name,
      }));

      // getList2(selectedItem.mda_code);
    } else {
      console.log("no item selected");
    }
  };

  // const ccCopies = [
  //   {
  //     title: "The Head of Civil Service of the State",
  //     office: "Governor's Office",
  //   },
  //   {
  //     title: "The Permanent Secretary",
  //     office: "Government House",
  //   },
  //   {
  //     title: "The Hon. Commissioner",
  //     office: "Ministry of Finance",
  //   },
  //   {
  //     title: "The Auditor General",
  //     office: "Office of the Auditor-General for the State",
  //   },
  //   {
  //     title: "The Director Main-Account",
  //     office: "Ministry of Finance",
  //   },
  // ];

  const handleApi = () => {
    let list = selectedData;
    if (
      parseFloat(form.approved_amount) > parseFloat(form.mda_budget_balance)
    ) {
      setShowAmountWarningModal(true);
    } else {
      if (form.approved_by === "") {
        addToast("Please Complete the form", {
          appearance: "info",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
      } else {
        setIsLoading(true);
        form.sent_to_mda = form.sent_to.office || "";
        form.sent_to_office = form.sent_to.title || "";
        form.query_type = "insert";
        form.approval_status = "Approval Initiated";
        postApi(`post_approval_collection`, { list, form })
          .then((data) => {
            if (data.success) {
              console.log(data);
              handleApi1(data.approval_no);
              const _newData = selectedData.map((i) => ({
                ...i,
                approval_no: data.approval_no,
              }));
              setSelectedData(_newData);
              addToast("submit success", {
                appearance: "info",
                autoDismiss: true,
                autoDismissTimeout: 5000,
              });
              setShowForm(false);
            }
          })
          .catch((err) => {
            alert(err);
            addToast(err, {
              appearance: "info",
              autoDismiss: true,
              autoDismissTimeout: 5000,
            });
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  };

  const unClearhandleApiRelease = () => {
    setSubmittingReserveRequest(true);
    if (
      form.mda_name === "" ||
      form.mda_budget_balance == "" ||
      form.mda_code == "" ||
      form.approved_by === ""
    ) {
      alert("Please Complete the form");
      setSubmittingReserveRequest(false);
    } else {
      setIsLoading(true);
      form.sent_to_mda = form.sent_to.office || "";
      form.sent_to_office = form.sent_to.title || "";
      form.query_type = "insert";
      form.approval_status = "Unclear Released";

      postApi(`post_approval_collection`, { form })
        .then((data) => {
          if (data.success) {
            console.log(data);
            handleApi1(data.approval_no);
            const _newData = selectedData.forEach((i) => ({
              ...i,
              approval_no: data.approval_no,
            }));
            setSelectedData(_newData);
            addToast("submit success", {
              appearance: "info",
              autoDismiss: true,
              autoDismissTimeout: 5000,
            });
            setShowForm(false);
            setSubmittingReserveRequest(false);
          }
        })
        .catch((err) => {
          alert(err);
          addToast(err, {
            appearance: "info",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
          setShowForm(false);
          setSubmittingReserveRequest(false);
        })
        .finally(() => {
          setIsLoading(false);
          setSubmittingReserveRequest(false);
        });
    }
  };

  const fetchMdaBankDetails = useCallback(() => {
    const query_type = "select";
    fetchApi(`select_mda_bank_details`, {
      query_type,
    })
      .then((data) => {
        console.log(data);
        setChildCode(data.result);
      })

      .catch((err) => {
        console.log(err);
      });

    console.log(childCode);
  });

  useEffect(() => {
    fetchMdaBankDetails();
  }, [fetchMdaBankDetails]);

  // const clearEconomicTypeahead = () => {
  //   economicTypeahead.current.clear();
  // };
  const handleApi1 = (approval_no) => {
    if (form.approved_amount && form.approved_amount !== "") {
      const formData = new FormData();
      form.approval_no = approval_no;
      for (let i = 0; i < acceptedFiles?.length; i++) {
        formData.append("files", acceptedFiles[i]);
      }

      if (!acceptedFiles?.length) {
        setForm((p) => ({ ...p, approval: "not approved" }));
        formData.append("form", JSON.stringify(form));

        fetch(`${apiURL}/post_images`, {
          method: "POST",

          body: formData,
        })
          .then((response) => response.json())
          .then((result) => {
            console.log(result);
          })

          .catch((err) => {
            console.log(err);
          });
      } else {
        formData.append("form", JSON.stringify(form));

        fetch(`${apiURL}/post_images`, {
          method: "POST",

          body: formData,
        })
          .then((response) => response.json())
          .then((result) => {
            console.log(result);
          })

          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  const getApprovalById = useCallback(() => {
    if (_id) {
      fetchApi(
        `get-approve-col?id=${_id}`,
        (res) => {
          if (res.success) {
            setForm({
              ...res.result[0],
              approved_amount: res.result[0].amount,
              query_type: "update_app",
            });
          }
        },
        (err) => {
          addToast(`error occured ${err}`, {
            appearance: "error",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
        }
      );
    }
  }, [_id]);

  useEffect(() => {
    getApprovalById();
  }, [getApprovalById]);

  return (
    <DashboardLayout>
      <MDBox pt={2} pb={3} pl={2}>
        <Grid container spacing={5}>
          {showForm ? (
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h5" color="white">
                    New Release Collection
                  </MDTypography>
                </MDBox>
                <Col
                  md={5}
                  style={{
                    marginLeft: 9,
                  }}
                  className="mt-4"
                >
                  <button
                    className="btn btn-primary"
                    style={{ marginRight: "10px", marginLeft: "10px" }}
                    onClick={() => navigate("/release-collection")}
                  >
                    Click here to go back
                  </button>
                </Col>
                {/* {JSON.stringify({ selectedData })} */}
                <Row
                  style={{
                    marginLeft: 8,
                    marginBottom: -20,
                    marginTop: 20,
                  }}
                ></Row>
                <Row className="mt-1 px-4 py-4">
                  <Col md={4}>
                    <Label>Realease Type</Label>
                    <Input
                      type="select"
                      value={steps}
                      onChange={({ target: { value } }) => {
                        setSteps(value);
                        if (value === "1") {
                          setForm((p) => ({
                            ...p,
                            approval_type: "Recurrent",
                          }));
                        } else if (value === "2") {
                          setForm((p) => ({ ...p, approval_type: "Capital" }));
                        }
                      }}
                    >
                      <option>--Select--</option>
                      <option value="1">Recurrent Project</option>
                      <option value="2">Capital Project</option>
                    </Input>
                  </Col>
                  {/* <Col md={4}>
                    <Label>Send to</Label>
                    <Input
                      type="select"
                      value={form.sent_to?.title}
                      name="sent_to"
                      onChange={({ target: { value } }) => {
                        const selectedVal = ccCopies.find(
                          (a) => a.title === value
                        );
                        setForm((prev) => ({
                          ...prev,
                          sent_to: selectedVal,
                        }));
                      }}
                    >
                      <option>--Select--</option>
                      {ccCopies.map((cc, i) => (
                        <option key={i} value={cc.title}>
                          {cc.title}
                        </option>
                      ))}
                    </Input>
                  </Col> */}
                  <Col md={4}>
                    <FormGroup
                      check
                      style={{ fontWeight: "bold", marginTop: 15 }}
                    >
                      <Label check>
                        Copy to Internal Revenue
                        <Input
                          id="checkbox2"
                          type="checkbox"
                          style={{ fontSize: 20 }}
                          name="copy_to"
                          value={form.copy_to}
                          onChange={handleChange}
                        />
                      </Label>
                    </FormGroup>
                  </Col>
                  {steps === "1" ? (
                    <>
                      <p>{selectedOptions.title}</p>
                      <Col md={4}>
                        <Label>Collection Date</Label>
                        <Input
                          type="date"
                          value={form.collection_date}
                          name="collection_date"
                          onChange={handleChange}
                        />
                      </Col>
                      <Col md={4}>
                        <Label>Approval Date</Label>
                        <Input
                          type="date"
                          value={form.approval_date}
                          name="approval_date"
                          onChange={handleChange}
                        />
                      </Col>
                      <Col md={4}>
                        <Label>Approved By</Label>
                        <Input
                          id="exampleSelect"
                          name="approved_by"
                          value={form.approved_by}
                          type="select"
                          onChange={handleChange}
                        >
                          <option>--Select--</option>
                          <option value="HE">HE</option>
                        </Input>
                      </Col>

                      <Col md={12}>
                        <Label>Narration</Label>
                        <Input
                          className="coloum"
                          type="textarea"
                          value={form.narration}
                          name="narration"
                          onChange={handleChange}
                          rows={5}
                        />
                      </Col>
                      <Col md={4}>
                        <Label>Select MDA</Label>
                        <Typeahead
                          size="sm"
                          id="20"
                          onChange={(selected) => handleMdaCode(selected)}
                          onInputChange={(text) => console.log(text)}
                          options={getDistinctObjects(
                            budgetSummary,
                            "mda_name",
                            "mda_code"
                          )}
                          labelKey={(option) => `${option.mda_name}`}
                          placeholder="Select---"
                          ref={mdaRef}
                        />
                      </Col>

                      <Col md={4}>
                        <Label>Administrative Code</Label>
                        <Input
                          type="number"
                          value={form.mda_code}
                          name="mda_code"
                          onChange={handleChange}
                          disabled
                        />
                      </Col>
                      <Col md={4}>
                        <Label>Select Head (if any)</Label>
                        <Typeahead
                          size="sm"
                          id="20"
                          onChange={(selected) => handleMdaCode1(selected)}
                          onInputChange={(text) => console.log(text)}
                          options={getDistinctObjects(
                            budgetSummary,
                            "mda_name",
                            "mda_code"
                          )}
                          labelKey={(option) => `${option.mda_name}`}
                          placeholder="Select---"
                          // ref={mdaRef}
                        />
                      </Col>
                      <Col md={4}>
                        <Label>Select Economic Code</Label>
                        <Typeahead
                          size="sm"
                          id="20"
                          onChange={(selected) => handleEconomicCode(selected)}
                          onInputChange={(text) => console.log(text)}
                          options={getDistinctObjects(
                            economicCode,
                            "economic_code",
                            "economic_description"
                          )}
                          labelKey={(option) => {
                            return `${option.economic_code} - ${option.economic_description}`;
                          }}
                          placeholder="Select---"
                          ref={economicRef}
                        />
                      </Col>
                      <Col md={4}>
                        <Label>Description</Label>
                        <Input
                          type="text"
                          value={form.description}
                          name="description"
                          onChange={handleChange}
                          disabled
                        />
                      </Col>
                      <Col md={4}>
                        <Label>Budget Balance</Label>
                        <Input
                          value={formatNumber(form.mda_budget_balance)}
                          name="mda_budget_balance"
                          onChange={handleChange}
                          disabled
                        />
                      </Col>
                      <Col md={4}>
                        <Label>Approved Budget Allocation</Label>
                        <Input
                          value={formatNumber(form.budget)}
                          name="budget"
                          onChange={handleChange}
                          disabled
                        />
                      </Col>
                      <Col md={4}>
                        <Label>
                          Approved Amount ({formatNumber(form.approved_amount)})
                        </Label>
                        <Input
                          type="number"
                          value={form.approved_amount}
                          name="approved_amount"
                          onChange={handleChange}
                        />
                      </Col>
                      {form.mda_code !== "" && form.mda_budget_balance === 0 ? (
                        <Alert color="primary" className="mt-2">
                          <center>
                            Please select the corresponding line item
                          </center>
                        </Alert>
                      ) : null}

                      <Col md={12} className="py-4 px-4">
                        <Attachment
                          acceptedFiles={acceptedFiles}
                          getRootProps={getRootProps}
                          getInputProps={getInputProps}
                          isDragActive={isDragActive}
                        />
                      </Col>
                      <center className="mb-3">
                        <Button color="success" onClick={handleAddToList}>
                          {isLoading ? (
                            <Spinner color="light" size="sm" />
                          ) : (
                            "Add to batch"
                          )}
                        </Button>
                      </center>
                      <SelectedDataTable
                        selectedData={selectedData}
                        showTable={showTable}
                      />

                      {selectedData?.length ? (
                        <Button
                          color="primary"
                          onClick={handleApi}
                          disabled={isLoading}
                          style={{
                            marginRight: 10,
                          }}
                        >
                          {isLoading ? (
                            <Spinner color="light" size="sm" />
                          ) : (
                            "Submit"
                          )}
                        </Button>
                      ) : null}
                    </>
                  ) : (
                    <CapitalProject generalForm={form} />
                  )}
                </Row>
                <WarningModal
                  showModal={showAmountWarningModal}
                  setShowModal={setShowAmountWarningModal}
                  handleSubmit={submitPlanningReserveRequest}
                  form={form}
                  loading={submittingReserveRequest}
                  handleApi={unClearhandleApiRelease}
                />
              </Card>
            </Grid>
          ) : (
            <>
              {form.approval_type === "Recurrent" && (
                <PDFViewer style={{ width: "100%", height: "100vh" }}>
                  {selectedData?.length === 1 ? (
                    <RealiseCollectionPdf
                      form={selectedData[0]}
                      selecOffer={formatNumber}
                    />
                  ) : (
                    <NewRelisePdf
                      selectedData={selectedData}
                      selecOffer={formatNumber}
                    />
                  )}
                </PDFViewer>
              )}
            </>
          )}
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
