import ReportExpByMDA from "./ReportExpByMDA";

function RevenueByMDA() {
  const listdata = [
    { value: "total_revenue_by_mda_new", title: "Total Revenue by MDA" },
  ];

  return (
    <ReportExpByMDA
      type="total_revenue_by_mda_new"
      route="/reports"
      options={listdata}
      title="Total Revenue by MDA"
      chartType="get_head_rev"
    />
  );
}

export default RevenueByMDA;
