// AUTHS
export const LOADING_SIGNUP = "LOADING_SIGNUP";
export const AUTH = "SET_USER";
export const LOADING_LOGIN = "LOADING_LOGIN";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGOUT = "_LOGOUT";
export const LOADING_APP = "___LOADING_APP";
export const ERRORS = "ERRORS";
export const ONE_USER = "ONE_USER";

// FINANCE (PAYMENTS)
export const PAYMENT_SCHEDULE = "Payment-Schedule";
export const PAYMENT_SCHEDULES = "Payment-Schedules";
export const BATCH_NO = "BATCH_NO-Schedules";
export const APPROVAL_DISPHATCH = "APPROVAL_DISPHATCH";

//budget_new TYPES
export const ADD_PAYMENT = "ADD_PAYMENT";
export const EMPTY_CART = "EMPTY_CART";
