/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import { formatNumber } from "redux/action/api";
import { CURRENCY } from "redux/constants";

export default function WarningModal({
  showModal,
  setShowModal,
  handleSubmit = (f) => f,
  form,
  loading = false,
  handleApi,
}) {
  const approvedAmount = form.approved_amount ? form.approved_amount : "";
  const budgetBal = form.mda_budget_balance ? form.mda_budget_balance : "";

  return (
    <div>
      <Modal isOpen={showModal} toggle={() => setShowModal((p) => !p)}>
        <ModalHeader className="text-danger text-center">Warning</ModalHeader>
        <ModalBody className="">
          The Approved Amount of (
          <b>
            {CURRENCY}
            {formatNumber(approvedAmount)}
          </b>
          ) is greater than the Budget Balance (
          <b>
            {CURRENCY}
            {formatNumber(form.mda_budget_balance)}
          </b>
          ).
        </ModalBody>
        <ModalFooter>
          <div>
            <Button
              color="danger"
              className="m-1 p-1 px-3"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </Button>
            <Button
              color="success"
              className="m-1 p-1 px-3"
              disabled={loading}
              onClick={handleApi}
            >
              {loading && <Spinner size={"sm"} className="me-2 mr-2" />}
              Continue
            </Button>

            <Button
              color="primary"
              className="m-1 p-1 px-3"
              disabled={loading}
              onClick={handleSubmit}
            >
              {loading && <Spinner size={"sm"} className="me-2 mr-2" />}
              Submit for AIE
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
}
