/* eslint-disable react/prop-types */
import { useCallback, useEffect, useRef, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  FormGroup,
  Label,
  Input,
  Col,
  Table,
  Alert,
} from "reactstrap";

import { _postApi } from "redux/action/api";
import { useToasts } from "react-toast-notifications";

function CommentModal({
  toggle,
  isOpen,
  data = 0,
  onSave = (f) => f,
  title,
  names,
  edit,
}) {
  const [form, setForm] = useState({});

  const handleChange = ({ target: { name, value } }) => {
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };
  //
  const { addToast } = useToasts();

  let datas = {
    status: names,
    budget_id: data?.id,
    comment: form.comment,
    query_type: "insert",
  };
  const handleSubmit = () => {
    _postApi(
      "/post-comment",
      datas,
      (res) => {
        if (res.success) {
          toggle();
          addToast("Commented successfully!", {
            appearance: "success",
            autoDismiss: true,
          });
          setForm({})
        }
      },
      (err) => {
        console.error(err);
      }
    );
  };

  const [comments, setComments] = useState();

  useEffect(() => {
    _postApi(
      "/post-comment",
      { query_type: "select", budget_id: data?.id },
      (res) => {
        if (res.success) {
          setComments(res.results);
        }
      },
      (err) => {
        console.error(err);
      }
    );
  }, [data?.id,toggle]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader
        style={{
          textAlign: "center",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <center>Comments</center>
      </ModalHeader>
      <ModalBody>
        {/* {JSON.stringify(comments)} */}
        {edit && edit === "edit" ? (
          <>
            <Table striped bordered>
              <thead>
                <tr>
                  <th>Comment</th>
                  <th>Commented By</th>
                </tr>
              </thead>
              <tbody>
                {comments?.map((item) => (
                  <tr key={item.id}>
                    <td>{item.comment}</td>
                    <td>{item.status}</td>
                  </tr>
                ))}
              </tbody>
            </Table>

            {comments?.length === 0 ? (
              <div className="container">
                <center>
                  {" "}
                  <Alert color="primary">
                    No Comments Available Right Now !!
                  </Alert>
                </center>
              </div>
            ) : null}
          </>
        ) : (
          <>
            <textarea
              className="form-control"
              placeholder="Write your comment"
              name="comment"
              value={form.comment}
              onChange={handleChange}
            />

            <center className="m-2 p-2">
              <button
                className="btn btn-primary pr-5 pl-5 ml-5"
                style={{ marginRight: "20px" }}
                onClick={handleSubmit}
              >
                Submit
              </button>

              <Button className="ml-5" color="danger" onClick={toggle}>
                Cancel
              </Button>
            </center>
          </>
        )}
      </ModalBody>
    </Modal>
  );
}

export default CommentModal;
