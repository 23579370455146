import { expiredSetting } from "examples/admin/helper";
import moment from "moment";
import React, { useEffect, useState } from "react";

export function getBudgetYear() {
  const [expired, setExpired] = useState([]);

  useEffect(() => {
    expiredSetting(
      { description: "Proposed Budget End Date" },
      "select",
      (d) => {
        setExpired(d.results);
      },
      (e) => {
        console.log(e);
      }
    );
  }, []);

  return expired;
}

export function getNextYear(cb) {
  expiredSetting(
    { description: "Proposed Budget End Date" },
    "select",
    (d) => {
      let val = d.results[0].end_date;
      cb(moment(val).format("YYYY"));
    },
    (e) => {
      console.log(e);
    }
  );
}
