/* eslint-disable react/prop-types */
import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export default function CheckBoxItem({ label, name, handleCheck, value = [] }) {
  return (
    <div style={{ marginLeft: "13px" }}>
      <FormControlLabel
        style={{ fontSize: "10px" }}
        control={
          <Checkbox
            checked={value.indexOf(label) !== -1}
            size="small"
            onChange={({ target }) => {
              handleCheck(name, target.checked, label);
              //   setChecked(target.checked);
            }}
          />
        }
        label={label}
      />
    </div>
  );
}
