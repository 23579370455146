import ReportExpByMDA from "./ReportExpByMDA";

function GeoReport() {
  const listdata = [
    { value: "total_ex_by_mda", title: "Total Expenditure by MDA" },
    {
      value: "ex_by_mda_new",
      title: "Personnel Exp. by MDA",
      code: "Personnel",
    },
    {
      value: "ex_by_mda_new",
      title: "Other NDR Exp. by MDA",
      code: "OverHead",
    },
    {
      value: "ex_by_mda",
      title: "Debt Service by MDA",
      code: "2206%",
    },
    {
      value: "ex_by_mda_new",
      title: "Capital Exp. by MDA",
      code: "Capital",
    },
  ];

  return (
    <ReportExpByMDA
      type="total_ex_by_mda"
      route="/reports"
      options={listdata}
      title="Total Expenditure by MDA"
      chartType="get_head_eco"
    />
  );
}

export default GeoReport;
