import React from "react";
import ReportBudgetShareCom from "./ReportBudgetShareCom";

export default function BudgetOverview() {
  const listdata = [{ value: "budget_overview", title: "Budget Overviews" }];

  return (
    <div>
      <ReportBudgetShareCom
        type="budget_overview"
        route="/reports"
        options={listdata}
      />
    </div>
  );
}
