import ReportsAdminShareCom from "./ReportsAdminShareCom";
const listdata = [
  { value: "total_revenue_by_admin", title: "Total Revenue by Admin" },
  {
    value: "revenue_admin_new",
    title: "Recurrent Revenue by Admin",
    code: "Revenue",
  },
  {
    value: "revenue_admin_new",
    title: "Capital Receipts by Admin",
    code: "Capital Receipt",
  },
];
function AdminQuaterly() {
  return (
    <ReportsAdminShareCom
      type="total_revenue_by_admin"
      route="/reports"
      options={listdata}
      title=" Total Revenue by Admin"
    />
  );
}

export default AdminQuaterly;
