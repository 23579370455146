/* eslint-disable react/prop-types */
import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import SearchBar from "components/SearchBar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import React, { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { IoMdCheckmark } from "react-icons/io";
import { MdOutlineCancel } from "react-icons/md";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import {
  Button,
  Card,
  Col,
  Input,
  Label,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import { weekAgo, today } from "redux/action/api";
import { formatNumber } from "redux/action/api";
import { postApi } from "redux/action/api_tbeams";

export default function ReleasesTable(props) {
  const {
    type,
    filterBy = "Approval Initiated",
    approval_status = "approval Reviewed",
    title = "Internal Control",
    query_type = "select_by_status",
    return_status,
    showButton = false,
  } = props;
  const navigate = useNavigate();
  const user = useSelector((e) => e.auth.user);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [budgetUtilization, setBudgetUtilization] = useState([]);
  const [editDropdownOpen, setEditDropdownOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null); // To store the index of the row where the dropdown is open
  const [data, setData] = useState({});
  const [modal, setModal] = useState(false);
  const [deleteRow, setDeleteRow] = useState(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    remark: "",
    reject: "",
    date_from: weekAgo,
    date_to: today,
  });
  const [searchQuery, setSearchQuery] = useState("");

  const { addToast } = useToasts();

  const toggleEditDropdown = (idx) => {
    setEditDropdownOpen((prevState) => !prevState);
    setSelectedRow(idx);
  };
  const toggle2 = () => setModal(!modal);

  const toggle1 = () => {
    setModal(!modal);
    setDeleteRow(!deleteRow);
    // returnRequest()
  };
  const handleChanges = ({ target: { name, value } }) =>
    setForm((p) => ({ ...p, [name]: value }));

  const location = useLocation();
  const isPending = location.pathname.includes("payapp");
  const [filter] = useState(type || "All");

  const getApprovalCollection = useCallback(() => {
    setIsLoading(true);
    postApi(`post_approval_collection`, {
      form: {
        query_type,
        filter,
        type: "release",
        approval_status: filterBy,
        date_from: form.date_from,
        date_to: form.date_to,
      },
    })
      .then((data) => {
        console.log("data", data);
        if (data.result) {
          const arr = data.result.map((item) => ({
            ...item,
            isOpen: false,
          }));
          setBudgetUtilization(arr);
        }
      })
      .catch((err) => {
        // Handle errors
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [filterBy, isPending, form.date_from, form.date_to]);

  useEffect(() => {
    getApprovalCollection();
  }, [getApprovalCollection]);

  const filteredData = budgetUtilization.filter(
    (item) =>
      item.mda_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.approval_no.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const getRowStyle = (row) => {
    switch (row.approval_status) {
      case "Approval Released":
        return { backgroundColor: "orange" };
      case "approval Reviewed Returned":
        return { backgroundColor: "yellow" };
      case "Approval Initiated Returned":
        return { backgroundColor: "yellow" };
      case "Approval Queried":
        return { backgroundColor: "yellow" };
      case "Payment Schedule":
        return { backgroundColor: "blue" };
      case "No Budget":
        return { backgroundColor: "#cc8888" };
      case "paid":
        return { backgroundColor: "green" };
      default:
        return { backgroundColor: "" };
    }
  };

  // const approveRequest = (status) => {
  //   // alert(JSON.stringify(data));
  //   setLoading(true);
  //   postApi(`update-approval`, {
  //     mda_name: data.mda_name,
  //     query_type: "update_status",
  //     approval_no: data.approval_no,
  //     status: status,
  //     remarks: form.remark,
  //     mopb_balance: data.amount,
  //     userId: user.id,
  //   })
  //     .then((data) => {
  //       console.log("data", data);
  //       if (data.success) {
  //         setModal(false);
  //         updateBalance();
  //         getApprovalCollection();
  //         addToast("Approve successfully", {
  //           appearance: "info",
  //           autoDismiss: true,
  //           autoDismissTimeout: 5000,
  //         });
  //       }
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       // Handle errors
  //       console.log(err);
  //       addToast("An error occurred", {
  //         appearance: "danger",
  //         autoDismiss: true,
  //         autoDismissTimeout: 5000,
  //       });
  //       setLoading(false);
  //     })
  //     .finally(() => {
  //       // setIsUpdating(false);
  //       setLoading(false);
  //     });
  // };

  let styles = {
    fontSize: 15,
  };

  const bodyStyle = (item) => {
    return { fontSize: 15, ...getRowStyle(item) };
  };

  return (
    <DashboardLayout>
      <MDBox pt={2} pb={3} pl={2}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h5" color="white">
                  {title}
                </MDTypography>
              </MDBox>
              {showButton && (
                <Col md={4} className="mt-4">
                  <button
                    className="btn btn-primary"
                    style={{ marginRight: "10px", marginLeft: "10px" }}
                    onClick={() => navigate("/new-realise-collection")}
                  >
                    Add Release Processing
                  </button>
                </Col>
              )}

              <Row
                style={{
                  margin: 10,
                }}
              >
                <Col md={6}>
                  <Label>Search From Table</Label>
                  <SearchBar
                    placeholder="Search By code or description"
                    filterText={searchQuery}
                    onFilterTextChange={(e) => setSearchQuery(e.target.value)}
                  />
                </Col>
                <Col md={3}>
                  <Label>Date From</Label>
                  <Input
                    type="date"
                    value={form.date_from}
                    name="date_from"
                    onChange={handleChanges}
                  />
                </Col>
                <Col md={3}>
                  <Label>Date To</Label>
                  <Input
                    type="date"
                    value={form.date_to}
                    name="date_to"
                    onChange={handleChanges}
                  />
                </Col>
              </Row>
              {/* {JSON.stringify(form)} */}
              <div
                className="mt-4"
                style={{
                  width: "100%",
                  height: 400,
                  overflowX: "scroll",
                }}
              >
                {/* {JSON.stringify({ user })} */}
                <Table style={{ width: "120%" }} bordered>
                  <thead>
                    <tr>
                      <th style={styles}>Action</th>
                      {/* <th style={styles}>Economic Code</th>*/}
                      <th style={styles}>Amount</th>
                      <th style={styles}>MDA Name</th>
                      <th style={styles}>Approval No.</th>
                      <th style={styles}>Collection Date</th>
                      <th style={styles}>Approval Date</th>
                      <th style={styles}>Approval Type</th>
                      <th style={styles}>Status</th>
                    </tr>
                  </thead>

                  {isLoading ? (
                    <Spinner color="primary" />
                  ) : (
                    <tbody>
                      {filteredData?.map((item, idx) => (
                        <tr key={idx}>
                          <td style={bodyStyle(item)}>
                            {item.approval_status === "No Budget" ? null : (
                              <Button
                                className="d-flex"
                                size="sm"
                                onClick={() => {
                                  navigate(
                                    `/view-release?approval_no=${item.approval_no}&approval_status=${approval_status}&return_status=${return_status}&showButton=${showButton}`
                                  );
                                }}
                              >
                                <IoMdCheckmark
                                  size="18px"
                                  style={{ marginRight: 6 }}
                                />
                                View
                              </Button>
                            )}
                          </td>
                          {/* <td
                            style={bodyStyle(item)}
                          >
                            {item.economic_code}
                          </td>*/}
                          <td
                            style={{
                              ...bodyStyle(item),
                              textAlign: "right",
                            }}
                          >
                            {formatNumber(item.amount)}
                          </td>
                          <td style={bodyStyle(item)}>{item.mda_name}</td>
                          <td style={bodyStyle(item)}>{item.approval_no}</td>
                          <td style={bodyStyle(item)}>
                            {item.collection_date}
                          </td>
                          <td style={bodyStyle(item)}>{item.approval_date}</td>
                          <td style={bodyStyle(item)}>{item.approved_by}</td>
                          <td style={bodyStyle(item)}>
                            {item.approval_status}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </Table>
              </div>
              {/* <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader>
                  <span className="text-center" style={{ width: "100%" }}>
                    <center>
                      {deleteRow ? (
                        <p
                          style={{
                            color: "red",
                            fontWeight: "500",
                          }}
                        >
                          Are You Sure You want to return
                        </p>
                      ) : (
                        <p
                          style={{
                            color: "#54AC58",
                            fontWeight: "500",
                          }}
                        >
                          Are You Sure You want to Approve this request?
                        </p>
                      )}
                    </center>
                  </span>
                </ModalHeader>
                <ModalBody>
                  <Col md={12}>
                    <Label> Add Remark</Label>
                    <Input
                      type="textarea"
                      value={form.remark}
                      name="remark"
                      onChange={handleChanges}
                      style={{
                        borderColor: deleteRow ? "red" : "#54AC58",
                        borderWidth: 2,
                      }}
                    />
                  </Col>
                </ModalBody>
                <ModalFooter className="d-flex justify-content-center">
                  <Button color="warning" variant="warning" onClick={toggle1}>
                    cancel
                  </Button>
                  {deleteRow ? (
                    <Button
                      variant="danger"
                      color="danger"
                      onClick={() => {
                        toggle1();
                        approveRequest(return_status);
                      }}
                    >
                      Return
                    </Button>
                  ) : (
                    <Button
                      onClick={() => approveRequest(approval_status)}
                      variant="primary"
                      color="primary"
                      disabled={loading}
                    >
                      {loading ? "Loading..." : "Approve"}
                    </Button>
                  )}
                </ModalFooter>
              </Modal> */}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
