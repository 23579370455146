import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import PropTypes from "prop-types";
import DM_SANS_NORMAL from "../../assets/DM_Sans/DM_Sans/static/DMSans_24pt-SemiBold.ttf";
import DM_SANS_BOLD from "../../assets/DM_Sans/DM_Sans/static/DMSans-ExtraBold.ttf";
import DM_SANS_ITALIC from "../../assets/DM_Sans/DM_Sans/static/DMSans-Italic.ttf";
import TAHOMA_NORMAL from "../../assets/Tahoma/static/TAHOMA_0.TTF";
import TAHOMA_BOLD from "../../assets/Tahoma/static/TAHOMAB0.TTF";

Font.register(
  {
    family: "DM_SANS",
    fonts: [
      { src: DM_SANS_NORMAL },
      {
        src: DM_SANS_BOLD,
        // fontStyle: "bold",
        // fontWeight: 700,
      },
      {
        src: DM_SANS_ITALIC,
        fontStyle: "italic",
      },
    ],
  },
  {
    family: "Tahoma",
    fonts: [
      { src: TAHOMA_NORMAL },
      {
        src: TAHOMA_BOLD,
      },
    ],
  }
);

const styles = StyleSheet.create({
  page: {
    alignContent: "center",
    justifyContent: "center",
    fontSize: 10.5,
    // fontFamily: "Tahoma",
    fontWeight: "bold",
    padding: 10.5,
    flexDirection: "column",
    marginBottom: 40,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  table: {
    display: "table",
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
  },
  row: {
    flexDirection: "row",
  },
  cell: {
    flexGrow: 1,
    borderBottomColor: "#000",
    borderBottomWidth: 1,
    padding: 4,
    borderLeftColor: "#333",
    borderLeftWidth: 0.5,
    fontSize: 10.5,
    fontWeight: "700",
    textAlign: "justify",
    paddingBottom: 10,
  },
  header: {
    fontSize: 23,
    textAlign: "center",
    marginTop: 30,
    marginBottom: 10,
  },
  header2: {
    fontSize: 15,
  },
  logo: {
    width: "40px",
    height: "40px",
  },
  logo1: {
    width: "300px",
    height: "300px",
  },
  watermark: {
    display: "flex",
    opacity: 0.1,
    position: "absolute",
    top: 200,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  logoContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 10,
    marginBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
  },
  italic: {
    // fontStyle: "normal",
  },
  underline: {
    fontWeight: "black",
  },
  bold: {
    fontWeight: "bold",
    fontFamily: "DM_SANS",
    fontSize: 12,
  },
});

const CollectionsSummaryPDF = ({ data = [], user = {}, title = "" }) => {
  const renderNode = (node) =>
    node.level === 0 ? null : (
      <>
        <Text
          style={[
            styles.cell,
            node.children.length === 0 ? {} : styles.bold,
            { width: "30%" },
          ]}
        >
          {!node.children.length ? node.head : node.head.replace(/0+$/, "")}
        </Text>
        <Text
          style={[
            styles.cell,
            node.children.length === 0 ? {} : styles.bold,
            { width: "55%" },
          ]}
        >
          {node.description}
        </Text>
        <Text
          style={[
            styles.cell,
            { width: "5%" },
            node.children.length === 0 ? {} : styles.bold,
          ]}
        >
          {node.level}
        </Text>
      </>
    );

  const renderTree = (tree) => {
    const rows = [];
    const traverse = (nodes) => {
      if (!nodes || nodes.length === 0) return;

      nodes.forEach((node) => {
        rows.push(
          <View key={node.head} style={styles.row}>
            {renderNode(node)}
          </View>
        );
        traverse(node.children);
      });
    };
    traverse(tree);
    return rows;
  };

  return (
    <Document>
      <Page orientation="landscape" size="A4" style={styles.page}>
        <View>
          <View style={styles.section}>
            <View style={styles.table}>
              {/* Table Headers */}
              <View style={styles.row}>
                <View
                  style={[
                    styles.cell,
                    {
                      width: "30%",
                      textAlign: "center",
                      backgroundColor: "yellow",
                    },
                  ]}
                >
                  <Text>CODE</Text>
                </View>
                <View
                  style={[
                    styles.cell,
                    {
                      width: "55%",
                      textAlign: "center",
                      backgroundColor: "yellow",
                    },
                  ]}
                >
                  <Text>DESCRIPTION</Text>
                </View>
                <View
                  style={[
                    styles.cell,
                    {
                      width: "5%",
                      textAlign: "center",
                      backgroundColor: "yellow",
                    },
                  ]}
                >
                  <Text>LEVEL</Text>
                </View>
              </View>
              {/* Render the hierarchical tree */}
              {renderTree(data)}
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

CollectionsSummaryPDF.propTypes = {
  data: PropTypes.array,
  user: PropTypes.object,
  title: PropTypes.string,
};

export default CollectionsSummaryPDF;
