import React, { useState, useCallback, useEffect } from "react";
import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useQuery } from "redux/action/api";
import { postApi } from "redux/action/api_tbeams";
import {
  Card,
  Spinner,
  Table,
  Col,
  Row,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Input,
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledButtonDropdown,
} from "reactstrap";
import { formatNumber } from "redux/action/api";
import { PDFViewer } from "@react-pdf/renderer";
import { NewRelisePdf } from "./NewRelisePdf";
import { useNavigate } from "react-router-dom";
import { RealiseCollectionPdf } from "./RealiseCollectionPdf";
import { useSelector } from "react-redux";
// import moment from "moment";
import moment from "moment";
import { IoMdCheckmark } from "react-icons/io";
import { MdOutlineCancel } from "react-icons/md";
import { useToasts } from "react-toast-notifications";
import { fetchApi } from "redux/action/api_tbeams";
import { _fetchApi } from "redux/action/api";

export default function ViewRelease() {
  const navigate = useNavigate();
  const query = useQuery();
  const { addToast } = useToasts();
  const user = useSelector((e) => e.auth.user);
  const approval_status = query.get("approval_status");
  const return_status = query.get("return_status");
  const showButton = query.get("showButton");
  const approval_no = query.get("approval_no");
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [submittingReview, setSubmittingReview] = useState(false);
  const [display, setDisplay] = useState(true);
  const [form, setForm] = useState({ remark: "", rejact: "" });
  const [modal, setModal] = useState(false);
  const [deleteRow, setDeleteRow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [budget_amount, setAmount] = useState("");
  const [edit, setEdit] = useState(false);
  const toggle = () => setModal((prevState) => !prevState);
  const toggle2 = () => setModal(!modal);
  const [users, setUsers] = useState({});

  const toggleEdit = () => setEdit(!edit);

  const toggle1 = () => {
    setModal(!modal);
    setDeleteRow(!deleteRow);
  };
  const handleChanges = ({ target: { name, value } }) =>
    setForm((p) => ({ ...p, [name]: value }));

  const getRowStyle = (row) => {
    switch (row.approval_status) {
      case "Approval Released":
        return { backgroundColor: "orange" };
      case "Approval Processed":
        return { backgroundColor: "lightblue" };
      case "Approval Approved":
        return { backgroundColor: "lightgreen" };
      case "Approval Queried":
        return { backgroundColor: "yellow" };
      case "Payment Schedule":
        return { backgroundColor: "blue" };
      case "No Budget":
        return { backgroundColor: "#cc8888" };
      case "paid":
        return { backgroundColor: "green" };
      default:
        return { backgroundColor: "" };
    }
  };

  const getApprovalCollection = useCallback(() => {
    setIsLoading(true);
    postApi(`post_approval_collection`, {
      form: {
        query_type: "select_by_approval_no",
        approval_no,
      },
    })
      .then((data) => {
        setData(data.result);
        const _data = data.result.length ? data.result[0] : {};
        // console.log(_data, "sjdjdjdj");
        _fetchApi(
          `/sign-by-id-new?id=${_data.approved_ids}`,
          (d) => {
            if (d.success) {
              setUsers(d?.data[0]);
            }
          },
          (err) => {
            console.log(err);
          }
        );
        fetchApi(
          `get-budget-summary?query_type=fetch_budget&mda_code=${_data?.mda_code}&economic_code=${_data?.economic_code}`
        ).then((d) => {
          console.log(d);
          if (d.result && d.result?.length) {
            console.log("Budget", d);
            const selectedItem = d.result[0];
            setAmount(selectedItem);
            console.log(d, "jhgjahg");
          }
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [approval_no]);

  let styles = {
    fontSize: 15,
    padding: 2,
  };

  const bodyStyle = (item) => {
    return { fontSize: 15, padding: 2, ...getRowStyle(item) };
  };

  const _data = data.length ? data[0] : {};
  const pdfData = data.map((i) => ({ ...i, approved_amount: i.amount }));
  const total = data.reduce((acc, item) => acc + parseFloat(item.amount), 0);
  const previewPdf = () => setDisplay(!display);

  useEffect(() => {
    getApprovalCollection();
  }, [getApprovalCollection]);

  const updateBalance = () => {
    postApi(`update_budget_balance`, {
      query_type: "update_balance",
      mda_name: budget_amount.mda_name,
      mda_code: budget_amount.mda_code1,
      amount: total,
      economic_code: budget_amount.mda_parent_code,
      geo_code: budget_amount.geo_code,
      program_code: budget_amount.program_code,
      functional_code: budget_amount.functional_code,
    })
      .then((data) => {
        console.log("data", data);
        if (data.success) {
          toast.success("Balance updated");
        }
      })
      .catch((err) => {
        // Handle errors
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getCCCopies = useCallback(() => {
    postApi(`post-cc?query_type=select_mda`, { mda_name: _data.mda_name })
      .then((data) => {
        console.log("data", data);
        if (data.results) {
          setForm((p) => ({
            ...p,
            ..._data,
            approved_amount: _data.amount,
            copies: data.results[0],
            mda_economic_code: _data.economic_code,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [_data.mda_name]);

  const getCCCopies1 = useCallback(() => {
    postApi(`post-cc?query_type=select_mda`, { mda_name: _data.mda_head })
      .then((data) => {
        console.log("data", data);
        if (data.results) {
          setForm((p) => ({
            ...p,
            ..._data,
            approved_amount: _data.amount,
            copies1: data.results[0],
            mda_economic_code: _data.economic_code,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [_data.mda_head]);

  useEffect(() => {
    getCCCopies();
    getCCCopies1();
  }, [getCCCopies, getCCCopies1]);

  const approveRequest = (status) => {
    // alert(JSON.stringify(data));
    setLoading(true);
    postApi(`update-approval`, {
      mda_name: _data.mda_name,
      approval_no: _data.approval_no,
      status,
      remarks: form.remark,
      userId: user.id,
      query_type: "new-update",
    })
      .then((data) => {
        console.log("data", data);
        if (data.success) {
          setModal(false);
          setLoading(false);
          if (status.includes("Approval Released")) {
            updateBalance();
            // alert(JSON.stringify(status));
          }
          getApprovalCollection();
          navigate(-1);
          addToast("Approve successfully", {
            appearance: "info",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
        }
      })
      .catch((err) => {
        // Handle errors
        console.log(err);
        addToast("An error occurred", {
          appearance: "danger",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
        setLoading(false);
      })
      .finally(() => {
        // setIsUpdating(false);
        setLoading(false);
      });
  };

  const onEditClick = (item) => {
    setEdit(!edit);
    const newList = [];
    data.forEach((i) => {
      if (
        i.mda_code == item.mda_code &&
        i.economic_code === item.economic_code
      ) {
        newList.push({ ...i, editing: true });
      } else {
        newList.push(i);
      }
    });

    setData(newList);
  };

  const handleDataChange = (name, value, item) => {
    const newList = [];
    data.forEach((i) => {
      if (
        i.mda_code == item.mda_code &&
        i.economic_code === item.economic_code
      ) {
        newList.push({ ...i, [name]: value });
      } else {
        newList.push(i);
      }
    });

    setData(newList);
  };

  const submitReview = () => {
    setSubmittingReview(true);
    const modified = data
      .filter((a) => a.editing)
      .map((a) => ({
        ...a,
        approved_amount: a.amount,
        mda_economic_code: a.economic_code,
      }));
    postApi(`update_releases`, {
      approval_no: _data.approval_no,
      query_type: "update_release",
      data: modified,
      userId: user.id,
    })
      .then((resp) => {
        console.log("resp", resp);
        if (resp.success) {
          setModal(false);
          getApprovalCollection();
          navigate(-1);
          addToast("Updated successfully", {
            appearance: "info",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
        }
      })
      .catch((err) => {
        // Handle errors
        console.log(err);
        addToast("An error occurred", {
          appearance: "danger",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
      })
      .finally(() => {
        setSubmittingReview(false);
      });
  };

  const newUser =
    (approval_status === "Approval Released") & !showButton ? user : users;

  return (
    <div>
      <DashboardLayout>
        <MDBox pt={2} pb={3} pl={2}>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              {isLoading && (
                <>
                  <Spinner />
                  Loading ...
                </>
              )}
              <Card className="p-2" style={{ height: "95vh" }}>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h5" color="white">
                    Release No: {_data.approval_no}
                  </MDTypography>
                </MDBox>

                {/* {JSON.stringify({ form })} */}
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Col
                    md={5}
                    style={{
                      marginLeft: 9,
                    }}
                    className="mt-4"
                  >
                    <Button
                      color="warning"
                      style={{ marginRight: "10px", marginLeft: "10px" }}
                      onClick={() => navigate(-1)}
                    >
                      Click here to go back
                    </Button>
                  </Col>
                  <Col md={4}>
                    <div
                      style={{
                        marginTop: 30,
                      }}
                    >
                      <p>
                        Budget Balance:{" "}
                        <b>{formatNumber(budget_amount.mopb_balance)}</b>
                      </p>
                    </div>
                  </Col>
                </Row>
                {/* {JSON.stringify({ user })} */}

                <Row className="p-2 ">
                  <Table>
                    <thead>
                      <tr>
                        <th>Collection Date</th>
                        <th>Approval Date</th>
                        <th>Approval By</th>
                        <th> Total</th>
                        <th className="text-center">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <p>{_data.collection_date}</p>
                        </td>
                        <td>
                          <b></b>
                          <p>{_data.approval_date}</p>
                        </td>
                        <td>
                          <b></b>
                          <p>{_data.approved_by}</p>
                        </td>
                        <td>
                          <b>{formatNumber(total)}</b>
                        </td>

                        <td>
                          <Row className="d-flex">
                            <div
                              className={
                                display ? "text-primary" : "text-danger"
                              }
                              style={{
                                textAlign: "right",
                                cursor: "pointer",
                                fontWeight: "bold",
                              }}
                              onClick={previewPdf}
                            >
                              <small>
                                {display
                                  ? "Preview document"
                                  : "Close document"}
                              </small>
                            </div>
                            {showButton === true ||
                            showButton === "true" ? null : (
                              <div>
                                <UncontrolledButtonDropdown direction="up">
                                  <DropdownToggle
                                    caret
                                    size="sm"
                                    style={{ marginTop: -50 }}
                                  >
                                    Actions
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem
                                      onClick={() => {
                                        toggle2();
                                        setData([_data]);
                                      }}
                                    >
                                      <IoMdCheckmark
                                        size="18px"
                                        style={{ marginRight: 6 }}
                                      />
                                      Approve
                                    </DropdownItem>

                                    <DropdownItem
                                      onClick={() => {
                                        toggle1();
                                        setData([_data]);
                                      }}
                                    >
                                      {" "}
                                      <MdOutlineCancel
                                        size="18px"
                                        style={{ marginRight: 6 }}
                                      />
                                      Return
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledButtonDropdown>
                              </div>
                            )}
                          </Row>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Row>

                {/* <Row>
                  <Col md={{ size: 1, offset: 11}}><Button className="mb-2" size="sm" color="success">Edit</Button></Col>
                </Row> */}
                {display && isLoading ? (
                  <Spinner color="primary" />
                ) : (
                  <Table
                    size="sm"
                    className="table-bordered table-responsive table-zebra table-hover"
                    style={{ zIndex: 10 }}
                  >
                    <thead size="sm">
                      <tr>
                        <th style={styles}>Action</th>
                        <th style={styles}>Amount</th>
                        <th style={styles}>MDA</th>
                        <th style={styles}>MDA code</th>
                        <th style={styles}>Activities</th>
                        <th style={styles}>Economic code</th>
                        <th style={styles}>Program</th>
                      </tr>
                    </thead>

                    <tbody>
                      {data?.map((item, idx) => (
                        <tr key={idx}>
                          <td
                            style={{
                              ...bodyStyle(item),
                              textAlign: "center",
                            }}
                          >
                            <Button
                              onClick={() => onEditClick(item)}
                              className=""
                              size="sm"
                              color="success"
                            >
                              Edit
                            </Button>
                          </td>
                          <td
                            style={{
                              ...bodyStyle(item),
                              textAlign: "right",
                            }}
                          >
                            {formatNumber(item.amount)}
                          </td>

                          <td style={bodyStyle(item)}>{item.mda_name}</td>
                          <td style={bodyStyle(item)}>{item.mda_code}</td>
                          <td style={bodyStyle(item)}>
                            {item.description_code}
                          </td>
                          <td style={bodyStyle(item)}>{item.economic_code}</td>
                          <td style={bodyStyle(item)}>{item.program_code}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}

                {!display && (
                  <PDFViewer style={{ width: "100%", height: "100vh" }}>
                    {_data.approval_type === "Capital" ? (
                      <RealiseCollectionPdf
                        form={form}
                        selecOffer={formatNumber}
                        today={moment().format("LL")}
                        user={newUser}
                      />
                    ) : _data.approval_type === "Recurrent" &&
                      data.length === 1 ? (
                      <RealiseCollectionPdf
                        form={form}
                        selecOffer={formatNumber}
                        user={newUser}
                        today={moment().format("LL")}
                      />
                    ) : (
                      <NewRelisePdf
                        selectedData={pdfData}
                        selecOffer={formatNumber}
                        imageUrl={newUser && newUser.imageUrl}
                        today={moment().format("LL")}
                        user={newUser}
                      />
                    )}
                  </PDFViewer>
                )}
              </Card>
            </Grid>
          </Grid>
        </MDBox>

        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader>
            <span className="text-center" style={{ width: "100%" }}>
              <center>
                {deleteRow ? (
                  <p
                    style={{
                      color: "red",
                      fontWeight: "500",
                    }}
                  >
                    Are You Sure You want to return
                  </p>
                ) : (
                  <p
                    style={{
                      color: "#54AC58",
                      fontWeight: "500",
                    }}
                  >
                    Are You Sure You want to Approve this request?
                  </p>
                )}
              </center>
            </span>
          </ModalHeader>
          <ModalBody>
            <Col md={12}>
              <Label>Add Remark</Label>
              <Input
                type="textarea"
                name="remark"
                value={form.remark}
                onChange={handleChanges}
                style={{
                  borderColor: deleteRow ? "red" : "#54AC58",
                  borderWidth: 2,
                }}
              />
            </Col>
          </ModalBody>
          <ModalFooter className="d-flex justify-content-center">
            <Button
              color="warning"
              variant="warning"
              className="btn btn-warning"
              onClick={toggle1}
            >
              cancel
            </Button>
            {deleteRow ? (
              <Button
                variant="danger"
                className="btn btn-danger"
                onClick={() => {
                  approveRequest(return_status);
                  toggle1();
                }}
                disabled={loading}
              >
                Return
              </Button>
            ) : (
              <Button
                onClick={() => approveRequest(approval_status)}
                disabled={loading}
                color="primary"
                variant="primary"
                className="btn btn-primary"
              >
                {loading ? "Loading..." : "Approve"}
              </Button>
            )}
          </ModalFooter>
        </Modal>

        <Modal isOpen={edit} toggle={toggleEdit} size="lg">
          <ModalHeader>
            <center>Edit Release Info</center>
          </ModalHeader>
          <ModalBody>
            <Table
              size="sm"
              className="table-bordered table-responsive table-zebra table-hover"
              style={{ zIndex: 10 }}
            >
              <thead size="sm">
                <tr>
                  <th style={styles}>Narration</th>
                  <th style={styles}>Amount</th>
                  <th style={styles}>MDA</th>
                  <th style={styles}>MDA code</th>
                  <th style={styles}>Activities</th>
                  <th style={styles}>Economic code</th>
                  <th style={styles}>Program</th>
                </tr>
              </thead>

              <tbody>
                {data?.map((item, idx) => (
                  <tr key={idx}>
                    <td
                      style={{
                        ...bodyStyle(item),
                        textAlign: "center",
                      }}
                    >
                      <Input
                        value={item.narration}
                        onChange={({ target: { value } }) =>
                          handleDataChange("narration", value, item)
                        }
                      />
                    </td>
                    <td
                      style={{
                        ...bodyStyle(item),
                        textAlign: "right",
                      }}
                    >
                      <Input
                        value={item.amount}
                        onChange={({ target: { value } }) =>
                          handleDataChange("amount", value, item)
                        }
                      />
                    </td>

                    <td style={bodyStyle(item)}>
                      <Input
                        value={item.mda_name}
                        onChange={({ target: { value } }) =>
                          handleDataChange("mda_name", value, item)
                        }
                      />
                    </td>
                    <td style={bodyStyle(item)}>
                      <Input
                        value={item.mda_code}
                        onChange={({ target: { value } }) =>
                          handleDataChange("mda_code", value, item)
                        }
                      />
                    </td>
                    <td style={bodyStyle(item)}>
                      <Input
                        value={item.description_code}
                        onChange={({ target: { value } }) =>
                          handleDataChange("description_code", value, item)
                        }
                      />
                    </td>
                    <td style={bodyStyle(item)}>
                      <Input
                        value={item.economic_code}
                        onChange={({ target: { value } }) =>
                          handleDataChange("economic_code", value, item)
                        }
                      />
                    </td>
                    <td style={bodyStyle(item)}>
                      <Input
                        value={item.program_code}
                        onChange={({ target: { value } }) =>
                          handleDataChange("program_code", value, item)
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </ModalBody>
          <ModalFooter className="d-flex justify-content-center">
            <Button
              color="warning"
              variant="warning"
              className="btn btn-warning"
              onClick={toggleEdit}
            >
              cancel
            </Button>
            <Button
              color="success"
              onClick={submitReview}
              disabled={submittingReview}
            >
              Submit Review
            </Button>
          </ModalFooter>
        </Modal>
      </DashboardLayout>
    </div>
  );
}
