import React from "react";
import PublicationReports from "./PublicationReports";

export default function PublicationReportsNew() {
  const listdata = [
    { value: "mda_report_econ", title: "MDA reports by Econ" },
    { value: "econ_by_func", title: "MDA Exp. By Function" },
    { value: "rev_by_mda", title: "MDA Revenue by Econ." },
    { value: "capital_eco", title: "MDA Capita Exp." },
  ];
  return (
    <PublicationReports
      type="mda_report_econ"
      route="/publication-reports"
      options={listdata}
      title="MDA reports by Econ"
    />
  );
}
