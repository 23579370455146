import CapitalRecByItem from "./CapitalRecByItem";
const listdata = [
  { value: "capital_receipts_by_item", title: "Capital Rec By Item" },
  // { value: "capital_receipts_admin", title: "Capital Rec by Department" },
  // { value: "capital_receipts_admin", title: "Capital Rec by Fund" },
];
function FuncQuaterly() {
  return (
    <CapitalRecByItem
      type="capital_receipts_by_item"
      route="/reports"
      options={listdata}
      title="Capital Rec By Item"
    />
  );
}

export default FuncQuaterly;
