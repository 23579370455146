import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import CoverPage, {
  Administative,
  AdministativeCapital,
  AdministativeOverHead,
  AdministativePersonell,
  BudgetSummaryPdf,
  EconomicRev,
  OverView,
  Summary2,
  SummaryPage,
  TotalFundExp,
} from "./PerformancePdfComps";
import PropTypes from "prop-types";

const styles = StyleSheet.create({
  page: {
    padding: 30,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableColHeader: {
    width: "16.66%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#ffc107",
    padding: 5,
  },
  tableCol: {
    width: "16.66%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
  },
  tableCellHeader: {
    fontSize: 10,
    fontWeight: "bold",
    color: "#000",
  },
  tableCell: {
    fontSize: 10,
  },
});

const PerformancePdf = ({
  data = [],
  data2 = [],
  data3 = [],
  data4 = [],
  data5 = [],
  data6 = [],
  data7 = [],
  data8 = [],
  data9 = [],
  data10 = [],
  data11 = [],
  data12 = [],
}) => (
  <Document>
    <CoverPage />
    <SummaryPage />
    <Summary2 />

    {/* Uncomment the components as needed and ensure they accept the required props */}
    <OverView />
    {/* <TotalFundExp
      data={data6}
      title="2.B Revenue by Administrative Classification"
      sub_title="Table 2:  Total Revenue by Classification by Administrative"
      heading="Q1 Total Revenue by Classification by Administrative"
      names="Total Revenue"
      unit="Administrative Unit"
    /> */}
    <TotalFundExp
      data={data7}
      title="2.D	Expenditure by Administrative Classification"
      sub_title="Table 2: Total Expenditure by Administrative Classification"
      heading="Total Expenditure by
      Classification by Administrative"
      names="Total Expenditure"
      unit="Administrative Unit"
    />
    <TotalFundExp
      data={data11}
      title="2.C	Revenue by Economic Classification"
      sub_title="Table 3: Total Revenue by Economic Classification"
      heading="Total Revenue by
      Classification by Economic"
      names="Total Revenue"
      unit="Economic"
    />
    <TotalFundExp
      data={data8}
      title=""
      sub_title="Table 5: Personnel Expenditure by Administrative Classification"
      heading="Personnel Expenditure by
      Classification by Administrative"
      names="Total personnel Expenditure"
      unit="Administrative Unit"
    />

    <TotalFundExp
      data={data9}
      title=""
      sub_title="Table 6: Overhead Expenditure by Administrative Classification"
      heading="Overhead Expenditure by
      Classification by Administrative"
      names="Total Overhead Expenditure"
      unit="Administrative Unit"
    />

    <TotalFundExp
      data={data10}
      title=""
      sub_title="Table 7: Capital Expenditure by Administrative Classification"
      heading="Capital Expenditure by
      Classification by Administrative"
      names="Total Capital Expenditure"
      unit="Administrative Unit"
    />

    <TotalFundExp
      data={[]}
      title=""
      sub_title="Table 8: Other Expenditure by Administrative Classification"
      heading="Other Expenditure by
      Classification by Administrative"
      names="Total Other Expenditure"
      unit="Administrative Unit"
    />

    <TotalFundExp
      data={[]}
      title="2.E	Expenditure by Economic Classification"
      sub_title="Table 9: Total Expenditure by Economic Classification"
      heading="Total Expenditure by
      Classification by Economic"
      names="Total Expenditure"
      unit="Economic"
    />

    {/* <EconomicRev data={data} /> */}

    {/* <TotalFundExp
      data={data}
      title="2.F Expenditure by Function"
      sub_title="Table 10: Total Expenditure by Function"
      heading="Q1 Total Expenditure by Classification by Function"
      names="Total Expenditure"
      unit=""
    />

    <TotalFundExp
      data={data2}
      title=""
      sub_title="Table 11: Personnel Expenditure by Function"
      heading="Q1 Total Personnel Expenditure by Classification by Function"
      names="Total Personnel Expenditure"
      unit=""
    />

    <TotalFundExp
      data={data3}
      title=""
      sub_title="12: Overhead Expenditure by Function"
      heading="Q1 Total Overhead Expenditure by Classification by Function"
      names="Total Overhead Expenditure"
      unit=""
    />
    <TotalFundExp
      data={data4}
      title=""
      sub_title="Table 13: Capital Expenditure by Function"
      heading="Q1 Total Capital Expenditure by Classification by Function"
      names="Total Capital Expenditure"
      unit=""
    />

    <TotalFundExp
      data={data12}
      title=""
      sub_title="Table 14:Other Expenditure by Function"
      heading="Q1 Total Other Expenditure by Classification by Function"
      names="Total Other Expenditure"
      unit=""
    />

    <TotalFundExp
      data={data5}
      title="2.G	Capital Expenditure by Programme"
      sub_title="Table 15 Capital Expenditure by Programme"
      heading="Q1 Capital Expenditure by Classification by Programme"
      names="Total Capital Expenditure"
      unit="programmer (sector and objectives)"
    /> */}
  </Document>
);

PerformancePdf.propTypes = {
  data: PropTypes.array,
  data2: PropTypes.array,
  data3: PropTypes.array,
  data4: PropTypes.array,
  data5: PropTypes.array,
  data6: PropTypes.array,
  data7: PropTypes.array,
  data8: PropTypes.array,
  data9: PropTypes.array,
  data10: PropTypes.array,
  data11: PropTypes.array,
  data12: PropTypes.array,
  totals: PropTypes.array,
  form: PropTypes.array,
};

export default PerformancePdf;
