import React, { useEffect, useState } from "react";

import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import { AiFillEdit, AiFillEye } from "react-icons/ai";
import { FiDelete, FiEdit } from "react-icons/fi";
import { getSector } from "helper";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import { Card, Grid } from "@mui/material";
import SearchBar from "components/SearchBar";
import CustomTable from "components/CustomTable";
import { _postApi } from "redux/action/api";
import Footer from "examples/Footer";
import MDTypography from "components/MDTypography";

function SubSubSubOrg() {
  const navigate = useNavigate();
  const fields = [
    {
      title: "Sub Sub Sub Org Code",
      custom: true,
      component: (ite) => <div className="text-center">{ite.head}</div>,
    },
    {
      title: "Sub Sub Sub Org Name",
      custom: true,
      component: (ite) => ite.description,
    },
    { title: "Remarks", custom: true, component: (item) => item.remarks },
    {
      title: "Action",
      custom: true,
      component: (ite) => (
        <div className="text-center">
          <button
            className="btn btn-primary"
            onClick={() => navigate(`form/${ite.id}`)}
          >
            <FiEdit /> Edit
          </button>
          <button
            className="btn btn-danger"
            color="danger"
            onClick={() => {
              Delete(ite.id);
              getSector();
            }}
          >
            <FiDelete />{" "}
          </button>
        </div>
      ),
    },
  ];
  const [results, setResults] = useState();
  // let data = results&&results.map(item => item.name)

  const Delete = (id) => {
    _postApi(
      `/account?query_type=delete`,
      { id },
      (data) => {
        // setResults(id.filters((results) => results.id !== id));
        if (data.success) {
          alert("Deleted Successfully");
          // setResults(data.results);
          getSector({ type: "sub_org" }, "select", setResults);
        }
      },
      (err) => console.log(err)
    );
  };
  useEffect(() => {
    getSector({ type: "sub_org" }, "select", setResults);
  }, []);
  return (
    <DashboardLayout>
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={3}
              >
                <MDBox>
                  <MDTypography variant="h6" gutterBottom>
                    Sub Sub Sub Table
                  </MDTypography>
                </MDBox>
                <MDBox color="text" px={2}></MDBox>
              </MDBox>
              <MDBox>
                <MDBox pt={3}>
                  <div className=" row mb-4 p-3">
                    <button
                      style={{ float: "left" }}
                      className="mb-4 btn btn-primary col-md-2"
                      onClick={() => navigate("form")}
                    >
                      <FaPlus /> Add Sub-Org{" "}
                    </button>
                    <SearchBar />
                    {/* <h1>i am sub org</h1>  */}
                    <CustomTable fields={fields} data={results} />
                  </div>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default SubSubSubOrg;
