import ReportShareCom from "./ReportsExpShareCom";

function FundReport() {
  return (
    <ReportShareCom
      type="fund"
      header="Capital Receipts by Fund"
      ignore="Fund Code"
    />
  );
}

export default FundReport;
