import ReportsAdminShareCom from "./ReportsAdminShareCom";

const listdata = [
  { value: "total_ex_by_dep", title: "Total Expenditure by Department" },
  {
    value: "ex_by_dep_new",
    title: "Personnel Expenditure by Department",
    code: "Personnel",
  },
  {
    value: "ex_by_dep_new",
    title: "Other NDR Expenditure by Department",
    code: "OverHead",
  },
  {
    value: "ex_by_dep",
    title: "Debt Service by Department",
    code: "2206%",
  },
  {
    value: "ex_by_dep_new",
    title: "Capital Expenditure by Department",
    code: "Capital",
  },
];

function ExpByDepartment() {
  return (
    <ReportsAdminShareCom
      type="total_ex_by_dep"
      route="/reports"
      options={listdata}
      title="Total Expenditure by Department"
    />
  );
}

export default ExpByDepartment;
