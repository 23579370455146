import React, { useState, useEffect, useCallback } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Button, Grid } from "@mui/material";
import { _postApi, _fetchApi } from "redux/action/api";
import { formatNumber } from "redux/action/api";
import { getSector } from "helper";
import CustomTypeahead from "components/CustomTypeahead";
import { year } from "helper";
import { useToasts } from "react-toast-notifications";
import { useSelector } from "react-redux";
import { useYear } from "useYearList";
import {
  Col,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import PreviousCeiling from "./PreviousCeiling";
import { electron } from "webpack";

export default function SubBudgetCeiling() {
  const user = useSelector((state) => state.auth.user.username);
  const [data, setData] = useState([]);
  const [testarray, setSetarr] = useState([]);
  const [type, setType] = useState([]);
  const [form, setForm] = useState({
    description: "",
    year,
    totalAmt: 0,
    head: "",
  });
  const [percentage] = useState(true);
  const [isOpen, setIsopen] = useState(false);
  const toggle = () => setIsopen(!isOpen);

  const [totalAmounts, setTotalAmounts] = useState("");
  const years = useYear();
  const total = data?.reduce((a, b) => a + parseFloat(b.amt || 0), 0);
  const totalVal = data?.reduce((a, b) => a + parseFloat(b.total_amt || 0), 0);
  const items = data?.length && data[0];
  const checkStatus = items?.req_status === "approved";
  const _total = totalAmounts?.length && totalAmounts[0].total_amt;

  let _new_data = [
    {
      head: form.head,
      sub_head: "",
      description: "Personnel",
      level: 4,
      type: "ADMINISTRATIVE SEGMENT",
      remarks: null,
      status: "sub_mda",
      amt: 0,
      total_amt: 0,
      mda_name: form.description,
      mda_code: form.head,
    },
    {
      head: form.head,
      sub_head: "",
      description: "OverHead",
      level: 4,
      type: "ADMINISTRATIVE SEGMENT",
      remarks: null,
      status: "sub_mda",
      amt: 0,
      total_amt: 0,
      mda_name: form.description,
      mda_code: form.head,
    },
    {
      head: form.head,
      sub_head: "",
      description: "Recurrent",
      level: 4,
      type: "ADMINISTRATIVE SEGMENT",
      remarks: null,
      status: "sub_mda",
      amt: 0,
      total_amt: 0,
      mda_name: form.description,
      mda_code: form.head,
    },
  ];

  let query_type = testarray?.length ? "update" : "insert";

  const handleChange = ({ target: { name, value } }) =>
    setForm((p) => ({ ...p, [name]: value }));

  // const [results4, setResult4] = useState([]);
  useEffect(() => {
    getSector({ sub_head: form.head }, "select_subhead", (d) => {
      const newData = d.map((item) => ({
        ...item,
        status: "mda",
        amt: 0,
        total_amt: 0,
      }));
      setData(newData);
    });
  }, [form.sector_type]);

  const getHeads = () => {
    // alert("im called");
    _fetchApi(
      `/budgetCeiling?query_type=mda`,
      (data) => {
        setType(data.results);
      },
      (err) => console.log(err)
    );
  };

  const getCeilingAmt = useCallback(() => {
    _postApi(
      `/budgetCeiling?query_type=select_ceiling_mda`,
      { head: form.head, year: form.year },
      (data) => {
        setTotalAmounts(data.results);
      },
      (err) => console.log(err)
    );
  }, [form.head, form.year]);

  const getCellingData = useCallback(() => {
    _postApi(
      `/budgetCeiling?query_type=sub_mda`,
      { subhead: form.head, year: form.year, mda_code: form.head },
      (data) => {
        if (data.results?.length) {
          setData(data.results);
          setSetarr(data.results);
        } else {
          getSector({ sub_head: form.head }, "select_subhead", (d) => {
            // alert(JSON.stringify(data));
            const newData = d.map((item) => ({
              ...item,
              status: "mda",
              amt: 0,
              total_amt: 0,
            }));
            if (form.description === "") {
            } else {
              setData(_new_data);
            }
          });
        }
      },
      (err) => console.log(err)
    );
  }, [form.year, form.type, form.head]);

  useEffect(() => {
    getHeads();
    getCellingData();
    getCeilingAmt();
  }, [getCellingData, getCeilingAmt]);
  const [mainOrg, setMainOrg] = useState();
  useEffect(() => {
    getSector({ type: "mdas" }, "mdas", (d) => {
      // Change the status of each object in the array to 'sector'
      const newData = d.map((item) => ({
        ...item,
        status: "sector",
        amount: 0,
        total_amt: 0,
      }));
      setMainOrg(newData);
    });
  }, []);

  const handleTableChange = (value, index) => {
    let newArr = [];
    data?.forEach((item, i) => {
      if (i === index) {
        newArr.push({
          ...item,
          amt: value,
          total_amt: (parseFloat(value) / 100) * parseFloat(_total),
        });
      } else {
        newArr.push(item);
      }
    });
    setData(newArr);
  };

  useEffect(() => {
    if (percentage && total > 100) {
      alert("Must not grater than 100%");
    }
  }, [percentage, total]);
  const pvalue = percentage ? "Percentage" : "Value";

  const { addToast } = useToasts();

  const handleSubmit = (req_status) => {
    if (percentage && total > 100) {
      alert("Total percentage cannot exceed 100%");
      return;
    } else if ((form.totalAmount || _total) < totalVal) {
      alert("Total amount is less than ceiling provided");
    } else if (totalAmounts[0].req_status === "draft") {
      alert("Cannot ceilled because the sector ceiling still on draft");
    } else {
      _postApi(
        `/insert-budgetCeiling?query_type=${query_type}`,
        {
          data,
          pvalue,
          req_status,
          created_by: user,
          updated_by: query_type === "insert" ? null : user,
          year: form.year,
        },
        (data) => {
          console.log(data);
          addToast("Submit successfully", {
            appearance: "info",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
          getCellingData();
          setData([]);
        },
        (err) => console.log(err)
      );
    }
  };

  let newData = [
    { name: "Adminstrative Sector", code: "01%", head: "010000000000" },
    { name: "Economic Sector", code: "02%", head: "020000000000" },
    { name: "Social Sector", code: "05%", head: "030000000000" },
    { name: "Law And Justice", code: "03%", head: "050000000000" },
  ];
  return (
    <div>
      <DashboardLayout>
        <Modal isOpen={isOpen} size="lg">
          <ModalHeader toggle={toggle}>Previous Year Ceilling</ModalHeader>
          <PreviousCeiling status="" subhead={form.head} year={form.year} />
          <ModalFooter>
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <MDBox pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12} p={3}>
              <Card className="p-3">
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography
                    variant="h5"
                    color="white"
                    classNam="text-center"
                  >
                    MDA Budget Ceiling
                  </MDTypography>
                </MDBox>
                {/* {JSON.stringify(data)} */}
                <MDBox pt={3}>
                  <div className="row">
                    <div className="col-md-4">
                      {/* <label>Select Sector</label>
                      <select
                        name="sector_type"
                        onChange={handleChange}
                        className="form-control"
                        value={form.sector_type}
                      >
                        <option>...Select...</option>

                        <option value="01%">Adminstrative Sector</option>
                        <option value="02%">Economic Sector</option>
                        <option value="05%">Social Sector</option>
                        <option value="03%">Law And Justice</option>
                      </select> */}
                      <CustomTypeahead
                        labelKey="description"
                        label="Select Sector"
                        options={mainOrg}
                        onChange={(s) => {
                          if (s.length) {
                            console.log(s);
                            setForm((p) => ({
                              ...p,
                              description: s[0]?.description,
                              sector_type: s[0]?.code,
                              head: s[0]?.head,
                            }));
                          }
                        }}
                      />
                    </div>
                    {/* <div className="col-md-4 mb-3">
                      <CustomTypeahead
                        labelKey="head"
                        label="Select MDA"
                        options={aheadData}
                        onChange={(s) => {
                          if (s.length) {
                            console.log(s);
                            setForm((p) => ({
                              ...p,
                              description: s[0]?.description,
                              head: s[0]?.code,
                              sub_head: s[0]?.sub_head,
                            }));
                          }
                        }}
                      />
                    </div> */}
                    <div className="col-md-4">
                      {/* <div className="form-group m-2" style={{ width: "60%" }}>
                        <label>Select Value Type:</label>
                        <div className="d-flex">
                          <div className="form-check ">
                            <input
                              type="radio"
                              name="valueType"
                              value="percentage"
                              className="form-check-input"
                              checked={percentage}
                              onChange={handleOptionChange}
                            />
                            <label className="form-check-label">
                              Percentage
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              type="radio"
                              name="valueType"
                              value="fixed"
                              className="form-check-input"
                              checked={!percentage}
                              onChange={handleOptionChange}
                            />
                            <label className="form-check-label">Fixed</label>
                          </div>
                        </div>
                      </div> */}
                    </div>
                    <div className="col-md-4 ">
                      <Label>Select year</Label>
                      <Input
                        type="select"
                        name="year"
                        value={form.year}
                        onChange={handleChange}
                      >
                        <option>--select--</option>
                        {years?.map((item) => (
                          <option key={item}>{item}</option>
                        ))}
                      </Input>
                    </div>
                  </div>
                  {/* {JSON.stringify(data)} */}
                  <div className="d-flex justify-content-between">
                    <small
                      className="text-primary"
                      style={{ cursor: "pointer", marginTop: 10 }}
                      onClick={toggle}
                    >
                      <b>View previous year ceiling</b>
                    </small>
                    <div className="p-2" style={{ float: "right" }}>
                      Total Ceilling{" "}
                      <b>₦{formatNumber(form.totalAmount || _total)}</b>
                    </div>
                  </div>

                  <table className="table table-border">
                    <thead>
                      <tr>
                        <th>Description</th>
                        <th>Ceiling ({total || 0}%)</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.map((row, index) => (
                        <tr key={index}>
                          <td>{row.description?.toUpperCase()}</td>
                          <td>
                            <Col md={4}>
                              <InputGroup>
                                <Input
                                  // type="number"
                                  maxLength={2}
                                  // className="form-control"
                                  value={row.amt}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    handleTableChange(value, index);
                                  }}
                                  disabled={checkStatus ? true : false}
                                />
                                <InputGroupText>%</InputGroupText>
                              </InputGroup>
                            </Col>

                            {/* <input
                              type="number"
                              maxLength={2}
                              className="form-control"
                              value={row.amt}
                              onChange={(e) => {
                                const value = e.target.value;
                                handleTableChange(value, index);
                              }}
                              disabled={checkStatus ? true : false}
                            /> */}
                            {/* <input
                              type="number"
                              maxLength={2}
                              className="form-control"
                              value={row.amount || totalAmount / len}
                              onChange={(e) => {
                                const value = e.target.value;
                                handleTableChange(value, index);
                              }}
                            /> */}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            ₦ {formatNumber(row.total_amt)}
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <th>Total</th>
                        <th>
                          {total} {percentage && "%"}
                        </th>
                        <th style={{ textAlign: "right" }}>
                          ₦{formatNumber(totalVal)}
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </MDBox>
                {!checkStatus && (
                  <center>
                    <button
                      className="btn btn-primary pr-5 pl-5 m-2"
                      onClick={() => handleSubmit("approved")}
                    >
                      Submit now
                    </button>
                    <button
                      className="btn btn-warning pr-5 pl-5 m-2"
                      onClick={() => handleSubmit("draft")}
                    >
                      Save As Draft
                    </button>
                  </center>
                )}
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </div>
  );
}
