import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useRoutes } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  Table,
} from "reactstrap";
import { postApi } from "redux/action/api_tbeams";
import { LuEye } from "react-icons/lu";
import { IoMdCheckmark } from "react-icons/io";
import { MdOutlineCancel } from "react-icons/md";

export default function PlanningReserve() {
  const navigate = useNavigate();
  const [planningReserve, setPlanningReserve] = useState([]);
  const [filter, setFilter] = useState("All");
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [data, setData] = useState({});
  const [editDropdownOpen, setEditDropdownOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [deleteRow, setDeleteRow] = useState(false);

  const toggle = () => setModal(!modal);
  const toggle1 = () => {
    setModal(!modal);
    setDeleteRow(!deleteRow);
  };

  const toggleEditDropdown = (idx) => {
    setEditDropdownOpen((prevState) => !prevState);
    setSelectedRow(idx);
  };

  const getPlanningReserve = useCallback(() => {
    setIsLoading(true);
    postApi(`planning-reservation`, {
      query_type: "select",
    })
      .then((data) => {
        console.log("data", data);
        if (data.result) {
          const arr = data.result.map((item) => ({
            ...item,
            isOpen: false,
          }));
          setPlanningReserve(arr);
        }
      })
      .catch((err) => {
        // Handle errors
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    getPlanningReserve();
  }, [getPlanningReserve]);

  const getRowStyle = (row) => {
    switch (row.reserve_status) {
      case "Pending":
        return { backgroundColor: "lightgreen" };
      case "Approved":
        return { backgroundColor: "yellow" };
      default:
        return { backgroundColor: "" };
    }
  };
  const params = useLocation();
  const type =
    params.pathname === "/Review-Virement" ? "update_status" : "final_approve";
  const approveRequest = () => {
    console.log(data, "xxxx");
    setIsUpdating(true);
    postApi(`planning-reservation`, {
      query_type: type,
      reserveStatus: "Reviewed",
      id: data.id,
    })
      .then((data) => {
        console.log("data", data);
        if (data.success) {
          setModal(false);
          getPlanningReserve();
        }
      })
      .catch((err) => {
        // Handle errors
        console.log(err);
        alert("An error occurred");
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  return (
    <DashboardLayout>
      <MDBox pt={2} pb={3} pl={2}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h5" color="white">
                  {params.pathname === "/Review-Virement"
                    ? "Review Virement"
                    : "Approve Virement"}{" "}
                </MDTypography>
              </MDBox>
              {/* {JSON.stringify(type)} */}
              {/* <Col md={4} className="mt-4">
                <button
                  className="btn btn-success"
                  style={{ marginRight: "10px", marginLeft: "10px" }}
                  onClick={() => navigate("/new-plnning-reservation")}
                >
                  Add Planning Reserve
                </button>
              </Col> */}
              <Col
                md={11}
                style={{
                  margin: 18,
                }}
              >
                <Label>Search</Label>
                <Input
                  type="Text"
                  name="search"
                  placeholder="Search Table.................."
                  // value={}
                />
              </Col>
              {/* {JSON.stringify(planningReserve)} */}
              <div
                className="mt-4"
                style={{
                  width: "100%",
                  height: 400,
                  overflowX: "scroll",
                }}
              >
                <Table
                  style={{
                    width: "100%",
                  }}
                  bordered
                  striped
                >
                  <thead>
                    <tr>
                      <th
                        colSpan="2"
                        style={{
                          fontSize: 15,
                          width: "50%",
                          textAlign: "center",
                        }}
                      >
                        Requesting MDA
                      </th>
                      <th
                        colSpan="2"
                        style={{
                          fontSize: 15,
                          width: "50%",
                          textAlign: "center",
                        }}
                      >
                        Source
                      </th>

                      <th
                        colSpan="3"
                        style={{
                          fontSize: 15,
                          width: "50%",
                          textAlign: "center",
                        }}
                      >
                        Action
                      </th>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontSize: 15,
                        }}
                      >
                        Admin
                      </td>
                      <td
                        style={{
                          fontSize: 15,
                        }}
                      >
                        Economic Code
                      </td>

                      <td
                        style={{
                          fontSize: 15,
                        }}
                      >
                        Admin
                      </td>
                      <td
                        style={{
                          fontSize: 15,
                        }}
                      >
                        Economic Code
                      </td>
                      <td
                        style={{
                          fontSize: 15,
                        }}
                      >
                        Amount
                      </td>
                      <td
                        style={{
                          fontSize: 15,
                        }}
                      >
                        Status
                      </td>
                      <td
                        style={{
                          fontSize: 15,
                        }}
                      >
                        Action
                      </td>
                    </tr>
                  </thead>
                  {isLoading ? (
                    <Spinner color="primary" />
                  ) : (
                    <tbody>
                      {planningReserve.map((item, idx) => (
                        <tr key={idx} style={{}}>
                          <td
                            style={{
                              fontSize: 15,
                              ...getRowStyle(item),
                            }}
                          >
                            <Link
                              to={`/view-planning-reservation?type=by_mda&mda_code=${item.dest_admin_code}`}
                            >
                              {item.dest_admin_code} - {item.dest_admin_desc}
                            </Link>
                          </td>
                          <td
                            style={{
                              fontSize: 15,
                              ...getRowStyle(item),
                            }}
                          >
                            <Link
                              to={`/view-planning-reservation?type=by_economic&economic_code=${item.dest_economic_code}`}
                            >
                              {item.dest_economic_code} -{" "}
                              {item.dest_economic_desc}
                            </Link>
                          </td>
                          <td
                            style={{
                              fontSize: 15,
                              ...getRowStyle(item),
                            }}
                          >
                            <Link
                              to={`/view-planning-reservation?type=by_mda&mda_code=${item.source_admin_code}`}
                            >
                              {item.source_admin_code} -{" "}
                              {item.source_admin_desc}
                            </Link>
                          </td>
                          <td
                            style={{
                              fontSize: 15,
                              ...getRowStyle(item),
                            }}
                          >
                            <Link
                              to={`/view-planning-reservation?type=by_economic&economic_code=${item.dest_economic_code}`}
                            >
                              {item.source_economic_code} -{" "}
                              {item.source_economic_desc}
                            </Link>
                          </td>

                          <td
                            style={{
                              fontSize: 15,
                              ...getRowStyle(item),
                              textAlign: "right",
                            }}
                          >
                            {item.amount.toLocaleString()}
                          </td>

                          <td
                            style={{
                              fontSize: 15,
                              ...getRowStyle(item),
                            }}
                          >
                            {item.reserve_status}
                          </td>
                          <td
                            style={{
                              fontSize: 15,
                              ...getRowStyle(item),
                            }}
                          >
                            <Dropdown
                              isOpen={selectedRow === idx && editDropdownOpen}
                              toggle={() => {
                                toggleEditDropdown(idx);
                                setData(item);
                              }}
                            >
                              <DropdownToggle
                                color="success"
                                variant="success"
                                caret
                              >
                                Actions
                              </DropdownToggle>
                              <DropdownMenu>
                                {/* <DropdownItem
                                // onClick={() =>
                                // navigate(
                                //   `/edit-new-release-collection?id=${item.id}`
                                // )
                                // }
                                >
                                  <LuEye
                                    size="18px"
                                    style={{ marginRight: 6 }}
                                  />
                                  View
                                </DropdownItem> */}
                                <DropdownItem
                                  onClick={() => {
                                    toggle();
                                    setData(item);
                                  }} /*onClick={() => approveRequest(item.id)}*/
                                >
                                  {" "}
                                  <IoMdCheckmark
                                    size="18px"
                                    style={{ marginRight: 6 }}
                                  />
                                  {params.pathname === "/Review-Virement"
                                    ? "Review"
                                    : "Approve"}
                                </DropdownItem>
                                {
                                  <DropdownItem
                                    onClick={() => {
                                      navigate(
                                        `/new-plnning-reservation?reserve_id=${item.id}`
                                      );
                                    }} /*onClick={() => approveRequest(item.id)}*/
                                  >
                                    {" "}
                                    <IoMdCheckmark
                                      size="18px"
                                      style={{ marginRight: 6 }}
                                    />
                                    Process
                                  </DropdownItem>
                                }
                                <DropdownItem onClick={toggle1}>
                                  {" "}
                                  <MdOutlineCancel
                                    size="18px"
                                    style={{ marginRight: 6 }}
                                  />
                                  Return
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </Table>
                <Modal isOpen={modal} toggle={toggle}>
                  <ModalHeader>
                    <span className="text-center" style={{ width: "100%" }}>
                      <center>
                        {deleteRow ? (
                          <p
                            style={{
                              color: "red",
                              fontWeight: "500",
                            }}
                          >
                            Are You Sure You want to return{" "}
                          </p>
                        ) : (
                          <p
                            style={{
                              color: "#54AC58",
                              fontWeight: "500",
                            }}
                          >
                            Are You Sure You want to Approve this request?
                          </p>
                        )}
                      </center>
                    </span>
                  </ModalHeader>
                  <ModalBody>
                    <Col md={12}>
                      {deleteRow ? (
                        <Col md={12}>
                          <Label>Add Remark</Label>
                          <Input
                            type="textarea"
                            name="text"
                            style={{
                              borderColor: "Red",
                              borderWidth: 2,
                            }}
                          />
                        </Col>
                      ) : (
                        <Col md={12}>
                          <Label> Add Remark</Label>
                          <Input
                            type="textarea"
                            name="text"
                            style={{
                              borderColor: "#54AC58",
                              borderWidth: 2,
                            }}
                          />
                        </Col>
                      )}
                    </Col>
                  </ModalBody>
                  <ModalFooter className="d-flex justify-content-center">
                    {deleteRow ? (
                      <button color="danger" className="btn btn-danger">
                        Return
                      </button>
                    ) : (
                      <button
                        onClick={approveRequest}
                        className="btn btn-primary"
                      >
                        Approve
                      </button>
                    )}
                    <Button color="warning" onClick={toggle}>
                      cancel
                    </Button>
                  </ModalFooter>
                </Modal>
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
