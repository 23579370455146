const { _postApi } = require("redux/action/api");

export const expiredSetting = (form, query_type, cb, error) => {
  _postApi(
    `/expired_setting?query_type=${query_type}`,
    form,
    (response) => {
      cb(response);
    },
    (err) => {
      error(err);
    }
  );
};
