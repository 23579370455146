import PropTypes from "prop-types";

// @mui material components
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React base styles
import typography from "assets/theme/base/typography";
import CardMedia from "@mui/material/CardMedia";
import logo from "../../assets/images/footer.png";

function Footer({ company, links }) {
  const { href, name, name1 } = company;
  const { size } = typography;

  // const renderLinks = () =>
  //   links.map((link) => (
  //     <MDBox key={link.name} component="li" px={2} lineHeight={1}>
  //       <Link href={link.href} target="_blank">
  //         <MDTypography variant="button" fontWeight="regular" color="text">
  //           {link.name}
  //         </MDTypography>
  //       </Link>
  //     </MDBox>
  //   ));

  return (
    <MDBox
      width="100%"
      minHeight="7rem"
      borderRadius="xl"
      sx={{
        // backgroundImage: () => `url(${logo})`,
        backgroundSize: "cover",
        backgroundPosition: "10%",
        overflow: "hidden",
      }}
    >
      <center>
        <MDBox bottom="2rem" style={{ marginTop: 54 }}>
          <MDTypography variant="button" fontWeight="medium">
            &copy; E - BUDGET {new Date().getFullYear()}
          </MDTypography>
        </MDBox>
      </center>
      {/* <CardMedia
        component="img"
        sx={{ width: "100%", height: "100%" }}
        image={logo}
        alt="Logo cover"
        style={{ marginTop: -20 }}
      /> */}
    </MDBox>
  );
}

// Setting default values for the props of Footer
Footer.defaultProps = {
  company: {
    href: "http://brainstorm.com.ng/ ",
    name: "POWERED BY KANO STATE",
    name1: "DEVELOPED BY BRAINSTORM IT SOLUTION",
  },
};

// Typechecking props for the Footer
Footer.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.object),
};

export default Footer;
